import { LOGIN_REQUEST, LOGOUT_REQUEST } from '../actions/index';
import { saveState } from '../localStorage';

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
	if(action.error) return state;
	switch(action.type) {
		case LOGIN_REQUEST:
			const data = { ...action.payload.data.data };
			saveState(data);
			return data;
		case LOGOUT_REQUEST:
			saveState(INITIAL_STATE);
			return INITIAL_STATE;
		default:
			return state;
	}
}