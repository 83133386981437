import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { getFromUrl, mapField } from '../../actions';
import { Modal } from '../modal';
import { FieldItem, Table, Recipe } from '../common';

import { reprintRecipeField } from '../../fields';

class DataTransactionRepayment extends Component {
	constructor(props) {
		super(props);

		const headerField = _.cloneDeep(reprintRecipeField.header);
		const keysRecipe = _.cloneDeep(reprintRecipeField.recipe);

		headerField.code.option.store_id = props.session.store_id;

		this.state = { headerField, keysRecipe, header: null, data: [], recipe: null, payment: null, footer: [], option: [], notFound: 0, modal: 0, initialized: false, fetching: true, showPrice: true };
	}

	componentDidMount() {
		const { headerField } = this.state;

		Promise.all([
			mapField(headerField).then((data) => {
				this.setState({ headerField: _.mapKeys(data, 'name') });
			}),
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	updateHeader(data) {
		const { change } = this.props;

		change('customer_code', data.customer.code);
		change('customer_name', data.customer.name);
	}

	onResetClick() {
		const { reset } = this.props,
			header = null, data = [], recipe = null, payment = null, modal = 0;

		reset();
		this.setState({ header, data, recipe, payment, modal });
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			this.setState({ fetching: true });
			return getFromUrl(`transaction/${props.code}`).then(({data:{ data }}) => {
				this.updateHeader(data);
				const recipe = _.mapKeys(data.recipe, 'eye');
				this.setState({ header: props, data, recipe, fetching: false });
			}).catch((err) => {
				if( err.response && err.response.status === 404) {
					this.setState({ notFound: this.state.notFound + 1, fetching: false });
				}
			});
		}
	}

	onPrintClick() {
		window.print();
	}

	renderHeader() {
		const { data:{ note }, header, headerField, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container">
				<div className="col-6">
					{_.map(_.pickBy(_.cloneDeep(headerField), ['group','left']), ({validate, group, ...field}, index) => {
						field.disabled = !_.isEmpty(header) || field.disabled;
						return index === 'code'?
							<div key={index} className="row">
								<div className="col-8">
									<Field name={index} component={FieldItem} field={field} validate={validate} />
								</div>
								<div className="col-4" style={{paddingTop: '8px'}}>
								{!header && <Button type="submit" color="primary" style={{width: '100%'}} disabled={submitting}>Cari</Button>}
								{header && <Button type="reset" color="danger" onClick={this.onResetClick.bind(this)} style={{width: '100%'}}>Reset</Button>}
								</div>
							</div>:
							<Field key={index} name={index} component={FieldItem} field={field} validate={validate} />
					})}
				</div>
				<div className="col-3">
					{_.map(_.pickBy(headerField, ['group','middle']), ({validate, group, ...field}, index) => {
						field.disabled = !_.isEmpty(header) || field.disabled;
						return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} />
					})}
				</div>
				<div className="col-3">
					{_.map(_.pickBy(headerField, ['group','right']), ({validate, group, ...field}, index) => {
						field.disabled = !_.isEmpty(header) || field.disabled;
						return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} />
					})}
				</div>
				<div className="col-12">NOTE: {note || '-'}</div>
			</div>
	}

	renderFooter() {
		return (
			<div className="input-container d-print-none">
				<div className="col-3" style={{paddingTop: '8px'}}>
					<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this)}>Cetak Recipe</Button>
				</div>
				<div className="col-3" style={{paddingTop: '8px'}}>
					<Button color="primary" style={{width: '100%'}} onClick={this.onResetClick.bind(this)}>Pencarian Ulang</Button>
				</div>
			</div>
		);
	}

	renderRecipe() {
		const { session } = this.props;
		const { data, recipe } = this.state;

		return <Recipe store={session.store} customer={data.customer} recipe={_.cloneDeep(recipe)} note={data.note} />;
	}

	render() {
		const 
			{ name, handleSubmit } = this.props,
			{ data, recipe, keysRecipe, notFound, initialized, fetching } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && <Table data={recipe} keys={keysRecipe} fetching={fetching} /> }
				{ !_.isEmpty(recipe) && this.renderFooter()}
				<Modal title="Tidak Ditemukan" type="notice" modal={notFound} onButtonClick={()=>{}}>
					<h2 className="text-center">Kode Invoice Tidak Ditemukan</h2>
				</Modal>
				{ !_.isEmpty(data) && this.renderRecipe()}
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataTransactionRepayment);
