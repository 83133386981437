import axios from 'axios';
import _ from 'lodash';
import md5 from 'md5';
import { loadState } from '../localStorage';

export const LOGIN_REQUEST = '@@LOGIN_REQUEST**';
export const LOGOUT_REQUEST = '@@LOGOUT_REQUEST**';
export const GET_DATA = '@@GET_DATA**';
export const ADD_DATA = '@@ADD_DATA**';
export const EDIT_DATA = '@@EDIT_DATA**';
export const REMOVE_DATA = '@@REMOVE_DATA**';
export const SPLIT_ACTION = '~!@#$%^&*()_+';

let ROOT_URL, API_KEY;
if(process.env.NODE_ENV === "production") {
  ROOT_URL = process.env.REACT_APP_PRODUCTION;

	const DOMAIN = process.env.REACT_APP_DOMAIN;
	const SUBDOMAIN = _.replace(window.location.hostname, `.${DOMAIN}`, '');
  API_KEY = md5(SUBDOMAIN);
}else {
  ROOT_URL = process.env.REACT_APP_LOCALHOST;
  API_KEY = process.env.REACT_APP_API_KEY;
}
const API_VERSION = process.env.REACT_APP_API_VERSION;

axios.defaults.headers.common['key'] = API_KEY;

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const session = loadState();
    if(session && session.session && session.session.usergroup_id) {
      config.headers.type = session.session.usergroup_id;
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
  
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    if(_.get(error, "response.data.alert")) {
      alert(_.get(error, "response.data.message"));
    }else if(_.get(error, "response.data.message")){
      console.log(_.get(error, "response.data.message"));
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

export function login(props) {
	const request = axios.post(`${ROOT_URL+API_VERSION}/login`, props);

	return {
		type: LOGIN_REQUEST,
		payload: request
	}
}

export function logout() {
	return {
		type: LOGOUT_REQUEST,
		payload: { }
	}
}

export function getData(params, table, url = false) {
	const request = axios.get(`${ROOT_URL+API_VERSION}/${url || table}`, { params });

	return {
		type: GET_DATA+SPLIT_ACTION+table,
		payload: request,
	}
}

export function addData(props, table, url = false) {
	const request = axios.post(`${ROOT_URL+API_VERSION}/${url || table}`, props);

	return {
		type: ADD_DATA+SPLIT_ACTION+table,
		payload: request
	}
}

export function editData(props, table, id, url = false) {
	const request = axios.put(`${ROOT_URL+API_VERSION}/${url || table}/${id}`, props);

	return {
		type: EDIT_DATA+SPLIT_ACTION+table,
		payload: request
	}
}

export function removeData(table, id, url = false) {
	const params = { table, id }
	const request = axios.delete(`${ROOT_URL+API_VERSION}/${url || 'delete'}`, { params });

	return {
		type: REMOVE_DATA+SPLIT_ACTION+table,
		payload: request
	}
}

export function getSelect(params) {
	const request = axios.get(`${ROOT_URL+API_VERSION}/select`, { params });

	return request;
}

export function getHistory(params) {
	const request = axios.get(`${ROOT_URL+API_VERSION}/history`, { params });

	return request;
}

export function getAsync(params) {
	const request = axios.get(`${ROOT_URL+API_VERSION}/async`, { params });

	return request;
}

export function getDetail(table, id, params) {
	const request = axios.get(`${ROOT_URL+API_VERSION}/${table}/${id}`, { params });

	return request;
}

export function getFromUrl(url, params, option) {
	const request = axios.get(`${ROOT_URL+API_VERSION}/${url}`, { params }, option);

	return request;
}

export function postFromUrl(url, params, option) {
	const request = axios.post(`${ROOT_URL+API_VERSION}/${url}`, params, option);

	return request;
}

export function putFromUrl(url, params, option) {
	const request = axios.put(`${ROOT_URL+API_VERSION}/${url}`, params, option);

	return request;
}

export function deleteFromUrl(url, params, option) {
	const request = axios.delete(`${ROOT_URL+API_VERSION}/${url}`, { params }, option);

	return request;
}

export function saveData(url, params) {
	const request = axios.post(`${ROOT_URL+API_VERSION}/${url}`, params).catch((err) => {
    err && err.response && err.response.data && alert(JSON.stringify(err.response.data.message));
  });

	return request;
}

export function getCount(category_id, params) {
	const request = axios.get(`${ROOT_URL+API_VERSION}/product/${category_id}/count`, { params });

	return request;
}

export function getLogo(id) {
	const params = {
  	table: 'store',
  	select: ['image as image_path', 'store.name'],
  	where: {
  		id
  	}
  }
	const request = axios.get(`${ROOT_URL+API_VERSION}/show`, { params });

	return request;
}

export function mapField(data, updateField) {
	const field = _.cloneDeep(data);

	return Promise.all(_.map(field, (value, index) => {
		if(typeof value.onChange === 'string') {
			const column = value.onChange;
			value.onChange = (target) => {
				if(target && target.value) {
					const option = {
						table: _.replace(column, '_id', ''),
						...data[column].option,
						[index]: (target && target.value) || 0
					};
					getSelect(option).then(({data: { data }}) => {
						_.isFunction(updateField) && updateField(column, data);
					});
				}else{
					_.isFunction(updateField) && updateField(column, []);
				}
			};
		}else if(typeof value.onChange === 'object') {
			const column = value.onChange;
			value.onChange = (target) => {
				_.each(column, (v, i) => {
					if(target && target.value) {
						const option = {
							table: _.replace(v, '_id', ''),
							...data[v].option,
							[index]: (target && target.value) || 0
						};
						getSelect(option).then(({data: { data }}) => {
							_.isFunction(updateField) && updateField(v, data);
						});
					}else{
						sleep(i).then(() => {
							_.isFunction(updateField) && updateField(v, []);
						});
					}
				});
			};
		}
		if(value.type !== 'async' && value.option && value.option.table) {
			return getSelect(value.option).then(({data: { data }}) => {
				return {...value, option: data, name: index};
			});
		}else if(value.type !== 'async' && value.history && value.history.table) {
			return getHistory(value.history).then(({data: {data}}) => {
				// value.onNewOptionClick = (target) => {
				// 	var option = _.concat(target, data);
				// 	return _.isFunction(updateField) && updateField(index, option);
				// }
				return {...value, option: data, name: index};
			});
		}
		return { ...value, name: index };
	}));
}

export function mapKeys(field) {
	return _.mapKeys(_.mapValues(_.omitBy(field, (value, index) => value.hidden || index === 'description'), 'label'), (value, index) =>  _.replace(index, '_id', '_name'))
}

export function terbilang(num){
	const bilangan = ['','Satu','Dua','Tiga','Empat','Lima','Enam','Tujuh','Delapan','Sembilan','Sepuluh','Sebelas'];
	let kalimat, utama, depan, belakang;
  const a = Math.abs(num);

	// 1 - 11
	if(a < 12){
		kalimat = bilangan[a];
	}
	// 12 - 19
	else if(a < 20){
		kalimat = bilangan[a-10]+' Belas';
	}
	// 20 - 99
	else if(a < 100){
		utama = a/10;
		depan = parseInt(String(utama).substr(0,1), 10);
		belakang = a%10;
		kalimat = bilangan[depan]+' Puluh '+bilangan[belakang];
	}
	// 100 - 199
	else if(a < 200){
		kalimat = 'Seratus '+ terbilang(a - 100);
	}
	// 200 - 999
	else if(a < 1000){
		utama = a/100;
		depan = parseInt(String(utama).substr(0,1), 10);
		belakang = a%100;
		kalimat = bilangan[depan] + ' Ratus '+ terbilang(belakang);
	}
	// 1,000 - 1,999
	else if(a < 2000){
		kalimat = 'Seribu '+ terbilang(a - 1000);
	}
	// 2,000 - 9,999
	else if(a < 10000){
		utama = a/1000;
		depan = parseInt(String(utama).substr(0,1), 10);
		belakang = a%1000;
		kalimat = bilangan[depan] + ' Ribu '+ terbilang(belakang);
	}
	// 10,000 - 99,999
	else if(a < 100000){
		utama = a/100;
		depan = parseInt(String(utama).substr(0,2), 10);
		belakang = a%1000;
		kalimat = terbilang(depan) + ' Ribu '+ terbilang(belakang);
	}
	// 100,000 - 999,999
	else if(a < 1000000){
		utama = a/1000;
		depan = parseInt(String(utama).substr(0,3), 10);
		belakang = a%1000;
		kalimat = terbilang(depan) + ' Ribu '+ terbilang(belakang);
	}
	// 1,000,000 - 	99,999,999
	else if(a < 100000000){
		utama = a/1000000;
		depan = parseInt(String(utama).substr(0,4), 10);
		belakang = a%1000000;
		kalimat = terbilang(depan) + ' Juta '+ terbilang(belakang);
	}
	else if(a < 1000000000){
		utama = a/1000000;
		depan = parseInt(String(utama).substr(0,4), 10);
		belakang = a%1000000;
		kalimat = terbilang(depan) + ' Juta '+ terbilang(belakang);
	}
	else if(a < 10000000000){
		utama = a/1000000000;
		depan = parseInt(String(utama).substr(0,1), 10);
		belakang = a%1000000000;
		kalimat = terbilang(depan) + ' Milyar '+ terbilang(belakang);
	}
	else if(a < 100000000000){
		utama = a/1000000000;
		depan = parseInt(String(utama).substr(0,2), 10);
		belakang = a%1000000000;
		kalimat = terbilang(depan) + ' Milyar '+ terbilang(belakang);
	}
	else if(a < 1000000000000){
		utama = a/1000000000;
		depan = parseInt(String(utama).substr(0,3), 10);
		belakang = a%1000000000;
		kalimat = terbilang(depan) + ' Milyar '+ terbilang(belakang);
	}
	else if(a < 10000000000000){
		utama = a/10000000000;
		depan = parseInt(String(utama).substr(0,1), 10);
		belakang = a%10000000000;
		kalimat = terbilang(depan) + ' Triliun '+ terbilang(belakang);
	}
	else if(a < 100000000000000){
		utama = a/1000000000000;
		depan = parseInt(String(utama).substr(0,2), 10);
		belakang = a%1000000000000;
		kalimat = terbilang(depan) + ' Triliun '+ terbilang(belakang);
	}

	else if(a < 1000000000000000){
		utama = a/1000000000000;
		depan = parseInt(String(utama).substr(0,3), 10);
		belakang = a%1000000000000;
		kalimat = terbilang(depan) + ' Triliun '+ terbilang(belakang);
	}

  else if(a < 10000000000000000){
		utama = a/1000000000000000;
		depan = parseInt(String(utama).substr(0,1), 10);
		belakang = a%1000000000000000;
		kalimat = terbilang(depan) + ' Kuadriliun '+ terbilang(belakang);
	}

	const pisah = kalimat.split(' ');
	let full = [];
	for(var i = 0;i < pisah.length;i++){
	 if(pisah[i] !== ""){
	 	full.push(pisah[i]);
	 }
	}
	return full.join(' ');
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
