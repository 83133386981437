import React, { Component } from 'react';
import { reduxForm, Field, untouch } from 'redux-form';
import { Button, Input } from 'muicss/react';
import _ from 'lodash';

import { getFromUrl, saveData, mapField } from '../../actions';
import { Modal, ModalAdd } from '../modal';
import { FieldItem, Table } from '../common';

import { A5 } from '../style';
import { returField, maxValue, warehouse_id } from '../../fields';

class DataRetur extends Component {
	constructor(props) {
		super(props);
		const { session:{ store_id } } = props;

		const headerField = _.omit(_.cloneDeep(returField.header), store_id !== warehouse_id?['supplier_id']:['transfer_to']);
		headerField.store_id.option[0] = ['id', store_id];
		const field = _.cloneDeep(returField.data);
		const keys = _.cloneDeep(returField.keys);
		const initialValidate = _.cloneDeep(field.quantity.validate);
		field.quantity.validate = this.validateQuantity.bind(this);

		this.state = { headerField, field, keys, initialValidate, validate: initialValidate, header: null, data: [], product: [], transfer: null, option: [], saving: false, modal: 0, initialized: false, fetching: true, password: "" };
	}

	componentDidMount() {
		const { headerField, field } = this.state;

		Promise.all([
			mapField(headerField).then((data) => {
				this.setState({ headerField: _.mapKeys(data, 'name') });
			}),
			mapField(field).then((data) => {
				this.setState({ field: _.mapKeys(data, 'name') });
			}),
		]).then(() => {
			this.initHeader()
			this.setState({ fetching: false, initialized: true });
		});
	}

	initHeader() {
		const { session:{ id, store_id }, change } = this.props;

		change('admin_id', id);
		change('store_id', store_id);
		change('transfer_to', warehouse_id);
	}

	updateProduct(props) {
		const { dispatch } = this.props;
		const { store_id, supplier_id } = props;

		this.setState({ fetching: true });
		return getFromUrl('select-product', {store_id, supplier_id, stock: true, detail: true}).then(({data:{ data }}) => {
			const field = _.cloneDeep(this.state.field);
			field.product_id.option = data;
			field.product_id.onChange = (value) => {
				const { initialValidate } = this.state;
				const field = _.cloneDeep(this.state.field);
				if(value) {
					dispatch(untouch('addForm', 'quantity'));
					this.setState({ validate: [ ...initialValidate, maxValue(value.stock) ] });
				}else {
					this.setState({ validate: initialValidate });
				}
				this.setState({ field });
			}
			this.setState({ field, product: _.mapKeys(data, 'value'), header: props, fetching: false });
		});
	}

	validateQuantity(value) {
		const { validate } = this.state;

		const validation = _.map(validate, (v) => {
			return v(value);
		});
		return _.first(_.compact(validation));
	}

	onResetClick() {
		const { reset } = this.props,
			header = null, data = [], product = [], transfer = null, saving = false, password = "";

		reset();
		this.initHeader();
		this.setState({ header, data, product, transfer, saving, password });
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			return this.updateProduct(props);
		}
	}

	onAddSubmit(props) {
		const { data, product } = this.state;

		props = {...props, ...product[props.product_id]};
		this.setState({data: {...data, [props.product_id]: props}});
	}

	onEditSubmit(props, id) {
		const { data } = this.state;

		this.setState({data: {...data, [id]: props}});
	}

	onRemoveSubmit(id) {
		const { data } = this.state;

		this.setState({data: _.omit(data, [id])});
	}

	onSaveClick() {
		const { header, data, password } = this.state,
			{ session, change } = this.props;

		this.setState({ saving: true });
		saveData("retur", { header, data, session, password }).then(({data:{ data }}) => {
			change('code', data.code);
			this.setState({ transfer: data });
		}).catch((err) => {
			this.setState({ saving: false });
		});
	}

	onPrintClick() {

		window.print();
	}

	renderHeader() {
		const	{ header, headerField, initialized } = this.state;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container" style={{justifyContent: 'flex-end'}}>
				{_.map(_.cloneDeep(headerField), ({validate, className, ...field}, index) => {
					field.disabled = !_.isEmpty(header) || field.disabled;
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={className} />
				})}
				<div className="d-print-none" style={{flexBasis: '25%', paddingTop: '8px'}}>
					{!header && <Button type="submit" color="primary" style={{width: '100%'}}>Tetapkan</Button>}
					{header && <Button type="reset" color="danger" onClick={this.onResetClick.bind(this)} style={{width: '100%'}}>Ulang</Button>}
				</div>
				<div className="d-print-none" style={{flexBasis: '100%', color: 'red'}}>
					* Kode akan ditampilkan saat proses retur berhasil
				</div>
			</div>
	}

	renderFooter() {
		const { transfer, saving } = this.state;
		return (
			<div className="input-container d-print-none">
				<div style={{flexBasis: '100%', color: 'red'}}>
					* Harap kirim data sebelum meninggalkan halaman ini
				</div>
				<div style={{flexBasis: '25%', paddingRight: '15px'}}>
					<Button color="primary" style={{width: '100%'}} onClick={()=>{this.setState({ modal: this.state.modal + 1 })}} disabled={saving}>{(transfer)?"Data Terkirim":"Kirim"}</Button>
				</div>
				<div style={{flexBasis: '25%', paddingRight: '15px'}}>
					<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this)} disabled={!saving && !transfer}>Print</Button>
				</div>
			</div>
		);
	}

	render() {
		const 
			{ name, slug, remove, handleSubmit } = this.props,
			{ field, keys, header, data, transfer, modal, password } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{ this.renderHeader() }
				</form>
				<A5/>
				{ header && !transfer && <ModalAdd bsSize="lg" className="d-print-none" slug={slug} field={field} labelCancel="Selesai" onAddSubmit={this.onAddSubmit.bind(this)}/> }
				{ header && <Table bsSize="md" data={data} slug={slug} field={field} keys={keys} remove={!transfer && remove} onRemoveSubmit={this.onRemoveSubmit.bind(this)} name="product_name" footer={[ 3, 1, 0 ]}/> }
				{ !_.isEmpty(data) && this.renderFooter()}
				<Modal title="Kirim Barang" modal={modal} onButtonClick={this.onSaveClick.bind(this)}>
					<h2 className="text-center pb-3">Apakah anda yakin data sudah benar?</h2>
					<Input name="code" autoComplete="off" type="text" value={password} label="Kode Admin (Default Kosong)" onChange={({target:{ value }}) => this.setState({ password: value })} floatingLabel className="input-password" />
				</Modal>
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataRetur);
