import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/id';
import _ from 'lodash';

import { InvoiceStyle } from '../style';
import { terbilang } from '../../actions/index';

export class Barcode extends Component {
	renderField(value) {
  	const { field } = this.props;
  	const print = { ...field, ..._.omitBy(value.detail, (v, i) => {return i === 'expired'}) };

  	return _.map(print, (v, i) => {
  		if(value[i])
				return <div key={i} className="label-text" /*style={{flexBasis: value.barcode?'100%':'50%'}}*/>{i==='sell' || i==='sell_locale'?'Rp ':''}{i==='sell'?value[i].toLocaleString('id'):value[i]}</div>
		});
	}

	renderBarcode(value) {
		let element = [], i;

		for (i = 0; i < value.quantity; i++) {
			element.push(
				<div key={i} className="label-container">
					{ value.barcode && <div className="label-detail">
						<div dangerouslySetInnerHTML={{__html: value.barcode}} />
					</div> }
					<div className="label-detail">
						<div className="label-text" /*style={{flexBasis: value.barcode?'100%':'50%'}}*/>{value.code}</div>
						{this.renderField(value)}
					</div>
				</div>
			);
		}

		return element;
	}

	renderPrint() {
  	const { data } = this.props;

  	return _.map(data, (value, index) => {
  		return this.renderBarcode(value);
  	});
	}

	renderSkip() {
		const { skip } = this.props;

  	let element = [], i;

		for (i = 0; i < skip; i++) {
			element.push(<div key={i} className="label-container"></div>);
		}
		return element;
	}

  render() {
    return (
    	<div className="print-area">
    		{this.renderSkip()}
    		{this.renderPrint()}
      </div>
    );
  }
}

export const Recipe = ({ store, customer, recipe, note }) => {
  if(_.isNull(recipe.KIRI) || _.isUndefined(recipe.KIRI)) recipe.KIRI = [];
  if(_.isNull(recipe.KANAN) || _.isUndefined(recipe.KANAN)) recipe.KANAN = [];
  return (
    <div id="invoice">
      <h3 className="header">
        <div>{store.name}</div>
        <div style={{marginBottom: '10px'}}>{store.address}</div>
        <div>{store.phone}</div>
        <div>{store.city_name}</div>
      </h3>
      <div className="body" style={{marginTop: '20px', fontSize: '125%'}}>
        <div className="body-top justify-content-around">
          <div style={{width: '45%'}}>
            <div>ID Pelanggan: {customer.code}</div>
            <div>Nama Pelanggan: {customer.name}{recipe.created_at}</div>
          </div>
          <div style={{width: '45%'}}>
            <div>Tgl Transaksi: {moment(recipe.created_at).locale('id').format('DD MMM YYYY')}</div>
            <div>Telp Pelanggan: {customer.phone}</div>
          </div>
        </div>
        <div className="body-content justify-content-around" style={{marginTop: '30px'}}>
          <table style={{width: '45%'}}>
            <thead>
              <tr>
                <th colSpan="5">MATA KANAN</th>
              </tr>
              <tr>
                <th colSpan="5">{recipe.KANAN?`${recipe.KANAN.brand_name}/${recipe.KANAN.name}${recipe.KANAN.kind?'/'+recipe.KANAN.kind:''}`:'-'}</th>
              </tr>
              <tr>
                <th width="10%">SPH</th>
                <th width="10%">CYL</th>
                <th width="10%">AXIS</th>
                <th width="10%">ADD</th>
                <th width="10%">PD</th>
              </tr>
            </thead>
            <tbody>
              <tr height="80">
                <td>{recipe.KANAN.spherical || '-'}</td>
                <td>{recipe.KANAN.cylinder || '-'}</td>
                <td>{recipe.KANAN.axis !== undefined?recipe.KANAN.axis:'-'}</td>
                <td>{recipe.KANAN.addition || '-'}</td>
                <td>{recipe.KANAN.pd || '-'}</td>
              </tr>
            </tbody>
          </table>
          <table style={{width: '45%'}}>
            <thead>
              <tr>
                <th colSpan="5">MATA KIRI</th>
              </tr>
              <tr>
                <th colSpan="5">{recipe.KIRI?`${recipe.KIRI.brand_name}/${recipe.KIRI.name}${recipe.KIRI.kind?'/'+recipe.KIRI.kind:''}`:'-'}</th>
              </tr>
              <tr>
                <th width="10%">SPH</th>
                <th width="10%">CYL</th>
                <th width="10%">AXIS</th>
                <th width="10%">ADD</th>
                <th width="10%">PD</th>
              </tr>
            </thead>
            <tbody>
              <tr height="80">
                <td>{recipe.KIRI.spherical || '-'}</td>
                <td>{recipe.KIRI.cylinder || '-'}</td>
                <td>{recipe.KIRI.axis !== undefined?recipe.KIRI.axis:'-'}</td>
                <td>{recipe.KIRI.addition || '-'}</td>
                <td>{recipe.KIRI.pd || '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="" style={{marginTop: '30px'}}>
          NOTE: {note || '-'}
        </div>
      </div>
      <InvoiceStyle />
    </div>
  );
}

export const Invoice = ({ header, store, customer, data, order, payment = { unpaid: 0 }, footer, recipe, note, showPrice = true, showRecipe = true, showInternal = false, logo = { image_path: '' } }) => {
  let i = 1;
  let qty = 0;
	const transaction_date = moment(header.created_at);
	const print_date = moment();

  return (
    <div id="invoice">
      { _.isEmpty(logo.image_path)?
      <h3 className="header">
        <div>{store.name}</div>
        <div>{store.address}</div>
        <div>{store.city_name}</div>
      </h3>:
      <div className="header col-12 d-flex justify-content-center">
				<div className="col-3">
					<img alt={logo.name} src={logo.image_path} width="100%" />
				</div>
			</div> }
      <div className="body">
        <div className="body-top">
          <div className="body-top-left">
            <div>Invoice: {_.toUpper(header.code)} / {store.name}</div>
            <div>Customer: {_.toUpper(customer.name)} / {_.toUpper(customer.phone)}</div>
          </div>
          <div className="body-top-right">
						{ transaction_date.diff(print_date, 'days') ?
						<div>Tgl Transaksi / Cetak: {transaction_date.locale('id').format('DD MMM YYYY')} / {print_date.locale('id').format('DD MMM YYYY')}</div>:
						<div>Tgl Transaksi : {transaction_date.locale('id').format('DD MMM YYYY')}</div>
						}
            <div>Status Bayar: {Number(payment.unpaid) === 0?'LUNAS':'DP'} ({header.debit.name})</div>
          </div>
          <div className="body-top-note">
						{ showInternal ? 
							(header.note && <div>Note: {header.note}</div>):
							(header.ext_note && <div>Note: {header.ext_note}</div>)
						}
          </div>
        </div>
        <div className="body-content">
          <table width="100%">
            <thead>
              <tr>
                <th width="6%">No</th>
                <th width={showPrice?'18%':'23%'}>Merk</th>
                <th width={showPrice?'50%':'60%'}>Type</th>
                <th width={showPrice?'7%':'12%'}>Qty</th>
                { showPrice && <th width="19%">Harga</th> }
              </tr>
            </thead>
            <tbody>
              {_.map(data, (v) => {
                qty += _.parseInt(v.quantity);
                return (
                  <tr key={i}>
                    <td>{i++}</td>
                    <td>{v.brand_name}</td>
                    <td>{_.join(_.compact([v.code, _.last(_.split(v.label, ' - ')), v.kind]), ' - ')}</td>
                    <td>{v.quantity}</td>
                    { showPrice && <td>Rp. { (_.parseInt(v.price) * _.parseInt(v.quantity)).toLocaleString('id') }</td> }
                  </tr>
                );
              })}
              {_.map(order, (v) => {
                qty += 1;
                return (
                  <tr key={i}>
                    <td>{i++}</td>
                    <td>{_.join(_.compact([v.code, _.last(_.split(v.brand_name, ' / ')), v.kind]), ' - ')}</td>
                    <td>{v.name}</td>
                    <td>1</td>
                    { showPrice && <td>Rp. {v.sell_locale || v.sell}</td> }
                  </tr>
                );
              })}
              <tr>
                <td className="no-border"></td>
                <td className="no-border"></td>
                <td className="no-border"></td>
                <td>{qty}</td>
                <td className="no-border"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="body-bottom">
          { showPrice && <div>
              <div>JUMLAH</div>
              <div><span>Rp. </span><span>{(_.isNumber(footer.sum) && footer.sum > footer.total?footer.sum:footer.total).toLocaleString('id')}</span></div>
            </div>}
          { showPrice && <div>
              <div>POTOGAN/DISKON</div>
              <div><span>Rp. </span><span>{(_.isNumber(footer.sum) && footer.sum < footer.total?footer.total - footer.sum:0).toLocaleString('id')}</span></div>
            </div>}
          <div>
            <div>TOTAL HARGA</div>
            <div><span>Rp. </span><span>{(_.isNumber(footer.sum)?footer.sum:footer.total).toLocaleString('id')}</span></div>
          </div>
          <div className="total"></div>
          { payment.paid && Number(payment.paid) !== 0 && <div>
            <div>{Number(payment.unpaid) !== 0?'DP':'PELUNASAN'}</div>
            <div><span>Rp. </span><span>{(payment.paid).toLocaleString('id')}</span></div>
          </div>}
          <div>
            <div>TOTAL BAYAR</div>
            <div><span>Rp. </span><span>{(payment.total).toLocaleString('id')}</span></div>
          </div>
          { Number(payment.unpaid) !== 0 && <div>
            <div>SISA BAYAR</div>
            <div><span>Rp. </span><span>{payment.unpaid}</span></div>
          </div>}
        </div>
        { showRecipe && Number(payment.unpaid) === 0 && (recipe.KANAN || recipe.KIRI) && <div className="body-recipe">
          <table>
            <thead>
              <tr>
                <th className="no-border" width="10%"></th>
                <th width="10%">SPH</th>
                <th width="10%">CYL</th>
                <th width="10%">AXIS</th>
                <th width="10%">ADD</th>
                <th width="10%">PD</th>
              </tr>
            </thead>
            <tbody>
              { recipe.KANAN && <tr>
                <td>KANAN</td>
                <td>{recipe.KANAN.spherical || '-'}</td>
                <td>{recipe.KANAN.cylinder || '-'}</td>
                <td>{recipe.KANAN.axis !== undefined?recipe.KANAN.axis:'-'}</td>
                <td>{recipe.KANAN.addition || '-'}</td>
                <td>{recipe.KANAN.pd || '-'}</td>
              </tr>}
              { recipe.KIRI && <tr>
                <td>KIRI</td>
                <td>{recipe.KIRI.spherical || '-'}</td>
                <td>{recipe.KIRI.cylinder || '-'}</td>
                <td>{recipe.KIRI.axis !== undefined?recipe.KIRI.axis:'-'}</td>
                <td>{recipe.KIRI.addition || '-'}</td>
                <td>{recipe.KIRI.pd || '-'}</td>
              </tr>}
            </tbody>
          </table>
        </div>}
      </div>
      <div className="footer">
        <div className="total">TERBILANG : <span>{terbilang(payment.total || footer.sum || footer.total)} RUPIAH</span></div>
        <div>NOTE :</div>
        <ol>
          {_.map(note, (v, i) => {
            return <li key={i}>{v.note}</li>
          })}
        </ol>
      </div>
      <InvoiceStyle />
    </div>
  );
}
