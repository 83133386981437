import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { postFromUrl, mapField } from '../../actions';
import { Modal } from '../modal';
import { FieldItem, Table } from '../common';

import { A5 } from '../style';
import { inputSellField } from '../../fields';

class DataInputSell extends Component {
	constructor(props) {
		super(props);

		const header = _.cloneDeep(inputSellField.header);
		const keys = _.cloneDeep(inputSellField.keys);

		this.state = { header, keys, props: null, data: [], option: [], modal: 0, notFound: 0, initialized: false, fetching: true };
	}

	componentDidMount() {
		const { header } = this.state;

		Promise.all([
			mapField(header, this.updateHeader.bind(this)).then((data) => {
				this.setState({ header: _.mapKeys(data, 'name') });
			})
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	updateHeader(column, data) {
		const { header } = this.state;
		const { change } = this.props;

		change(column, null);
		this.setState({ header: { ...header, [column]: { ...header[column], option: data, value: null }} });
	}

	onResetClick() {
		// const { reset } = this.props,
		const	props = null, data = [];

		// reset();
		this.setState({ props, data });
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			const params = _.omitBy(_.cloneDeep(props), _.isNull);
			this.setState({ fetching: true });
			return postFromUrl('product/sell', params).then(({data:{ data }}) => {
				this.setState({ props, data, fetching: false });
			}).catch((err) => {
				err && err.response && err.response.data && this.setState({ error : JSON.stringify(err.response.data.message), notFound: this.state.notFound + 1, fetching: false });
			});
		}
	}

	triggerSubmit() {
		this.refs.button.click();
	}

	onPrintClick() {
		const table = this.refs.table && this.refs.table.refs.datatable && this.refs.table.refs.datatable.table;
		const len = table && table.page.len();
		Promise.all([
			table && table.page.len(-1).draw()
		]).then(() => {
			window.print();
		}).then(() => {
			table && table.page.len(len).draw();
		});
	}

	renderHeader() {
		const	{ props, header, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container row">
				{_.map(_.cloneDeep(header), ({validate, className, ...field}, index) => {
					field.disabled = !_.isNull(props) || field.disabled;
					const empty = field.type === 'select' && _.isEmpty(field.option);
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={`${className || "col-12 col-md"} ${props && props[index]?'':'d-print-none'} ${empty?'d-none':''}`} />
				})}
				<div className="d-print-none col-12" style={{paddingTop: '8px'}}>
					<div className="row d-flex justify-content-end">
						<div className="col col-sm-3">
							{ !props && <button ref="button" type="submit" className="d-none" disabled={submitting} >Submit</button>}
							{ !props && <Button type="submit" color="primary" style={{ width:'100%' }} disabled={submitting}>Set Harga</Button>}
							{ props && <Button type="reset" color="danger" style={{ width:'100%' }} onClick={this.onResetClick.bind(this)}>Reset</Button>}
						</div>
						<div className="col col-sm-3 d-none d-sm-block">
							<Button type="reset" color="primary" style={{ width:'100%' }} onClick={this.onPrintClick.bind(this)} disabled={!props}>Print</Button>
						</div>
					</div>
				</div>
				<div className="d-print-none col text-danger">
					* Pastikan data sudah benar sebelum set harga
				</div>
			</div>
	}

	render() {
		const 
			{ name, slug, handleSubmit } = this.props,
			{ keys, initialized, data, modal, notFound, error } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{ this.renderHeader() }
				</form>
				{ initialized && <Table ref="table" data={data} slug={slug} keys={keys} advanced /> }
				<A5/>
				<Modal title="Pesan Error" type="notice" modal={notFound} onButtonClick={()=>{}}>
					<h2 className="text-center text-danger">{ error }</h2>
				</Modal>
				<Modal title="Set Harga Jual" modal={modal} onButtonClick={this.triggerSubmit.bind(this)}>
					<h2 className="text-center">Apakah anda yakin data sudah benar?</h2>
				</Modal>
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataInputSell);
