import React, { Component } from 'react';
import { reduxForm, Form } from 'redux-form';
import { Button } from 'muicss/react';
import { Modal as RModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';

import { MyField } from '../common';

class MyModal extends RModal {
	constructor(props) {
		super(props);

		this.state = { click: false };
	}

	onOpened(node, isAppearing) {
		super.onOpened(node, isAppearing);
		this.setState({ click: false });
	}

	handleEscape(e) {
		if (this.state.click) return;

		if (this.props.keyboard && e.keyCode === 27 && this.props.toggle) {
			this.setState({ click: true });
			this.props.toggle();
		}
	}

	handleBackdropClick(e) {
		if (this.props.backdrop !== true || this.state.click) return;

		var container = this._dialog;

		if (e.target && !container.contains(e.target) && this.props.toggle) {
			this.setState({ click: true });
			this.props.toggle();
		}
	}
}

class ModalClass extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.onButtonClick = this.onButtonClick.bind(this);

		this.state = { error: null, modal: false };
	}

	componentDidUpdate(props) {
		const { modal } = this.props;

		if(modal && modal !== props.modal) {
			this.setState({ error: null, modal: !this.state.modal });
		}
	}

	onButtonClick(props) {
		const { valid, onButtonClick } = this.props;

		if(_.isFunction(onButtonClick)) {
			this.setState({ error: null });
			return valid && Promise.resolve(onButtonClick(_.cloneDeep(props))).then(() => {
				// reset();
				this.toggle(true);
			}).catch((err) => {
				err && err.response && err.response.data && this.setState({ error : JSON.stringify(err.response.data.message) });
			});
		}else {
			this.toggle(true);
		}
	}

	toggle(force = false) {
		const { onHide, submitting } = this.props;

		if(!submitting || force) {
			this.setState({
				modal: !this.state.modal
			});

			_.isFunction(onHide) && onHide();
		}
	}

	render() {
		const { children, title, type, bsSize, labelCancel, handleSubmit, submitting } = this.props;

		return (
			<div className="d-print-none">
				<MyModal size={bsSize || "sm"} isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>{title || "Modal"}</ModalHeader>
					<Form onSubmit={handleSubmit(this.onButtonClick)} className="add-form">
						<ModalBody>
							{children}
							<div className="mui--text-danger text-center" ref="errorMessage">{this.state.error}</div>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" type="submit" disabled={submitting}>OK</Button>{' '}
							{ type !== 'notice' && <Button type="reset" color="secondary" onClick={this.toggle}>{`${labelCancel?labelCancel:'Cancel'}`}</Button>}
						</ModalFooter>
					</Form>
				</MyModal>
			</div>
		);
	}
}

export const Modal = reduxForm({
	form: "modalForm",
	enableReinitialize: true,
	// destroyOnUnmount: false
})(ModalClass);

class ModalAddClass extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null, modal: false };
	}

	componentDidMount() {
		this.updateValue();
	}

	componentDidUpdate(prevProp) {
		const { onComponentDidUpdate, field, change, untouch } = this.props;
		
		if(!_.isEqual(prevProp.field, field)) {
			_.each(_.mapValues(field, 'value'), (value, index) => {
				if(value !== undefined) {
					change(index, value);
					untouch(index);
				}
			});
		}
		if(onComponentDidUpdate) {
			_.each(onComponentDidUpdate, (value, index) => {
				change(index, value);
			});
		}
	}

	componentWillUnmount() {
		const { destroy } = this.props;

		destroy();
	}

	updateValue(except = []) {
		const { change, untouch, field } = this.props;
		
		_.each(field, ( { type, value }, index) => {
			if(except.indexOf(index) < 0) {
				if(value !== undefined) {
					change(index, value);
					untouch(index);
				}if(type === 'date') {
					change(index, moment(Date.now()).format('YYYY-MM-DD'));
				}
			}
		});
	}

	toggle(force = false) {
		const { submitting } = this.props;

		if(!submitting || force) {
			this.setState({
				modal: !this.state.modal
			});
		}
	}

	onSubmit(props) {
		const { onAddSubmit, reset, valid, close, except } = this.props;

		this.setState({ error: null });
		return valid && onAddSubmit && Promise.resolve(onAddSubmit(_.cloneDeep(props))).then(() => {
			if(close) {
				reset();
				this.toggle(true);
			// }else {
			}
			this.updateValue(except);
		}).catch((err) => {
			err && err.response && err.response.data && this.setState({ error : err.response.status === 422?'Data Sudah Terdaftar':JSON.stringify(err.response.data.message) });
		});
	}

	render() {
		const { handleSubmit, field, button, children, title, bsSize, labelCancel, fetching, submitting } = this.props;

		return (
			<div className="ma-button d-print-none">
				<Button color="primary" onClick={this.toggle.bind(this)} disabled={fetching}>{button || 'Tambah'}</Button>
				<MyModal size={bsSize || "sm"} isOpen={this.state.modal} toggle={this.toggle.bind(this)}>
					<ModalHeader toggle={this.toggle.bind(this)}>{title || "Tambah Baru"}</ModalHeader>
					<Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="add-form">
						<ModalBody>
								{children}
								<MyField fields={field} />
								<div className="mui--text-danger text-center" ref="errorMessage">{this.state.error}</div>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" type="submit" disabled={submitting}>OK</Button>{' '}
							<Button color="secondary" type="reset" onClick={this.toggle.bind(this)}>{`${labelCancel?labelCancel:'Cancel'}`}</Button>
						</ModalFooter>
					</Form>
				</MyModal>
			</div>
		);
	}
}

export const ModalAdd = reduxForm({
	form: "addForm",
	// enableReinitialize: true,
	destroyOnUnmount: false
})(ModalAddClass);

class ModalEditClass extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null, data: null, id: null, modal: false };
	}

	componentDidUpdate(prevProps) {
		const { field, data, id, change } = this.props;

		if(id !== null && id !== prevProps.id) {
			_.map(field, (value, index) => {
				if(value['data-readonly-edit']) {
					const i = _.replace(index, '_id', '_name');
					change(i, data[i]);
				}
				change(index, data[index] === 0?`${data[index]}`:data[index]);
			});
			this.setState({ data, id, modal: !this.state.modal });
		}
	}

	toggle(force = false) {
		const { unmount, submitting } = this.props;
		
		if(!submitting || force) {
			_.isFunction(unmount) && unmount();
			this.setState({
				modal: !this.state.modal
			});
		}
	}

	onSubmit(props) {
		const { onEditSubmit, reset, valid } = this.props;
		const { id } = this.state;

		this.setState({ error: null });
		return valid && onEditSubmit && Promise.resolve(onEditSubmit(props, id)).then(() => {
			reset();
			this.toggle(true);
		}).catch((err) => {
			err && err.response && err.response.data && this.setState({ error : err.response.status === 422?'Data Sudah Terdaftar':JSON.stringify(err.response.data.message) });
		});
	}

	render() {
		const { handleSubmit, field, children, title, bsSize, labelCancel, submitting } = this.props;
		const eField = _.mapValues(_.mapKeys(_.cloneDeep(field), (v, i) => {
			return v['data-readonly-edit']?_.replace(i, '_id', '_name'):i;
		}), (v) => {
			if(v['data-readonly-edit']) {
				v.readOnly = true;
				v.type = 'text';
			}
			return v;
		});

		return (
			<div className="d-print-none">
				<MyModal size={bsSize || "sm"} isOpen={this.state.modal} toggle={this.toggle.bind(this)} >
					<ModalHeader toggle={this.toggle.bind(this)}>{title || "Edit Data"}</ModalHeader>
					<Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="edit-form">
						<ModalBody>
							{children}
							<MyField fields={eField} />
							<div className="mui--text-danger text-center" ref="errorMessage">{this.state.error}</div>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" type="submit" disabled={submitting}>OK</Button>{' '}
							<Button color="secondary" type="reset" onClick={this.toggle.bind(this)}>{`${labelCancel?labelCancel:'Cancel'}`}</Button>
						</ModalFooter>
					</Form>
				</MyModal>
			</div>
		);
	}
}

export const ModalEdit = reduxForm({
	form: "editForm",
	// enableReinitialize: true,
	destroyOnUnmount: false
})(ModalEditClass);

class ModalPrintClass extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null };
	}

	componentDidMount() {
		const { change } = this.props;

		change('skip', '0');
	}

	onButtonClick() {
		this.refs.button.click();
	}

	toggle(force = false) {
		const { submitting } = this.props;

		if(!submitting || force) {
			this.setState({
				modal: !this.state.modal
			});
		}
	}

	onSubmit(props) {
		const { onSubmit, reset, valid } = this.props;

		if(valid) {
			onSubmit(props);
			reset();
			this.toggle(true);
		}
	}

	render() {
		const 
			{ handleSubmit, field, disabled, button, children, title, bsSize, labelCancel } = this.props,
			{ code, profit_id, quantity, buy, buy_code, ...otherField } = field;

		let checklist = _.mapValues(otherField, (value, index) => {
			return { label: value.label, type: 'checkbox' };
		});
		checklist.skip = { label: 'Skip Label', type: 'number' };

		// const barcode = { label: 'Barcode', type: 'hidden' };

		return (
			<div className="d-print-none">
				<Button color="primary" style={{width: '100%'}} onClick={this.toggle.bind(this)} disabled={disabled}>{button || "Print Label"}</Button>
				<MyModal size={bsSize || "sm"} isOpen={this.state.modal} toggle={this.toggle.bind(this)}>
					<ModalHeader toggle={this.toggle.bind(this)}>{title || "Print"}</ModalHeader>
					<ModalBody>
						<form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="add-form">
							{children}
							<MyField fields={checklist} />
							<div className="mui--text-danger text-center" ref="errorMessage">{this.state.error}</div>
							<button type="submit" hidden={true} ref="button" />
						</form>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.onButtonClick.bind(this)}>OK</Button>{' '}
						<Button color="secondary" onClick={this.toggle.bind(this)}>{`${labelCancel?labelCancel:'Cancel'}`}</Button>
					</ModalFooter>
				</MyModal>
			</div>
		);
	}
}

export const ModalPrint = reduxForm({
	form: "printForm",
	// validate
})(ModalPrintClass);

export class ModalRemove extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null, data: null, id: null, name: null, modal: false, submitting: false };
	}

	componentDidUpdate(prevProps) {
		const { data, id, name } = this.props;

		if(id !== null && id !== prevProps.id) {
			this.setState({ data, id, name, error: null, modal: !this.state.modal });
		}
	}

	onButtonClick() {
		const { onRemoveSubmit } = this.props;
		const { id } = this.state;

		this.setState({ error: null, submitting: true });
		return onRemoveSubmit && Promise.resolve(onRemoveSubmit(id)).then(() => {
			this.toggle(true);
			this.setState({ submitting: false });
		}).catch((err) => {
			err && err.response && err.response.data && this.setState({ error : JSON.stringify(err.response.data.message), submitting: false });
		});
	}

	toggle(force = false) {
		const { unmount, submitting } = this.props;

		if(!submitting || force) {
			_.isFunction(unmount) && unmount();
			this.setState({
				modal: !this.state.modal
			});
		}
	}

	render() {
		const { children, title, bsSize, labelCancel } = this.props;
		const { data, name, submitting } = this.state;

		return (
			<div className="d-print-none">
				<MyModal size={bsSize || "md"} isOpen={this.state.modal} toggle={this.toggle.bind(this)}>
					<ModalHeader toggle={this.toggle.bind(this)}>{title || "Hapus Data"}</ModalHeader>
					<ModalBody>
						{children}
						<div>Hapus <span className="mui--text-danger">{ data && data[name || 'name'] }</span>?</div>
						<div className="mui--text-danger text-center" ref="errorMessage">{this.state.error}</div>
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={this.onButtonClick.bind(this)} disabled={submitting}>Hapus</Button>{' '}
						<Button color="secondary" onClick={this.toggle.bind(this)}>{`${labelCancel?labelCancel:'Cancel'}`}</Button>
					</ModalFooter>
				</MyModal>
			</div>
		);
	}
}