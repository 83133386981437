import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import moment from 'moment';
import _ from 'lodash';

import { getFromUrl, mapField } from '../../actions';
import { FieldItem, Table } from '../common';

import { A5 } from '../style';
import { transactionHistoryField } from '../../fields';

class DataTransactionHistory extends Component {
	constructor(props) {
		super(props);
		const headerField = _.cloneDeep(transactionHistoryField.header);
		const keys = _.cloneDeep(transactionHistoryField.data);
		const keysUnpaid = _.cloneDeep(transactionHistoryField.unpaid);

		const { store_id } = props.session;
		headerField.store_id = {
			type: store_id === Number(process.env.REACT_APP_WAREHOUSEID)?'select':'hidden',
			label: 'Store',
			option: {
				table: 'store'
			},
			className: store_id === Number(process.env.REACT_APP_WAREHOUSEID)?'col':'d-none',
		}
		if(store_id !== Number(process.env.REACT_APP_WAREHOUSEID)) {
			headerField.admin_id.option.store_id = props.session.store_id;
		}

		this.state = { headerField, keys, keysUnpaid, header: null, data: [], unpaid: [], paid: [], initialized: false, fetching: true };
	}

	componentDidMount() {
		const { headerField } = this.state;

		Promise.all([
			this.initHeader(),
			this.resetDate(),
			mapField(headerField).then((data) => {
				this.setState({ headerField: _.mapKeys(data, 'name') });
			})
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}
	
	initHeader() {
		const { store_id } = this.props.session;
		
		if(store_id !== Number(process.env.REACT_APP_WAREHOUSEID)) {
			this.props.change('store_id', store_id);
		}
	}

	onResetClick() {
		// const { change, untouch } = this.props,
		const header = null, data = [], paid = [], unpaid = [];

		// change('admin_id', '');
		// untouch('admin_id');
		// reset();
		// this.resetDate();
		this.setState({ header, data, paid, unpaid });
	}

	resetDate() {
		const { change } = this.props;

		const now = Date.now();
		change('date_from', moment(now).format('YYYY-MM-DD'));
		change('date_to', moment(now).format('YYYY-MM-DD'));
	}

	onSubmit(props) {
		const { valid } = this.props;

		const params = props;
		this.setState({ fetching: true });
		return valid && getFromUrl(`transaction`, params).then(({data:{ data }}) => {
			const detail = _.concat(..._.map(data, 'detail'));
			const unpaid = _.filter(data, (v) => v.unpaid > 0);
			const paid = _.concat(..._.map(_.filter(data, ['unpaid', 0]), 'detail'));
			this.setState({ header: params, data: detail, paid, unpaid, fetching: false });
		});
	}

	onPrintClick() {
		
		window.print();
	}

	renderHeader() {
		const { header, headerField, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container">
				{_.map(_.cloneDeep(headerField), ({validate, className, ...field}, index) => {
					field.disabled = !_.isEmpty(header) || field.disabled;
					const empty = field.type === 'select' && _.isEmpty(field.option);
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={`${className || "col-12 col-md"} ${header && header[index]?'':'d-print-none'} ${empty?'d-none':''}`} />
				})}
				<div className="d-print-none col-12 d-flex justify-content-end" style={{paddingTop: '8px'}}>
					{ !header && <Button type="submit" color="primary" style={{minWidth: '20%'}} disabled={submitting}>Cari</Button>}
					{ header && <Button type="reset" color="danger" style={{minWidth: '20%'}} onClick={this.onResetClick.bind(this)}>Reset</Button>}
					<Button type="reset" color="primary" style={{minWidth: '20%'}} onClick={this.onPrintClick.bind(this)} disabled={!header}>Print</Button>
				</div>
			</div>
	}

	render() {
		const 
			{ name, handleSubmit } = this.props,
			{ data, paid, unpaid, keys, keysUnpaid, initialized, fetching } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && <div className="row" style={{marginTop: '10px'}}>
					<h4 className="col-6">Data Penjualan</h4>
					<h4 className="col text-right">Total Penjualan: Rp {_.parseInt(_.sumBy(data, 'total')).toLocaleString('id')}</h4>
				</div> }
				{ initialized && <Table data={data} keys={keys} fetching={fetching} /> }
				{ initialized && <div className="row" style={{marginTop: '20px'}}>
					<h4 className="col-4">Data Penjualan Belum Lunas</h4>
					<h4 className="col text-center">Total Pembayaran DP: Rp {_.sumBy(unpaid, 'paid').toLocaleString('id')}</h4>
					<h4 className="col text-right">Total Sisa Pembayaran: Rp {_.sumBy(unpaid, 'unpaid').toLocaleString('id')}</h4>
				</div> }
				{ initialized && <Table data={unpaid} keys={keysUnpaid} fetching={fetching} /> }
				{ initialized && <div className="row" style={{marginTop: '20px'}}>
					<h4 className="col-6">Data Penjualan Lunas</h4>
					<h4 className="col text-right">Total Penjualan Lunas: Rp {_.sumBy(paid, 'total').toLocaleString('id')}</h4>
				</div> }
				{ initialized && <Table data={paid} keys={keys} fetching={fetching} />}
				<A5 />
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataTransactionHistory);
