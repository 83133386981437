import ls from 'local-storage';

var name = "eopto";

export const loadState = () => {
	// We need the try block because user may not permit our accessing localStorage.
  try {
    const serializedState = ls(name);
    if (serializedState === null) { // The key state does not exist.
      return undefined;             // Let our reducer initialize the app.
    }

    return { session: JSON.parse(serializedState)};

  } catch (error) {
    console.log(error);
    return undefined; // Let our reducer initialize the app.
  }
}

export const saveState = (state) => {
	try {
    // Serialize the state. Redux store is recommended to be serializable.
    if(state) {
	    const serializedState = JSON.stringify(state);
	    ls(name, serializedState);
	  } else{
	  	ls.clear();
	  }
  } catch (error) {
    console.log(error);
  }
}
