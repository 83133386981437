import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import moment from 'moment';
import _ from 'lodash';

import { getFromUrl, mapField } from '../../actions';
import { FieldItem, Table } from '../common';

import { A5 } from '../style';
import { customerDebitField } from '../../fields';

class DataTransactionHistory extends Component {
	constructor(props) {
		super(props);
		const headerField = _.cloneDeep(customerDebitField.header);
		const keys = _.cloneDeep(customerDebitField.data);

		this.state = { headerField, keys, header: null, data: [], paid: [], initialized: false, fetching: true };
	}

	componentDidMount() {
		const { headerField } = this.state;

		Promise.all([
			this.resetDate(),
			mapField(headerField).then((data) => {
				this.setState({ headerField: _.mapKeys(data, 'name') });
			})
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	onResetClick() {
		const //{ reset } = this.props,
			header = null, data = [], paid = [];

		// reset();
		// this.resetDate();
		this.setState({ header, data, paid });
	}

	resetDate() {
		const { change } = this.props;

		const now = Date.now();
		change('date_from', moment(now).format('YYYY-MM-DD'));
		change('date_to', moment(now).format('YYYY-MM-DD'));
	}

	onSubmit(props) {
		const { session: { store_id }, valid } = this.props;

		const params = { 
			...props, 
			store_id: store_id === Number(process.env.REACT_APP_WAREHOUSEID)?undefined:store_id, 
			status: 'unpaid' };
		this.setState({ fetching: true });
		return valid && getFromUrl(`transaction`, params).then(({data:{ data }}) => {
			this.setState({ header: params, data, fetching: false });
		});
	}

	onPrintClick() {
		
		window.print();
	}

	renderHeader() {
		const { header, headerField, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container">
				{_.map(headerField, ({validate, className, ...field}, index) => {
					field.disabled = !_.isEmpty(header) || field.disabled;
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={`${className || "col-12 col-md"} ${header && header[index]?'':'d-print-none'}`} />
				})}
				<div className="d-print-none col" style={{paddingTop: '8px'}}>
					{ !header && <Button type="submit" color="primary" style={{width: '100%'}} disabled={submitting}>Cari</Button>}
					{ header && <Button type="reset" color="danger" style={{width: '100%'}} onClick={this.onResetClick.bind(this)}>Reset</Button>}
				</div>
				<div className="d-print-none col" style={{paddingTop: '8px'}}>
					<Button type="reset" color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this)} disabled={!header}>Print</Button>
				</div>
			</div>
	}

	render() {
		const 
			{ name, handleSubmit } = this.props,
			{ data, keys, initialized, fetching, header } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && <div className="d-flex align-items-center justify-content-end" style={{marginTop: '10px'}}>
					<div className="d-print-none col-4">
						<Button type="reset" color="primary" style={{width: '100%'}} disabled={fetching || header} onClick={() => this.onSubmit()}>Pencarian Keseluruhan Hutang</Button>
					</div>
					<h4 className="col-8 text-right">Total Hutang: Rp {_.sumBy(data, 'unpaid').toLocaleString('id')}</h4>
				</div> }
				{ initialized && <Table data={data} keys={keys} fetching={fetching} /> }
				<A5 />
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataTransactionHistory);
