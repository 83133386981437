import React, { Component } from 'react';
import { Divider } from 'muicss/react';
import moment from 'moment';
import _ from 'lodash';

import { getFromUrl, terbilang } from '../../../actions';

export class Collapse extends Component {
  render() {
    const { name, title, children } = this.props;

    return (
      <div className="row">
        <div className="Collapse col-12" data-toggle="collapse" data-target={`#${name}`} aria-expanded="false" aria-controls={name}>
          <span className="">{ title }</span>
        </div>
        <div id={name} className="collapse col-12">
          <div className="Collapse__content row justify-content-center">
            { children }
          </div>
        </div>
        <Divider className="col-12" />
      </div>
    );
  }
}

export class Transaction extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="col-12 my-3">
        { _.map(data, (v, i) => {
          return <Collapse key={i} name={`Collapse-${v.id}`} title={moment(v.created_at).locale('id').format('DD MMMM YYYY')}>
            <div className="Card col-12">
              <div className="row flex-grow-1">
              { _.map(v.detail_simple, (vDetail, iDetail) => {
                return (
                  <div key={iDetail} className="col-12">
                    <div className={`row ${ iDetail === 0 ? 'border-bottom':'' } ${ iDetail === _.size(v.detail_simple) - 1 ? 'border-top':'' } ${ iDetail !== 0 && !iDetail !== _.size(v.detail_simple) - 1 ? 'border-bottom border-top':'' } align-items-center py-4`}>
                      <div className="col-8">
                        <strong>{ vDetail.category_name } | { vDetail.product_name } | { vDetail.brand_name }</strong>
                        <div>{ vDetail.detail_string }</div>
                      </div>
                      <div className="col-4">
                        <div><small>Rp { vDetail.deal.toLocaleString('id') }</small></div>
                        <strong>{ vDetail.quantity } pcs</strong>
                      </div>
                    </div>
                  </div>
                );
              }) }
              </div>
            </div>
            <div className="col-6 pl-4 py-3">Invoice</div>
            <strong className="col-6">{ v.code }</strong>
            <div className="col-6 pl-4 py-3">Cabang</div>
            <strong className="col-6">{v.store_name}</strong>
            <div className="col-6 pl-4 py-3">Total Transaksi</div>
            <strong className="col-6">Rp { v.sum.toLocaleString('id') }</strong>
            <div className="col-6 pl-4 py-3">Total Pembayaran</div>
            <strong className="col-6">Rp { v.paid.toLocaleString('id') }</strong>
            <div className="col-6 pl-4 py-3">Note</div>
            <strong className="col-6">{ v.ext_note || '-' }</strong>
          </Collapse>
        }) }
      </div>
    );
  }
}

export class Prescription extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="col-12 my-3">
        { _.map(data, (v, i) => {
          const header = _.first(v);
          const prescription = _.mapKeys(_.mapValues(v, (vV) => vV.recipe), (vV) => vV.eye);

          return <Collapse key={i} name={`Collapse-${header.id}`} title={moment(header.created_at).format('DD MMMM YYYY')}>
            <div className="col-4 pl-4 py-3">Invoice</div>
            <strong className="col-8">{ header.code }</strong>
            <div className="col-4 pl-4 py-3">Cabang</div>
            <strong className="col-8">{header.store_name}</strong>
            { prescription.KANAN && <div className="col-12 col-sm-4 pl-4 py-3">Kanan</div> }
            { prescription.KANAN && <strong className="col-12 col-sm-8 font-85 d-flex justify-content-between">
              <span>SPH | { prescription.KANAN.spherical }</span>
              <span>CYL | { prescription.KANAN.cylinder }</span>
              <span>ADD | { prescription.KANAN.addition }</span>
              <span>AXI | { prescription.KANAN.axis }</span>
              <span>PD | { prescription.KANAN.pd }</span>
            </strong> }
            { prescription.KIRI && <div className="col-12 col-sm-4 pl-4 py-3">Kiri</div> }
            { prescription.KIRI && <strong className="col-12 col-sm-8 font-85 d-flex justify-content-between">
              <span>SPH | { prescription.KIRI.spherical }</span>
              <span>CYL | { prescription.KIRI.cylinder }</span>
              <span>ADD | { prescription.KIRI.addition }</span>
              <span>AXI | { prescription.KIRI.axis }</span>
              <span>PD | { prescription.KIRI.pd }</span>
            </strong> }
          </Collapse>
        }) }
      </div>
    );
  }
}

export class Invoice extends Component {
	constructor(props) {
		super(props);

		this.state = { logo: [], note: [], fetching: true };
	}

  componentDidMount() {
    const paramsLogo = {
      table: 'store',
      select: ['image as image_path', 'store.*']
    }

    Promise.all([
      getFromUrl('get', paramsLogo).then(({data:{ data }}) => {
        this.setState({ logo: _.mapKeys(data, 'id') });
      }),
      getFromUrl('invoice-note').then(({data:{ data }}) => {
  			this.setState({ note: _.orderBy(data, 'pos') });
  		})
    ]).then(() => {
      this.setState({ fetching: false });
    });
  }

  render() {
    const { data, session } = this.props;
    const { logo, note, fetching } = this.state;

    return fetching ? <div>{ _.size(data) > 0 && 'Data gagal diterima, harap refresh browser Anda sebelum melanjutkan'}</div> :
      <div className="col-12 my-3">
        { _.map(data, (v, i) => {
          const image = logo[v.store_id];
          let count = 1, qty = 0;
          return <Collapse key={i} name={`Collapse-${v.id}`} title={moment(v.created_at).locale('id').format('DD MMMM YYYY')}>
            <div id="" className="Invoice col">
              { _.isEmpty(image.image_path)?
              <h3 className="Invoice__header">
                <div>{v.store_name}</div>
                <div>{v.store_address}</div>
                <div>{v.store_city_name}</div>
              </h3>:
              <div className="Invoice__header col-12 d-flex justify-content-center">
                <div className="col-9 col-sm-6 col-md-4 col-lg-3">
                  <img alt={image.name} src={image.image_path} width="100%" />
                </div>
              </div> }
              <div className="Invoice__body">
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div>Invoice: {_.toUpper(v.code)} / {v.store_name}</div>
                    <div>Customer: {_.toUpper(session.name)} / {_.toUpper(session.phone)}</div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div>Tgl Transaksi: {moment(v.created_at).locale('id').format('DD MMMM YYYY')}</div>
                    <div>Status Bayar: {v.sum === v.paid?'LUNAS':'DP'}</div>
                  </div>
                  { v.ext_note && <div className="col-12 body-top-note">
                    <div>Note: {v.ext_note}</div>
                  </div>}
                </div>
                <div className="body-content">
                  <table width="100%">
                    <thead>
                      <tr>
                        <th width="6%">No</th>
                        <th width="18%">Merk</th>
                        <th width="50%">Type</th>
                        <th width="7%">Qty</th>
                        <th width="19%">Harga</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(v.detail_simple, (vD) => {
                        qty += _.parseInt(vD.quantity);
                        return (
                          <tr key={count}>
                            <td>{count++}</td>
                            <td>{vD.brand_name}</td>
                            <td>{_.join(_.compact([vD.product_code, _.last(_.split(vD.product_name, ' - ')), vD.product_kind]), ' - ')}</td>
                            <td>{vD.quantity}</td>
                            <td>Rp. { (_.parseInt(vD.deal) * _.parseInt(vD.quantity)).toLocaleString('id') }</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td className="no-border"></td>
                        <td className="no-border"></td>
                        <td className="no-border"></td>
                        <td>{qty}</td>
                        <td className="no-border"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="body-bottom">
                  <div>
                    <div>TOTAL HARGA</div>
                    <div><span>Rp. </span><span>{v.sum.toLocaleString('id')}</span></div>
                  </div>
                  <div className="total"></div>
                  <div>
                    <div>TOTAL BAYAR</div>
                    <div><span>Rp. </span><span>{v.paid.toLocaleString('id')}</span></div>
                  </div>
                  <div className="total"></div>
                </div>
              </div>
              <div className="Invoice__footer">
                <div className="total">TERBILANG : <span>{terbilang(v.paid)} RUPIAH</span></div>
                <div>NOTE :</div>
                <ol>
                  {_.map(note, (vN, iN) => {
                    return <li key={iN}>{vN.note}</li>
                  })}
                </ol>
              </div>
            </div>
          </Collapse>
        }) }
      </div>
  }
}

export class Warranty extends Component {
	constructor(props) {
		super(props);

		this.state = { logo: [], warranty: [], note: [], fetching: true };
	}

  componentDidMount() {
    const paramsLogo = {
      table: 'store',
      select: ['image as image_path', 'store.*']
    }
    const paramsWarranty = {
      table: 'setting_detail',
      where: {
        setting_id: 1, // 1 = Garansi
      }
    }
    const paramsWarrantyNote = {
      table: 'warranty-note',
    }

    Promise.all([
      getFromUrl('get', paramsLogo).then(({data:{ data }}) => {
        this.setState({ logo: _.mapKeys(data, 'id') });
      }),
      getFromUrl('get', paramsWarranty).then(({data:{ data }}) => {
				this.setState({ warranty: _.mapKeys(data, 'store_id') });
			}),
      getFromUrl('get', paramsWarrantyNote).then(({data:{ data }}) => {
				this.setState({ note: _.groupBy(data, 'store_id') });
			}),
    ]).then(() => {
      this.setState({ fetching: false });
    });
  }

  render() {
    const { data } = this.props;
    const { logo, warranty, note, fetching } = this.state;

    return fetching ? <div>{ _.size(data) > 0 && 'Data gagal diterima, harap refresh browser Anda sebelum melanjutkan'}</div> :
      <div className="col-12 my-3">
        { _.map(data, (v, i) => {
          const image = logo[v.store_id];
          const length = warranty[v.store_id];
          const footer = note[v.store_id];
          const prescription = _.mapKeys(v.recipe, 'eye');
          const frame = _.find(v.detail_simple, ['category_name', 'FRAME']);
          const lensa = _.filter(v.detail_simple, ['category_name', 'LENSA']);
          return <Collapse key={i} name={`Collapse-${v.id}`} title={`${moment(v.created_at).locale('id').format('DD MMMM YYYY')} | ${v.store_code} - ${v.code}`}>
            <div id="warranty" className="Warranty col">
              <div className="Warranty__header row">
                <div className="col-6 d-flex align-items-center"><h3 className="Warranty__header-title">KARTU GARANSI</h3></div>
                <div className="col-6 d-flex align-items-center">
                  { _.isEmpty(image.image_path)?
                    <h3 className="text-right">
                      <div>{v.store_name}</div>
                      <div>{v.store_address}</div>
                      <div>{v.store_city_name}</div>
                    </h3>:
                    <div className="row justify-content-end">
                      <div className="col-12 col-md-9">
                        <img alt={image.name} src={image.image_path} width="100%" />
                      </div>
                    </div> }
                </div>
              </div>
              <div className="Warranty__body row">
                <div className="col-12 col-sm-6">
                  <h4 className="d-flex flex-wrap">Name <span>{v.customer_name}</span></h4>
                  <h4 className="d-flex flex-wrap">No/Tgl <span>{`${v.code} | ${moment(v.created_at).format('DD/MM/YY')}`}</span></h4>
                  <h4 className="d-flex flex-wrap">R/OD <small>{prescription.KANAN ?`SP|${prescription.KANAN.spherical} CY|${prescription.KANAN.cylinder} AD|${prescription.KANAN.addition} AX|${prescription.KANAN.axis} PD|${prescription.KANAN.pd} `:'-'}</small></h4>
                  <h4 className="d-flex flex-wrap">L/OS <small>{prescription.KIRI ?`SP|${prescription.KIRI.spherical} CY|${prescription.KIRI.cylinder} AD|${prescription.KIRI.addition} AX|${prescription.KIRI.axis} PD|${prescription.KIRI.pd} `:'-'}</small></h4>
                </div>
                <div className="col-12 col-sm-6">
                  <h4>Lensa</h4>
                  <h4>{_.size(lensa) > 0 ? _.map(lensa, (v, i) => {
                    return <div key={i}>{`${v.brand_name} | ${v.product_name}`}</div>
                  }) : '-'}</h4>
                  <h4>Frame</h4>
                  <h4>{frame ? `${frame.brand_name} | ${frame.product_name}` : '-'}</h4>
                  { length && <h4 className="d-flex flex-wrap">Berlaku Hingga <span>{moment(v.created_at).add(length.value, 'M').format('DD-MM-YYYY')}</span></h4> }
                </div>
              </div>
              <div className="Warranty__footer row">
                { _.map(footer, (v, i) => {
                  return <small key={i} className="col-12">&#8226; {v.note}</small>
                }) }
              </div>
            </div>
          </Collapse>
        }) }
      </div>
  }
}
