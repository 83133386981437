import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import moment from 'moment';
import _ from 'lodash';

import { getFromUrl, mapField } from '../../../actions';
import { FieldItem } from '../../common';

import { A5 } from '../../style';
import { initialize } from '../../../fields';

const _default = {
	items: 10, page: 1
}

class DataProfile extends Component {
	constructor(props) {
		super(props);
		const { slug, session, ...otherProps} = props;
		const { header, model, field, where, content, filter } = initialize(slug, session, otherProps);

		this.state = { header, model, field, where, content, filter, pagination: _default, props: null, data: [], initialized: false, fetching: true };
	}

	componentDidMount() {
		const { field } = this.state;

		Promise.all([
			this.resetDate(),
			..._.map(field, (v, i) => !_.includes(i, 'keys') && mapField(v, this.updateField.bind(this, i)).then((data) => {
				this.setState({ field: { ...this.state.field, [i]: _.mapKeys(data, 'name')} });
			}))
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	updateField(index, column, data) {
		const { field } = this.state;
		const { change } = this.props;

		change(column, null);
		this.setState({ field: { ...field, [index]: {	..._.mapValues(field[index], (v) => _.omit(v, 'value')), [column]: { ...field[index][column], option: data, value: null } } } });
	}

	onResetClick() {
		const //{ reset } = this.props,
			props = null, data = [];

		// reset();
		// this.resetDate();
		this.setState({ props, data, pagination: _default });
	}

	resetDate() {
		const { change } = this.props;
		const { field:{ header }} = this.state;

		const now = moment().format('YYYY-MM-DD');

		_.each(header, (v, i) => {
			v.type === 'date' && change(i, now);
		});
	}

	onSubmit(props) {
		const { valid } = this.props;
		const { model:{ url, ...model }, where, filter } = this.state;

		if(valid) {
			const params = _.merge(_.cloneDeep(model), url?
				_.omitBy(_.cloneDeep(props), _.isNull):
				{ props: _.map(_.omitBy(_.cloneDeep(props), _.isNull), where) });
			this.setState({ fetching: true });
			return getFromUrl(url || `get`, params).then(({data:{ data }}) => {
				const _data = _.isFunction(filter) ? filter(data) : data;
				this.setState({ props, data: _data, fetching: false });
			});
		}
	}

	onPrintClick() {
		window.print();
	}

	onPageClick(page) {
		const {pagination: { items }} = this.state;

		this.setState({ pagination: { page, items }});
	}

	renderHeader() {
		const { props, field:{ header }, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container row">
				{_.map(_.cloneDeep(header), ({validate, className, ...field}, index) => {
					field.disabled = !_.isNull(props) || field.disabled;
					// const empty = field.type === 'select' && _.isEmpty(field.option);
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={`${className || "col-12 col-md"} ${props && props[index]?'':'d-print-none'}`} />
				})}
				<div className="d-print-none col-12" style={{paddingTop: '8px'}}>
					<div className="row d-flex justify-content-end">
						<div className="col col-sm-3">
							{ !props && <Button type="submit" color="primary" style={{ width:'100%' }} disabled={submitting}>Cari</Button>}
							{ props && <Button type="reset" color="danger" style={{ width:'100%' }} onClick={this.onResetClick.bind(this)}>Reset</Button>}
						</div>
						<div className="col col-sm-3 d-none ">
							<Button type="reset" color="primary" style={{ width:'100%' }} onClick={this.onPrintClick.bind(this)} disabled={!props}>Print</Button>
						</div>
					</div>
				</div>
			</div>
	}

	renderPagination(size)
	{
		const { pagination: { items, page } } = this.state;

		return (
			<nav aria-label="Page navigation">
			  <ul className="pagination pagination-lg justify-content-end">
					<li className={`page-item ${page === 1 && 'disabled'}`}>
			      <span className="page-link" aria-label="Previous" onClick={this.onPageClick.bind(this, page - 1)}>
			        <span aria-hidden="true">&laquo;</span>
			        <span className="sr-only">Previous</span>
			      </span>
			    </li>
					{ _.reverse(_.times(3, (v) => {
						return page - (v + 1) > 0 && page - (v + 1) < page && <li key={v} className="page-item"><span className="page-link" onClick={this.onPageClick.bind(this, page - (v + 1))}>{page - (v + 1)}</span></li>
					})) }
			    <li className="page-item active"><span className="page-link">{page}</span></li>
					{ _.times(3, (v, i) => {
						return page + v + 1 <= _.ceil(size / items) && <li key={v} className="page-item"><span className="page-link" onClick={this.onPageClick.bind(this, page + v + 1)}>{page + v + 1}</span></li>
					}) }
			    <li className={`page-item ${page === _.ceil(size / items) && 'disabled'}`}>
			      <span className="page-link" aria-label="Next" onClick={this.onPageClick.bind(this, page + 1)}>
			        <span aria-hidden="true">&raquo;</span>
			        <span className="sr-only">Next</span>
			      </span>
			    </li>
			  </ul>
			</nav>
		);
	}

	render() {
		const
			{ name, session, handleSubmit } = this.props,
			{ header, data, pagination, content, initialized } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && _.isFunction(header) && header(data) }
				{ initialized && _.isFunction(content) && content(data, session, pagination) }
				{ _.size(data) > pagination.items && this.renderPagination(_.size(data)) }
				<A5 />
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataProfile);
