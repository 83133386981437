import React, { Component } from 'react';
import { reduxForm, Field, submit } from 'redux-form';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { getFromUrl, deleteFromUrl, mapField } from '../../actions';
import { Modal } from '../modal';
import { FieldItem, Table, Form } from '../common';

import { transactionCancelField } from '../../fields';

class DataTransactionCancel extends Component {
	constructor(props) {
		super(props);

		const headerField = _.cloneDeep(transactionCancelField.header);
		const keys = _.cloneDeep(transactionCancelField.data);
		const keysRecipe = _.cloneDeep(transactionCancelField.recipe);
		const field = _.cloneDeep(transactionCancelField.footer);
		
		if(props.session.store_id === _.parseInt(process.env.REACT_APP_WAREHOUSEID)) {
			headerField.code.option.name = 'CONCAT(store.code, " - ", transaction.code)';
			headerField.code.option.join = [
				{table: 'store', id: 'store.id', fk: 'transaction.store_id'}
			];
		} else {
			headerField.code.option.store_id = props.session.store_id;
		}

		this.state = { headerField, keys, keysRecipe, field, header: null, data: null, recipe: null, cancel: null, option: [], notFound: 0, modal: 0, initialized: false, fetching: true, note: [], showPrice: true };
	}

	componentDidMount() {
		const { headerField, field } = this.state;

		Promise.all([
			mapField(headerField).then((data) => {
				this.setState({ headerField: _.mapKeys(data, 'name') });
			}),
			mapField(field, this.updateField.bind(this)).then((data) => {
				this.setState({ field: _.mapKeys(data, 'name') });
			}),
			this.getNote()
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	getNote() {
		return getFromUrl('invoice-note').then(({data:{ data }}) => {
			this.setState({ note: _.orderBy(data, 'pos') });
		});
	}

	updateField(column, data) {
		const { field } = this.state;
		const { change } = this.props;

		change(column, null);
		this.setState({ field: { ...field, [column]: { ...field[column], option: data, value: null }} });
	}

	updateHeader(data) {
		const { change } = this.props;

		change('customer_code', data.customer.code);
		change('customer_name', data.customer.name);

		change('sum', data.sum.toLocaleString('id'));
		change('paid', data.paid.toLocaleString('id'));
		change('unpaid', data.unpaid.toLocaleString('id'));
		change('transaction_date', new Date(data.created_at).toLocaleDateString('id'));
		change('payment_name', data.debit.name);
		change('admin_name', data.admin.name);
	}

	onResetClick() {
		const { reset } = this.props,
			header = null, data = null, recipe = null, cancel = null, modal = 0;
		const { headerField, option } = this.state;

		reset();
		this.setState({ header, data, recipe, cancel, modal });
		!_.isEmpty(option) && this.setState({ headerField: { ...headerField, code: { ...headerField.code, option }}, option: [] });
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			this.setState({ fetching: true });
			return getFromUrl(`transaction/${props.code}`).then(({data:{ data }}) => {
				this.updateHeader(data);
				const recipe = _.mapKeys(data.recipe, 'eye');
				this.setState({ header: props, data, recipe, fetching: false });
			}).catch((err) => {
				if( err.response && err.response.status === 404) {
					this.setState({ notFound: this.state.notFound + 1, fetching: false });
				}
			});
		}
	}

	onSaveClick(props) {
		const { session: { id } } = this.props;
		const { header } = this.state;

		return deleteFromUrl(`transaction/${header.code}`, { ...props, admin_id: id }).then(({data:{ data }}) => {
			const { headerField } = this.state;
			const option = _.map(_.omitBy(_.cloneDeep(headerField.code.option), ['value', header.code]));
			this.setState({ cancel: data, option });
		}).catch((err) => {
			
		});
	}

	triggerSubmit(name) {
		const { dispatch } = this.props;

		dispatch(submit(name));
	}

	renderHeader() {
		const { header, headerField, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container">
				<div className="col-6">
					{_.map(_.pickBy(_.cloneDeep(headerField), ['group','left']), ({validate, group, ...field}, index) => {
						field.disabled = !_.isEmpty(header) || field.disabled;
						return index === 'code'?
							<div key={index} className="row">
								<div className="col-8">
									<Field name={index} component={FieldItem} field={field} validate={validate} />
								</div>
								<div className="col-4" style={{paddingTop: '8px'}}>
								{!header && <Button type="submit" color="primary" style={{width: '100%'}} disabled={submitting}>Cari</Button>}
								{header && <Button type="reset" color="danger" onClick={this.onResetClick.bind(this)} style={{width: '100%'}}>Reset</Button>}
								</div>
							</div>:
							<Field key={index} name={index} component={FieldItem} field={field} validate={validate} />
					})}
				</div>
				<div className="col-3">
					{_.map(_.pickBy(headerField, ['group','middle']), ({validate, group, ...field}, index) => {
						field.disabled = !_.isEmpty(header) || field.disabled;
						return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} />
					})}
				</div>
				<div className="col-3">
					{_.map(_.pickBy(headerField, ['group','right']), ({validate, group, ...field}, index) => {
						field.disabled = !_.isEmpty(header) || field.disabled;
						return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} />
					})}
				</div>
			</div>
	}

	renderFooter() {
		const { cancel, field } = this.state;

		return (
			<div className="input-container d-print-none">
				<div className="col-8">
					<Form form="footerForm" onSubmit={this.onSaveClick.bind(this)} keep>
						{_.map(_.omitBy(_.cloneDeep(field), v => v.type === 'hidden'), ({validate, className, ...field}, index) => {
							field.disabled = field.type === 'select'?_.isEmpty(field.option) || field.disabled:field.disabled;
							field.disabled = !_.isNull(cancel) || field.disabled;
							className = className?className:'col-6';
							return <Field key={index} name={index} component={FieldItem} field={field} validate={field.disabled?null:validate} className={className}/>
						})}
					</Form>
				</div>
				<div className="col-4">
					<div className="col-12" style={{paddingTop: '8px'}}>
						<Button color="primary" style={{width: '100%'}} onClick={()=>{this.setState({ modal: this.state.modal + 1 })}} disabled={!_.isNull(cancel)}>{(cancel)?"Pembatalan Berhasil":"Proses Pembatalan"}</Button>
					</div>
					<div className="col-12" style={{paddingTop: '8px'}}>
						<Button color="primary" style={{width: '100%'}} onClick={this.onResetClick.bind(this)} disabled={!cancel}>Transaksi Selesai</Button>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const 
			{ name, handleSubmit } = this.props,
			{ data, recipe, keys, keysRecipe, notFound, modal, initialized, fetching } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && <Table data={data && data.detail} keys={keys} fetching={fetching} /> }
				{ initialized && !_.isEmpty(recipe) && <Table data={recipe} keys={keysRecipe} fetching={fetching} /> }
				{ !_.isEmpty(data) && this.renderFooter()}
				<Modal title="Tidak Ditemukan" type="notice" modal={notFound} onButtonClick={()=>{}}>
					<h2 className="text-center">Kode Invoice Tidak Ditemukan</h2>
				</Modal>
				<Modal title="Pembatalan Transaksi" modal={modal} onButtonClick={this.triggerSubmit.bind(this, 'footerForm')}>
					<h2 className="text-center">Apakah anda yakin data sudah benar?</h2>
				</Modal>
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataTransactionCancel);
