import React from 'react';
import _ from 'lodash';

export const A5 = () => {
	return (
		<style type="text/css" media="print" dangerouslySetInnerHTML={{__html: "@page{margin: 1cm 1.5cm 1.5cm}"}}></style>
		);
}

export const TnJ109 = () => {
	return (
		<style type="text/css" media="print" dangerouslySetInnerHTML={{__html: "@page{margin: 2.75mm 2.25mm; size: 210mm 165mm;} .container-fluid > *:not(.print-area) {display: none !important}"}}></style>
		);
}

export const InvoiceStyle = () => {
	return (
		<style type="text/css" media="print" dangerouslySetInnerHTML={{__html: "@page{margin: 0.8cm 0.8cm} .container-fluid > *:not(#invoice) {display: none !important}"}}></style>
		);
}

export class CmsStyle extends React.Component {	
	componentDidMount() {
		var checkExist = setInterval(function() {
			var awc = document.getElementById("arc-widget-container");
		  if(awc) {
				var css = _.compact([awc.getAttribute("style"), "display: none !important;"]);
				awc.setAttribute("style", _.join(css, " "));
		    clearInterval(checkExist);
		  }
		}, 100);
	}
	
	render() {
		return null;
	}
}
