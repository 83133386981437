import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { Button } from 'muicss/react';
import _ from 'lodash';

import { Datatable } from '../common';
import { ModalEdit, ModalRemove } from '../modal';

/*/
export class TableItem extends Component {
	shouldComponentUpdate(nextProps) {
		const equal = !_.isEqual(this.props.data, nextProps.data);
		const fetching = this.props.fetching !== nextProps.fetching;
		return equal || fetching;
	}

	renderHead(keys) {
		return _.map(keys, (value, index) => {
			return (
				<th key={index} {...(_.isObject(value)?_.omit(value, ['render']):{ title: value })}>
				{
					_.isObject(value)?
						(value.title && _.isString(value.title)?
							value.title:
						_.startCase(index)):
					value
				}
				</th>
			);
		});
	}

	renderBody(data, keys) {
  	const { submenu, edit, remove, slug, onEditClick, onRemoveClick } = this.props;
		return _.map(data, (value, index) => {
			return (
				<tr key={index}>
					{this.renderBodyItem(value, keys)}
					{
						submenu &&
						<td className="action d-none d-sm-table-cell" width="8%">
							<Link to={
								(slug.indexOf('/') === -1)?
								`/${slug}/${value.id}/${submenu}`:
								`/${slug}/${submenu}/${value.id}`
							}><Button>Lihat</Button></Link>
						</td>
					}
					{
						(edit === 1 || remove === 1) &&
						<td className="action" width="7%">
							{ (edit === 1) && <i className="fa fa-pencil-square-o" aria-hidden="true" title="edit" onClick={onEditClick.bind(this, index)}></i> }
							{ (remove === 1) && <i className="fa fa-trash-o" aria-hidden="true" title="remove" onClick={onRemoveClick.bind(this, index)}></i> }
						</td>
					}
				</tr>
			);
		});
	}

	renderBodyItem(data, keys) {
		return _.map(keys, (value, index) => {
			return (
				<td key={index} {...(_.isObject(value)?_.omit(value, ['title', 'render']):[])}>
				{
					_.isObject(value)?
						(_.isFunction(value.render)?
							value.render(data[index], data):
							(data[index] || data[index] === 0?data[index]:'-')):
						(data[index] || data[index] === 0?data[index]:'-')
				}
				</td>
			);
		});
	}

  render() {
  	const { data, keys, submenu, edit, remove, fetching } = this.props;
		
		return (
			<table className="table table-hover">
				<thead>
					<tr>
						{this.renderHead(keys)}
						{ submenu && <th className="action d-none d-sm-table-cell">{_.startCase(submenu)}</th> }
						{ (edit === 1 || remove === 1) && <th className="action">Action</th> }
					</tr>
				</thead>
				<tbody>
					{(_.size(data) > 0 && !fetching)?this.renderBody(data, keys):
						<tr>
							<td colSpan="100%" style={{textAlign: 'center'}}>
								{fetching?<span><i className="fa fa-spinner fa-pulse"  aria-hidden="true"></i> &nbsp; MEMUAT ...</span>:'Data Kosong'}
							</td>
						</tr>
					}
				</tbody>
			</table>
		);
	}
}
/*/

export class Table extends Component {
	constructor(props) {
	  super(props);

	  this.state = { edit: null, remove: null };
	}

	// componentDidUpdate() {
		// if(this.state.edit !== null) {
		// 	this.setState({ edit: null });
		// }
		// if(this.state.remove !== null) {
		// 	this.setState({ remove: null });
		// }
	// }

	onEditClick(edit) {
		this.setState({ edit });
	}

	onRemoveClick(remove) {
		this.setState({ remove });
	}

	onClick(id, type) {
		this.setState({ [type]: id });
	}

  render() {
  	const { data, submenu, edit, remove, slug, name, field, onEditSubmit, onRemoveSubmit, bsSize } = this.props;

		const editableField = _.omitBy(field, (value) => {
			return value['data-hidden-edit'];
		});

		const props = _.omit(this.props, ['field', 'submenu', 'edit', 'remove', 'onEditSubmit', 'onRemoveSubmit', 'bsSize']);
		const action = { submenu, edit, remove };
		
    return (
    	<div className="table-content" style={{flexBasis: '100%'}}>
	      {/*/<TableItem {...props} onEditClick={this.onEditClick.bind(this)} onRemoveClick={this.onRemoveClick.bind(this)} />/*/}
	      <Datatable ref="datatable" {...props} action={action} onClick={this.onClick.bind(this)} />
				{ (edit === 1) && <ModalEdit id={this.state.edit} slug={slug} bsSize={bsSize} field={editableField} data={this.state.edit !== null && data[this.state.edit]} onEditSubmit={onEditSubmit} unmount={() => this.setState({ edit: null })} /> }
				{ (remove === 1) && <ModalRemove id={this.state.remove} slug={slug} name={name || 'name'} data={this.state.remove !== null && data[this.state.remove]} onRemoveSubmit={onRemoveSubmit} unmount={() => this.setState({ remove: null })} /> }
			</div>
    );
  }
}
