import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { getFromUrl, saveData, mapField } from '../../actions';
import { Modal, ModalAdd } from '../modal';
import { FieldItem, Table } from '../common';

import { A5 } from '../style';
import { opnameField } from '../../fields';

class DataOpname extends Component {
	constructor(props) {
		super(props);

		const headerField = _.cloneDeep(opnameField.header);
		const field = _.cloneDeep(opnameField.data);
		const keys = _.cloneDeep(opnameField.keys);

		this.state = { headerField, field, keys, header: null, data: [], opname: null, option: [], saving: false, modal: 0, initialized: false, fetching: true };
	}

	componentDidMount() {
		const { headerField, field } = this.state;

		Promise.all([
			mapField(headerField).then((data) => {
				this.setState({ headerField: _.mapKeys(data, 'name') });
			}),
			mapField(field).then((data) => {
				this.setState({ field: _.mapKeys(data, 'name') });
			}),
		]).then(() => {
			this.initHeader()
			this.setState({ fetching: false, initialized: true });
		});
	}

	initHeader() {
		const { session:{ id }, change } = this.props;

		change('admin_id', id);
	}

	updateProduct(props) {
		const { dispatch } = this.props;

		this.setState({ fetching: true });
		return getFromUrl('select-product', { store_id: props.store_id, select: [ 'stock' ], stock: 0, detail: 0 }).then(({data:{ data }}) => {
			const field = _.cloneDeep(this.state.field);
			field.product_id.option = data;
			field.product_id.onChange = (value) => {
				dispatch(change('addForm', 'stock', value?value.stock:0));
				dispatch(change('addForm', 'label', value?value.label:''));
			}
			this.setState({ field, header: props, fetching: false });
		});
	}

	onResetClick() {
		const { reset } = this.props,
			header = null, data = [], opname = null, saving = false, modal = 0;

		reset();
		this.initHeader();
		this.setState({ header, data, opname, saving, modal });
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			return this.updateProduct(props);
		}
	}

	onAddSubmit(props) {
		const { data } = this.state;

		this.setState({ data: {...data, [props.product_id]: props }});
	}

	onEditSubmit(props, id) {
		const { data } = this.state;

		this.setState({data: {...data, [id]: props}});
	}

	onRemoveSubmit(id) {
		const { data } = this.state;

		this.setState({data: _.omit(data, [id])});
	}

	onSaveClick() {
		const { header, data } = this.state,
			{ session, change } = this.props;

		this.setState({ saving: true });
		return saveData("opname", { header, data, session }).then(({data:{ data }}) => {
			change('code', data.code);
			this.setState({ opname: data });
		}).catch((err) => {
			this.setState({ saving: false });
		});
	}

	onPrintClick() {

		window.print();
	}

	renderHeader() {
		const	{ header, headerField, initialized } = this.state;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container" style={{justifyContent: 'flex-end'}}>
				{_.map(_.cloneDeep(headerField), ({validate, className, ...field}, index) => {
					field.disabled = !_.isEmpty(header) || field.disabled;
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={className} />
				})}
				<div className="d-print-none" style={{flexBasis: '25%', paddingTop: '8px'}}>
					{!header && <Button type="submit" color="primary" style={{width: '100%'}}>Tetapkan</Button>}
					{header && <Button type="reset" color="danger" onClick={this.onResetClick.bind(this)} style={{width: '100%'}}>Ulang</Button>}
				</div>
				<div className="d-print-none" style={{flexBasis: '100%', color: 'red'}}>
					* Kode akan ditampilkan saat proses simpan berhasil
				</div>
			</div>
	}

	renderFooter() {
		const { opname, saving } = this.state;
		return (
			<div className="input-container d-print-none">
				<div style={{flexBasis: '100%', color: 'red'}}>
					* Harap simpan data sebelum meninggalkan halaman ini
				</div>
				<div style={{flexBasis: '25%', paddingRight: '15px'}}>
					<Button color="primary" style={{width: '100%'}} onClick={()=>{this.setState({ modal: this.state.modal + 1 })}} disabled={saving}>{(opname)?"Data Tersimpan":"Simpan"}</Button>
				</div>
				<div style={{flexBasis: '25%', paddingRight: '15px'}}>
					<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this)} disabled={!saving && !opname}>Print</Button>
				</div>
			</div>
		);
	}

	render() {
		const 
			{ name, slug, remove, handleSubmit } = this.props,
			{ field, keys, header, data, opname, modal } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{ this.renderHeader() }
				</form>
				<A5/>
				{ header && !opname && <ModalAdd bsSize="md" className="d-print-none" slug={slug} field={field} labelCancel="Selesai" onAddSubmit={this.onAddSubmit.bind(this)}/> }
				{ header && <Table bsSize="md" data={data} slug={slug} field={field} keys={keys} remove={!opname && remove} onRemoveSubmit={this.onRemoveSubmit.bind(this)} name="label" /> }
				{ !_.isEmpty(data) && this.renderFooter()}
				<Modal title="Stok opname" modal={modal} onButtonClick={this.onSaveClick.bind(this)}>
					<h2 className="text-center">Apakah anda yakin data sudah benar?</h2>
				</Modal>
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataOpname);
