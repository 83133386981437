import React, { Component } from 'react';
import { reduxForm, Field, FieldArray, reset, change } from 'redux-form';
import Select from 'react-select';
import { Input, Button, Divider } from 'muicss/react';
import _ from 'lodash';

import { getSelect, getFromUrl, saveData, getLogo } from '../../actions';
import { Modal } from '../modal';
import { FieldItem, Table, Form, Invoice } from '../common';

import { transactionField, maxValue } from '../../fields';

let headerField, field, keys, recipeKeys, orderField, footerField, paymentField, paymentDetailField;

class DataTransaction extends Component {
	constructor(props) {
		super(props);

		this.state = { state: 0, header: null, customer: {}, data: [], except: [], product: [], order: [], recipe: [], transaction: null, option: [], saving: false, footer: { total: 0 }, value: null, type: null, total: [], payment: null, detailField: [], isOrder: false, order_id: null, modal: 0, pending: 0, note: [], showPrice: true, showRecipe: true, tError: '', logo: [], history: [], filter: [], transactionNote: {}, code: {}, loading: true };
	}

	componentWillMount() {
		const { session:{ store_id }} = this.props;

		headerField = _.cloneDeep(transactionField.header);
		headerField.sales_id.option.store_id = store_id;
		// headerField.admin_id.option.store_id = store_id;
		// if(usergroup_id === Number(process.env.REACT_APP_SUPERADMIN)) {
		// 	headerField.admin_id.option[id - 1] = undefined;
		// }
		orderField = _.cloneDeep(transactionField.order);
		orderField.header.name.option = [];
		footerField = _.cloneDeep(transactionField.footer);
		paymentField = _.cloneDeep(transactionField.payment);
		paymentDetailField = _.cloneDeep(transactionField.payment_detail);
		Promise.all([
			headerField = this.mapValues(headerField, true),
			this.onOrderFieldChange(),
			this.initField(),
			getLogo(store_id).then(({data:{ data }}) => {
				this.setState({ logo: data });
			}),
			this.getNote(),
			this.getCode(),
		]).then(() => {
			// this.setState({ loading: false });
			// const { sell_locale, ...otherField } = orderField.header;
			// orderKeys = _.mapKeys(_.mapValues(_.omitBy({...otherField, eye: {label: 'Mata'},...orderField.body}, (value, index) => { return value.hidden || index === 'description'; }), (value) => { return value.label }), (value, index) => { return _.replace(index, '_id', '_name') });
			headerField.customer_id.onChange = (value) => {
				this.props.change('name', '');
				this.props.change('phone', '');
				this.props.change('address', '');
				headerField.name.disabled = (value)?true:false;
				headerField.phone.disabled = (value)?true:false;
				headerField.address.disabled = (value)?true:false;
				this.setState({ customer: value });
			}
			this.setState({validate: field.quantity.validate, loading: false});
			// this.updateHeader();
		});
	}

	getNote() {
		return getFromUrl('invoice-note').then(({data:{ data }}) => {
			this.setState({ note: _.orderBy(data, 'pos') });
		});
	}

	getCode() {
		return getFromUrl('code/1').then(({data:{ data }}) => {
			this.setState({ code: data });
		});
	}

	onOrderFieldChange() {
		const { dispatch } = this.props;

		orderField.header.brand_id.onChange = (value) => {
			let option = _.cloneDeep(transactionField.order.header.name.option);

			orderField.header.brand_id.value = value?value.value:null;
			option.brand_id = value?value.value:0;
			option.kind = orderField.header.kind.value || 0;
			if(option.brand_id !== 0 && option.kind !== 0) {
				getSelect(option).then(({data:{ data }}) => {
					orderField.header.name.option = data;
					this.setState({state: this.state.state + 1});
				});
			}
			dispatch(change('orderForm', 'name', ''));
			orderField.header.sell_locale.value = null;
			this.setState({ order_id: null });
		}
		orderField.header.kind.onChange = (value) => {
			let option = _.cloneDeep(transactionField.order.header.name.option);

			orderField.header.kind.value = value?value.value:null;
			option.kind = value?value.value:0;
			option.brand_id = orderField.header.brand_id.value || 0;
			if(option.brand_id !== 0 && option.kind !== 0) {
				getSelect(option).then(({data:{ data }}) => {
					orderField.header.name.option = data;
					this.setState({state: this.state.state + 1});
				});
			}
			dispatch(change('orderForm', 'name', ''));
			orderField.header.sell_locale.value = null;
			this.setState({ order_id: null });
		}
		orderField.header.name.onChange = (value) => {
			const order = value?{header: value, KANAN: false, KIRI: false}:[];
			orderField.header.sell_locale.value = value?value.sell_locale:null;
			this.setState({ order_id: value?value.value:null, order });
		}
	}

	initField() {
		field = _.cloneDeep(transactionField.data);
		keys = _.cloneDeep(transactionField.keys);
		recipeKeys = _.cloneDeep(transactionField.recipe);
		// keys = _.mapKeys(_.mapValues(_.omitBy(field, (value, index) => { return value.hidden || index === 'description'; }), (value) => { return value.label }), (value, index) => { return _.replace(index, '_id', '_name') });
		// field = this.mapValues(field);
	}

	mapAfterHeader() {
		orderField.header = this.mapValues(orderField.header, true);
		orderField.body = this.mapValues(orderField.body, true);
		// footerField = this.mapValues(footerField, true);
		paymentField = this.mapValues(paymentField, true, transactionField.payment);
		// paymentDetailField = this.mapValues(paymentDetailField, true, transactionField.payment_detail);
	}

	mapValues(field, state = false, clone = []) {
		return _.mapValues(field, (value, index) => {
			if(value.type !== 'async' && value.option && value.option.table) {
				getSelect(value.option).then(({data:{ data }}) => {
					value.option = data;
					if(state) this.setState({state: this.state.state + 1});
					else {
						const { option } = this.state;
						this.setState({ option: {...option, [index]: _.mapKeys(data, (v) => {return v.value})}});
					}
				});
			}
			if(typeof value.onChange === 'string') {
				const col = value.onChange;
				value.onChange = (target) => {
					field[col].option = {
						...clone[col].option,
						[index]: (target && target.value) || 0
					};
					if(!field[col].onChange) {
						field[col].onChange = (target) => {
							field[col].value = target && target.value;
							this.setState({ state: this.state.state + 1 });
						};
					}
					getSelect(field[col].option).then(({data: { data }}) => {
						field[col].option = data;
						field[col].value = null;
						this.setState({ state: this.state.state + 1 });
					});
				};
			}
			return value;
		});
	}

	updateStock(props) {
		const { session:{ store_id }, dispatch } = this.props;
		const label = 'concat(product.code, " - ", brand.name, " - ", product.name)';

		return getFromUrl('select-product', {store_id, label, detail: true}).then(({data:{ data }}) => {
			field.product_id.option = data;
			field.product_id.onChange = (value) => {
				const { validate } = this.state;
				dispatch(change('bodyForm', 'eye', ''));
				dispatch(change('bodyForm', 'axis', ''));
				dispatch(change('bodyForm', 'pd', ''));
				if(value) {
					let qData = 0;
					if(value.category_id === 5) {
						qData = _.sumBy(_.filter(this.state.data, ['value', value.value]), ({ quantity }) => _.toInteger(quantity));
					}
					field.quantity.validate = [...validate, maxValue(_.subtract(value.stock, qData))];
					if(value.category_id === 5) {
						field.eye.type = 'select';
						field.axis.type = field.pd.type = 'number';
						// dispatch(change('bodyForm', 'eye', 'BOTH'));
						dispatch(change('bodyForm', 'axis', field.axis.values));
						dispatch(change('bodyForm', 'pd', field.pd.values));
					}else {
						field.eye.type = field.axis.type = field.pd.type = 'hidden';
					}
				} else {
					field.quantity.validate = validate;
					field.eye.type = 'hidden';
				}
				dispatch(change('bodyForm', 'quantity', '1'));
				this.setState({ state: this.state.state + 1 });
			}
			this.setState({ header: props, product: _.mapKeys(data, ({value}) => value)});
		});
	}

	onResetClick() {
		const { reset } = this.props,
			header = null, customer = {}, data = [], except = [], product = [], order = [], recipe = [], transaction = null, saving = false, footer = { total: 0 }, value = null, type = null, total = [], payment = null, detailField = [], isOrder = false, order_id = null, modal = 0, history = [], filter = [], transactionNote = {}, code = {};

		reset();
		headerField.name.disabled = false;
		headerField.phone.disabled = false;
		headerField.address.disabled = false;
		// this.updateHeader();
		this.setState({ header, customer, data, except, product, order, recipe, transaction, saving, footer, value, type, total, payment, detailField, isOrder, order_id, modal, history, filter, transactionNote, code});
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			return Promise.all([
				props.customer_id && getFromUrl(`customer/${props.customer_id}/recipe`).then(({data:{ data }}) => {
					this.setState({ history: data });
				}),
				this.updateStock(props),
				this.mapAfterHeader()
			]);
			// const header = { ...props	};
			// this.initField();
			// this.setState({ header: props });
		}
	}

	onAddSubmit(props) {
		const { product } = this.state;
		let { data } = this.state, except = [];
		const optionalKey = ['axis', 'pd', 'eye'];

		props = {...props, ...product[props.product_id]};
		// HITUNG DISKON & TOTAL HARGA
		// props.discount = "0";
		props.total = props.price * props.quantity;
		props.total_locale = props.total.toLocaleString('id');
		data = {...data, [`${props.product_id}${props.eye?'-'+props.eye:''}`]: props};

		if(!_.find(data, ['category_id', 5])) {
			except = optionalKey;
		}
		this.updateTotal(data);
		this.setState({ data, except });
	}

	onPaymentSubmit(props) {
		const payment = _.cloneDeep(props);
		const { total, footer } = this.state;

		const unpaid = (_.isFinite(footer.sum)?footer.sum:footer.total) - _.sum(total);
		payment.total = _.sum(_.map(payment.detail, v => v?_.parseInt(v.total || 0):0));
		payment.unpaid = unpaid.toLocaleString('id');

		if(unpaid < 0) {
			// error
		}else {
			this.setState({ payment });
		}
	}

	onOrderCancel() {
		_.each(orderField.header, (v, i) => {
			orderField.header[i].value = null;
		});
		this.updateTotal(this.state.data, []);
		this.setState({ isOrder: false, order: [], order_id: null, state: this.state.state + 1 });
	}

	onOrderSubmit(type, props) {
		if(!_.isEmpty(props)) {
			let { order } = this.state;
			order = {
				...order,
				data: {
					...order.data,
					[type]: {
						...props,
						...order.header,
						eye: type
					}
				}
			};

			this.updateTotal(this.state.data, order);
			this.setState({ order });
		}
	}

	updateTotal(data = this.state.data, order = this.state.order) {
		const total = _.add(_.sum(_.map(data, 'total')), (order.header && order.header.price)?(_.size(order.data) * order.header.price):0);

		this.onPaymentBodyReset();

		this.setState({ footer: { total }, value: null, type: null });
	}

	onPaymentReset() {
		const payment = null;

		this.setState({ payment });
	}

	onRemoveSubmit(id) {
		let { data } = this.state;
		data = _.omit(data, [id]);
		this.updateTotal(data);
		this.setState({ data });
	}

	onSaveClick() {
		const { header, data, order, footer, payment, transactionNote } = this.state,
			{ session, change } = this.props;
		const pick = ['spherical', 'cylinder', 'addition', 'axis', 'pd', 'eye'];

		this.setState({ saving: true, tError: '' });
		const filter = _.mapKeys(_.filter(data, 'eye'), 'eye');
		let recipe = {
			// KANAN: false, KIRI: false
		};
		if(filter.BOTH){
			recipe.KANAN = { ..._.pick(filter.BOTH, pick), eye: 'KANAN', product_id: filter.BOTH.id || filter.BOTH.value };
		}else if(filter.KANAN){
			recipe.KANAN = {..._.pick(filter.KANAN, pick), product_id: filter.KANAN.id || filter.KANAN.value};
		}else if(order.data && order.data.KANAN) {
			recipe.KANAN = {..._.pick(order.data.KANAN, pick), product_id: order.data.KANAN.value};
		}
		if(filter.BOTH){
			recipe.KIRI = { ..._.pick(filter.BOTH, pick), eye: 'KIRI', product_id: filter.BOTH.id || filter.BOTH.value };
		}else if(filter.KIRI){
			recipe.KIRI = {..._.pick(filter.KIRI, pick), product_id: filter.KIRI.id || filter.KIRI.value};
		}else if(order.data && order.data.KIRI) {
			recipe.KIRI = {..._.pick(order.data.KIRI, pick), product_id: order.data.KIRI.value};
		}

		return saveData("transaction", { header, data, order, recipe, session, footer, payment, note: transactionNote }).then(({data:{ data }}) => {
			change('code', data.code);
			this.setState({ transaction: data, recipe, saving: false });
		}).catch((err) => {
			err && err.response && err.response.data && this.setState({ tError : JSON.stringify(err.response.data.message) });
			this.setState({ saving: false });
		});
	}

	onPendingClick() {
		const { header:{ customer_id }, data, order } = this.state;
		const pick = ['spherical', 'cylinder', 'addition', 'axis', 'pd', 'eye'];

		this.setState({ saving: true, tError: '' });
		const filter = _.mapKeys(_.filter(data, 'eye'), 'eye');
		let recipe = {
			// KANAN: false, KIRI: false
		};
		if(filter.BOTH){
			recipe.KANAN = { ..._.pick(filter.BOTH, pick), eye: 'KANAN', product_id: filter.BOTH.id || filter.BOTH.value };
		}else if(filter.KANAN){
			recipe.KANAN = {..._.pick(filter.KANAN, pick), product_id: filter.KANAN.id || filter.KANAN.value};
		}else if(order.data && order.data.KANAN) {
			recipe.KANAN = {..._.pick(order.data.KANAN, pick), product_id: order.data.KANAN.value};
		}
		if(filter.BOTH){
			recipe.KIRI = { ..._.pick(filter.BOTH, pick), eye: 'KIRI', product_id: filter.BOTH.id || filter.BOTH.value };
		}else if(filter.KIRI){
			recipe.KIRI = {..._.pick(filter.KIRI, pick), product_id: filter.KIRI.id || filter.KIRI.value};
		}else if(order.data && order.data.KIRI) {
			recipe.KIRI = {..._.pick(order.data.KIRI, pick), product_id: order.data.KIRI.value};
		}

		return saveData(`customer/${customer_id}/recipe`, { recipe }).then(({data:{ data }}) => {
			this.onResetClick();
		}).catch((err) => {
			err && err.response && err.response.data && this.setState({ tError : JSON.stringify(err.response.data.message) });
			this.setState({ saving: false });
		});
	}

	onValueChange({target: { value }}) {

		this.setState({ value });
	}

	onValueClick(type) {
		const { footer, value, code: { max_discount } } = this.state;
		let error = "";

		if(_.isFinite(_.parseInt(value))) {
			footer.sum = footer.total;

			switch(type.value) {
				case 'DEAL':
					((footer.sum - value) / footer.sum) * 100 > max_discount?
					error = `Diskon tidak bisa lebih dari ${max_discount}%`:
					footer.sum = _.parseInt(value);
					break;
				case 'REBATE':
					if(value > footer.total) {
						error = "Harga Potongan tidak bisa lebih besar dari total harga";
					}else{
						(value / footer.sum) * 100 > max_discount?
						error = `Diskon tidak bisa lebih dari ${max_discount}%`:
						footer.sum -= _.parseInt(value);
					}
					break;
				case 'DISCOUNT':
					value > max_discount?
					error = `Diskon tidak bisa lebih dari ${max_discount}%`:
					footer.sum *= (100 - _.parseInt(value))/100;
					break
				default:
					break
			}
			if(_.isEmpty(error)) {
				footer.value = { [type.value]: _.parseInt(value) };
				this.onPaymentBodyReset();
				this.setState({ footer, type, error });
			}else {
				this.setState({ error });
			}
		}
	}

	onValueReset() {
		const { total } = this.state.footer;
		this.onPaymentBodyReset();

		this.setState({ footer: { total }, value: null, type: null });
	}

	onPaymentBodyReset() {
		this.setState({ payment: null });
	}

	onFormReset(form) {
		const { dispatch } = this.props;

		dispatch(reset(form));
	}

	onOrderBodyReset(form, type) {
		const { order } = this.state;

		this.onFormReset(form);
		this.setState({ order: {...order, data: _.omit(order.data, [type]), [type]: !order[type]} });
	}

	onAddPaymentClick(fields) {
		const { detailField, total } = this.state;

		fields.push();
		detailField.push(_.cloneDeep(paymentDetailField));
		total.push(0);
		const lastIndex = _.size(detailField) - 1;
		detailField[lastIndex] = this.mapValues(detailField[lastIndex], false, transactionField.payment_detail);

		this.updatePaymentTotalValue(detailField);
		this.setState({ total });
	}

	onRemovePaymentClick(fields, index) {
		const { detailField, total } = this.state;

		fields.remove(index);
		detailField.splice(index, 1);
		total.splice(index, 1);

		this.updatePaymentTotalValue(detailField);
		this.setState({ total });
	}

	updatePaymentTotalValue(detailField) {
		_.each(_.cloneDeep(detailField), (v, i) => {
			detailField[i].total.onChange = ({target:{ value }}) => {
				const { total } = this.state;
				total[i] = _.parseInt(value || 0);
				// this.setState({ total });
			}
		});

		this.setState({ detailField });
	}

	updatePaymentSelect(name, index, value) {
		const { dispatch } = this.props;

		// value && dispatch(change('paymentForm', `detail.${index}.${name}`, value.value));
		(name === 'payment_id') && dispatch(change('paymentForm', `detail.${index}.payment_detail_id`, ''));
	}

	updatePaymentValues() {
		const { total, footer } = this.state;

		const values = _.mapValues(paymentField, 'value');

		values.total = _.sum(total);
		values.unpaid = ((_.isFinite(footer.sum)?footer.sum:footer.total) - values.total).toLocaleString('id');

		return values;
	}

	onPrintClick(showPrice = true, showRecipe = true) {
		Promise.resolve(this.setState({ showPrice, showRecipe })).then(() => {
			window.print();
		});
	}

	renderHeader() {
		const { header, transaction, loading, state } = this.state;
		const { submitting } = this.props;
		const { sales_id, code, ...customer } = headerField;

		return loading || state === 0?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container form-row justify-content-end">
				<div className="col-sm-4 col-12">
					<div className="row">
						{_.map(_.extend({}, { sales_id, code }), ({validate, className, ...field}, index) => {
							field.disabled = !_.isEmpty(header) || field.disabled;
							return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={className} />
						})}
					</div>
				</div>
				<div className="col-sm-8 col-12">
					<div className="form-row">
						{_.map(customer, ({validate, className, ...field}, index) => {
							field.disabled = !_.isEmpty(header) || field.disabled;
							return <Field key={index} name={index} component={FieldItem} field={field} validate={field.disabled?null:validate} className={className} />
						})}
					</div>
				</div>
				<div className="col-sm-4 col-12" style={{ paddingTop: '8px' }} hidden={transaction}>
					{ _.isEmpty(header) && <Button type="submit" color="primary" style={{width: '100%'}} disabled={submitting}>Tetapkan</Button>}
					{ !_.isEmpty(header) && <Button type="reset" color="danger" onClick={this.onResetClick.bind(this)} style={{width: '100%'}}>Batalkan</Button>}
				</div>
			</div>
	}

	renderField() {
		return (
			<Form form="bodyForm" onSubmit={this.onAddSubmit.bind(this)}>
				<div className="input-container form-row justify-content-end">
					{_.map(_.omitBy(field, ['type', 'hidden']), ({validate, className, hidden, ...field}, index) => {
						return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} hidden={hidden} className={className} />
					})}
					<div className="col-sm-2 col-12" style={{ paddingTop: '8px' }}>
						<Button type="submit" color="primary" style={{width: '100%'}}>Tambah</Button>
					</div>
				</div>
			</Form>
		);
	}

	renderOrderHeaderField() {
		return _.map(orderField.header, ({validate, className, value, ...field}, index) => {
			// field.disabled = order_id || field.disabled;
			return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={className} />
		});
	}

	renderOrderBodyField(type) {
		const { order } = this.state;

		return _.map(orderField.body, ({validate, className, value, ...field}, index) => {
			field.disabled = !order[type] || field.disabled;
			return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={className} />
		});
	}

	renderOrder() {
		const { isOrder, order_id, order, state, transaction, history } = this.state;

		return (
			<div className="input-container form-row">
				{ !transaction && <div className="col-12 col-sm-3">
					<Button color="primary" style={{width: '100%'}} onClick={()=>{this.setState({ isOrder: true })}} disabled={isOrder}>Lensa RX/Gosok</Button>
				</div>}
				{ !transaction && <div className="col-12 col-sm-3">
					<Button color="danger" style={{width: '100%'}} onClick={this.onOrderCancel.bind(this)} hidden={!isOrder}>Batalkan</Button>
				</div>}
				{ (isOrder && !transaction) && <Form form="orderForm" onSubmit={()=>{}} initialValues={{sell_locale: orderField.header.sell_locale.value}} keepDirtyOnReinitialize keep state={state}>
					<div className="col-12" style={{ paddingTop: '5px' }}>
						<div className="form-row">
							{ this.renderOrderHeaderField() }
						</div>
					</div>
				</Form>}
				{ (order_id && !transaction) && <Form form="orderRightForm" onSubmit={this.onOrderSubmit.bind(this, 'KANAN')} keep state={state} initialValues={_.assign({}, _.mapValues(orderField.body, 'values'), history.kanan)}>
					<div className="col-12" style={{ paddingTop: '5px' }}>
						<div className="form-row">
							<div className="col-12 col-sm-2" style={{ paddingTop: '8px' }}>
								<Button type="reset" color="primary" style={{width: '100%'}} onClick={this.onOrderBodyReset.bind(this, 'orderRightForm', 'KANAN')}>Kanan</Button>
							</div>
							{ this.renderOrderBodyField('KANAN') }
							<div className="col-12 col-sm-2" style={{ paddingTop: '8px' }}>
								<Button type="submit" color="primary" style={{width: '100%'}} disabled={!order.KANAN}>Tetapkan</Button>
							</div>
						</div>
					</div>
				</Form>}
				{ (order_id && !transaction) && <Form form="orderLeftForm" onSubmit={this.onOrderSubmit.bind(this, 'KIRI')} keep state={state} initialValues={_.assign({}, _.mapValues(orderField.body, 'values'), history.kiri)}>
					<div className="col-12" style={{ paddingTop: '5px' }}>
						<div className="form-row">
							<div className="col-12 col-sm-2" style={{ paddingTop: '8px' }}>
								<Button type="reset" color="primary" style={{width: '100%'}} onClick={this.onOrderBodyReset.bind(this, 'orderLeftForm', 'KIRI')}>Kiri</Button>
							</div>
							{ this.renderOrderBodyField('KIRI') }
							<div className="col-12 col-sm-2" style={{ paddingTop: '8px' }}>
								<Button type="submit" color="primary" style={{width: '100%'}} disabled={!order.KIRI}>Tetapkan</Button>
							</div>
						</div>
					</div>
				</Form>}
				<div className="col-12">
					{ order.data && <Table data={order.data} keys={orderField.keys} /> }
				</div>
			</div>
		);
	}

	renderPayment() {
		const { payment } = this.state;

		return _.map(paymentField, ({validate, className, ...field}, index) => {
			field.disabled = field.type === 'select'?_.isEmpty(field.option) || field.disabled:field.disabled;
			field.disabled = !_.isEmpty(payment) || field.disabled;
			return <Field key={index} name={index} component={FieldItem} field={field} validate={field.disabled?null:validate} className={className} />
		});
	}

	renderPaymentDetail() {
		const { payment, detailField } = this.state;

		const renderDetail = ({ fields, meta: { error, submitFailed } }) => (
			<div className="col-12">
				<div className="form-row">
					<div className="col-12 col-sm-3">
						<Button type="reset" color="primary" style={{width: '100%'}} onClick={this.onAddPaymentClick.bind(this, fields)} disabled={!_.isEmpty(payment)}>Tambah Pembayaran</Button>
					</div>
					{fields.map((value, index) => (
						<div key={index} className="col-12">
							<div className="form-row" style={{paddingTop: '8px'}}>
								{_.map(detailField[index], ({validate, className, ...field}, i) => {
									field.disabled = field.type === 'select'?_.isEmpty(field.option) || field.disabled:field.disabled;
									field.disabled = !_.isEmpty(payment) || field.disabled;
									return <Field key={i} name={`${value}.${i}`} component={FieldItem} field={field} validate={field.disabled?null:validate} className={className} onChange={field.type === 'select'?this.updatePaymentSelect.bind(this, i, index):undefined} />
								})}
								<div className="col-12 col-sm-3" style={{paddingTop: '8px'}}>
									<Button type="reset" color="danger" style={{width: '100%'}} onClick={this.onRemovePaymentClick.bind(this, fields, index)} disabled={!_.isEmpty(payment)}>Hapus Pembayaran</Button>
								</div>
							</div>
						</div>
					))}
					<div className="col-12 text-danger">
						{submitFailed && error && <span>{error}</span>}
					</div>
				</div>
			</div>
		);

		return (
			<FieldArray name="detail" component={renderDetail}
				validate={value => {
					if(value) {
						const { footer } = this.state;
						const total = _.map(value, v => v?_.parseInt(v.total || 0):0);
						const sum = _.sum(total);
						const paid = _.isFinite(footer.sum)?footer.sum:footer.total;
						// if(!_.isEqual(total, this.state.total)) this.setState({ total });
						return sum > paid?'Total harga melebihi harga yang harus dibayar':undefined;
					}
					return 'Cara pembayaran harus diisi';
				}} />
		);
	}

	renderFooter() {
		const { transaction, saving, footer, value, type, payment, detailField, state, error, tError } = this.state;

		return (
			<div className="input-container row">
				<div className="col-12 col-sm-6">
					<div className="form-row justify-content-end">
						<div className="col-12">
							<Input {...footerField.total} floatingLabel={true} readOnly={true} value={(footer.total).toLocaleString('id')} onChange={()=>{}} />
						</div>
						<div className="col-12" hidden={transaction}>
							<div className="form-row">
								<div className="col-12 col-sm-6">
									<Input {...footerField.value} floatingLabel={true} onChange={this.onValueChange.bind(this)} value={value?value:''} disabled={!_.isEmpty(footer.value)} />
								</div>
								<div className="col-12 col-sm-6">
									<div className="mui-textfield">
										<Select {...footerField.type} onChange={this.onValueClick.bind(this)} value={type} disabled={!_.isEmpty(footer.value)} />
										<label>{footerField.type.label}</label>
									</div>
								</div>
								<div className="col-12 text-danger">{error}</div>
							</div>
						</div>
						<div className="col-12 col-sm-6" style={{paddingTop: '8px'}} hidden={transaction}>
							<Button color="danger" style={{width: '100%'}} onClick={this.onValueReset.bind(this)} disabled={!footer.value}>Batalkan</Button>
						</div>
						<div className="col-12">
							<Input {...footerField.sum} floatingLabel={true} readOnly={true} value={(_.isFinite(footer.sum)?footer.sum:footer.total).toLocaleString('id')} onChange={()=>{}} />
						</div>
					</div>
				</div>
				<div className="col-12">
					<div className="row">
						<Form form="paymentForm" onSubmit={this.onPaymentSubmit.bind(this)} initialValues={this.updatePaymentValues()} keepDirtyOnReinitialize keep state={state}>
							{ this.renderPayment() }
							{ this.renderPaymentDetail() }
							<div className="col-12" hidden={transaction}>
								<div className="form-row">
									<div className="col-12 col-sm-3">
										<Button type="reset" color="danger" style={{width: '100%'}} onClick={this.onPaymentReset.bind(this)} disabled={!payment}>Ganti Pembayaran</Button>
									</div>
									<div className="col-12 col-sm-3">
										<Button type="submit" color="primary" style={{width: '100%'}} disabled={payment || _.isEmpty(detailField)}>Tetapkan Pembayaran</Button>
									</div>
								</div>
							</div>
						</Form>
					</div>
				</div>
				<div className="col-12">
					<div className="row">
						<div className="col-12">
							<Input name="note" label="Internal Note" onBlur={({target:{ value }}) => this.setState({ transactionNote: { ...this.state.transactionNote, note: value} })} />
						</div>
						<div className="col-12">
							<Input name="ext_note" label="External Note" onBlur={({target:{ value }}) => this.setState({ transactionNote: { ...this.state.transactionNote, ext_note: value} })} />
						</div>
					</div>
				</div>
				<div className="col-12" style={{paddingTop: '15px'}}>
					<div className="form-row">
						<div className="col-12 col-sm-3">
							<Button color="primary" style={{width: '100%'}} onClick={()=>{this.setState({ pending: this.state.pending + 1 })}} disabled={saving || transaction}>Simpan Data Resep</Button>
						</div>
					</div>
				</div>
				<div className="col-12" style={{paddingTop: '15px'}}>
					<div className="form-row">
						<div className="col-12 text-danger">
							* Harap proses data sebelum meninggalkan halaman ini
						</div>
						<div className="col-12 col-sm-3">
							<Button color="primary" style={{width: '100%'}} onClick={()=>{this.setState({ modal: this.state.modal + 1 })}} disabled={!payment || saving || transaction}>{(transaction)?"Transaksi Berhasil":"Proses Transaksi"}</Button>
						</div>
						<div className="col-12 col-sm-3 d-none d-sm-block">
							<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this, true)} disabled={!transaction}>Cetak Invoice</Button>
						</div>
						<div className="col-12 col-sm-3 d-none d-sm-block">
							<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this, false)} disabled={!transaction}>Cetak Invoice Tanpa Harga</Button>
						</div>
						<div className="col-12 col-sm-3 d-none d-sm-block">
							<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this, true, false)} disabled={!transaction}>Cetak Invoice Tanpa Resep</Button>
						</div>
						<div className="col-12 col-sm-3 d-none d-sm-block">
							<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this, false, false)} disabled={!transaction}>Cetak Tanpa Harga & Resep</Button>
						</div>
						<div className="col-12 col-sm-3">
							<Button color="primary" style={{width: '100%'}} onClick={this.onResetClick.bind(this)} disabled={!transaction}>Transaksi Selesai</Button>
						</div>
						<div className="col-12 text-danger">
							{tError}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderInvoice() {
		const { session } = this.props;
		const { header, transaction, data, order, payment, footer, recipe, note, showPrice, showRecipe, logo, option: { payment_id } } = this.state;
		const customer = header.name ? header : this.state.customer;
		let debit = {};
		if(payment_id) {
			debit = {
				name: _.join(_.map(payment.detail, (v) => payment_id[v.payment_id].label), '/')
			}
		}

		return <Invoice header={{ ...transaction, debit }} store={session.store} customer={customer} data={data} order={order.data} payment={payment} footer={footer} recipe={recipe} note={note} showPrice={showPrice} showRecipe={showRecipe} logo={logo} transaction={transaction} />;
	}

	render() {
		const
			{ name, slug, remove, handleSubmit } = this.props,
			{ header, data, history, except, transaction, modal, pending } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{ this.renderHeader() }
				</form>
				<Divider style={{marginTop: '10px', marginBottom: '5px'}}/>
				{ header && !_.isEmpty(_.reject(history, _.isEmpty)) &&
					<div className="d-none d-sm-block row">
						<div className="col-sm-8 m-auto">
							<Table data={_.reject(history, _.isEmpty)} keys={recipeKeys} />
						</div>
						<div className="col-12">
							<Divider style={{marginTop: '10px', marginBottom: '5px'}}/>
						</div>
					</div> }
				{ header && !transaction && this.renderField() }
				{ header && <Table data={data} slug={slug} field={field} keys={_.omit(keys, except)} name="label" remove={remove} onRemoveSubmit={this.onRemoveSubmit.bind(this)}/> }
				{ header && !_.isEmpty(orderField.header.brand_id.option) && this.renderOrder() }
				{ header && <Divider style={{margin: '5px 0'}}/>}
				{ header && this.renderFooter()}
				<Modal title="Simpan Data Resep" modal={pending} onButtonClick={this.onPendingClick.bind(this)}>
					<h2 className="text-center">Apakah anda yakin menunda transaksi ini?</h2>
					<div className="text-danger">* Hanya data resep yang akan tersimpan dan tertampil pada transaksi berikutnya</div>
				</Modal>
				<Modal title="Proses Transaksi" modal={modal} onButtonClick={this.onSaveClick.bind(this)}>
					<h2 className="text-center">Apakah anda yakin transaksi sudah benar?</h2>
				</Modal>
				<Modal type="notice" title="Transaksi Sukses" modal={transaction?1:0}>
					<h2 className="text-center">Nomor Invoice <span className="text-danger">{transaction && transaction.code}</span></h2>
				</Modal>
				{ transaction && this.renderInvoice() }
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm',
})(DataTransaction);
