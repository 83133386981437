import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import moment from 'moment';
import _ from 'lodash';

import { getFromUrl, putFromUrl, mapField } from '../../actions';
import { Modal } from '../modal';
import { FieldItem, Table } from '../common';

import { A5 } from '../style';
import { initialize } from '../../fields';

class DataTableHeader extends Component {
	constructor(props) {
		super(props);
		const { slug, session, ...otherProps} = props;
		const { model, field, where } = initialize(slug, session, otherProps);

		this.state = { model, field, where, props: null, data: [], error: '', initialized: false, fetching: true };
	}

	componentDidMount() {
		const { field } = this.state;

		Promise.all([
			this.resetDate(),
			..._.map(field, (v, i) => !_.includes(i, 'keys') && mapField(v, this.updateField.bind(this, i)).then((data) => {
				this.setState({ field: { ...this.state.field, [i]: _.mapKeys(data, 'name')} });
			})),
			this.updateKeys()
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	updateField(index, column, data) {
		const { field } = this.state;
		const { change } = this.props;

		change(column, null);
		this.setState({ field: { ...field, [index]: {	...field[index], [column]: { ...field[index][column], option: data, value: null } } } });
	}

	updateKeys() {
		const { field } = this.state;

		const keys = {
			request: {
				title: field.keys.request,
				className: 'action',
				render: (data, type, { transaction_detail_id, disabled }) => {
					return data?"<strong>STATUS BERJALAN</strong>":`<button class="btn btn-info" data-toggle="click" data-action="request" data-id="${transaction_detail_id}" ${disabled?`disabled=""`:''}>PESAN</button>`;
				},
				onClick: this.onButtonClick.bind(this)
			},
		}

		this.setState({ field: { ...field, keys: { ...field.keys, ...keys } } });
	}

	onButtonClick(e, id) {
		this.setState({ data: { ...this.state.data, [id]: { ...this.state.data[id], disabled: true } }});
		return putFromUrl(`order-request/${id}`).then(({data:{ data }}) => {
			this.setState({ data: { ...this.state.data, [id]: { ...this.state.data[id], request: data.request, disabled: false } }});
		}).catch((err) => {
			err && err.response && err.response.data && this.setState({ error : JSON.stringify(err.response.data.message), data: { ...this.state.data, [id]: { ...this.state.data[id], disabled: false }} });
		});
	}

	onResetClick() {
		const //{ reset } = this.props,
			props = null, data = [];

		// reset();
		// this.resetDate();
		this.setState({ props, data });
	}

	resetDate() {
		const { change } = this.props;
		const { field:{ header }} = this.state;

		const now = moment().format('YYYY-MM-DD');

		_.each(header, (v, i) => {
			v.type === 'date' && change(i, now);
		});
	}

	onSubmit(props) {
		const { valid } = this.props;
		const { model, where } = this.state;

		if(valid) {
			const params = _.merge(_.cloneDeep(model), { props: _.map(_.omitBy(_.cloneDeep(props), _.isNull), where) });
			this.setState({ fetching: true });
			return getFromUrl(`get`, params).then(({data:{ data }}) => {
				this.setState({ props: params, data: _.mapKeys(data, 'transaction_detail_id'), fetching: false });
			});
		}
	}

	onPrintClick() {
		
		window.print();
	}

	renderHeader() {
		const { props, field:{ header }, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container">
				{_.map(_.cloneDeep(header), ({validate, className, ...field}, index) => {
					field.disabled = !_.isEmpty(props) || field.disabled;
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={`${className || "col-12 col-md"} ${props && props[index]?'':'d-print-none'}`} />
				})}
				<div className="d-print-none col-12 d-flex justify-content-end" style={{paddingTop: '8px'}}>
					{ !props && <Button type="submit" color="primary" style={{minWidth: '20%'}} disabled={submitting}>Cari</Button>}
					{ props && <Button type="reset" color="danger" style={{minWidth: '20%'}} onClick={this.onResetClick.bind(this)}>Reset</Button>}
					<Button type="reset" color="primary" style={{minWidth: '20%'}} onClick={this.onPrintClick.bind(this)} disabled={!props}>Print</Button>
				</div>
			</div>
	}

	render() {
		const 
			{ name, handleSubmit } = this.props,
			{ data, field:{ keys }, initialized, fetching, error } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && <Table data={data} keys={keys} fetching={fetching} /> }
				<Modal type="notice" title="Error Message" modal={error}>
					<h2 className="text-center mui--text-danger">{error}</h2>
				</Modal>
				<A5 />
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataTableHeader);
