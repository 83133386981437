import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import moment from 'moment';
import _ from 'lodash';

import Cms from './cms';
// import Analytics from './analytics';
import Login from './components/login';

const HOMEPAGE = "/";
const DASHBOARD = "/dashboard";
const PROFILE = "/profile";

class MyRoute extends Component {
	render() {
		const { session } = this.props;
		const isLogin = !_.isEmpty(session);
		// const expired = moment().startOf('day').diff(moment(session.last_login).startOf('day'), 'days');
		// const isLogin = (!session.last_login || expired !== 0) ? false : !_.isEmpty(session);

		return (
			<Router>
				<Switch>
					<Route exact path={HOMEPAGE} render={() => {
						return (isLogin)?<Redirect push to={session.usergroup_id === Number(process.env.REACT_APP_CUSTOMER) ? PROFILE : DASHBOARD} />:<Login />
					}} />
					<Route render={(props) => {
						return isLogin?<Cms {...props} session={session} />:<Redirect to={HOMEPAGE} />
					}} />
				</Switch>
			</Router>
		);
	}
}

export default connect((state) => {
	return {
		session: state.session
	};
})(MyRoute);
