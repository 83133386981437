import React, { Component } from 'react';
import _ from 'lodash';
import nl2br from 'react-nl2br';

import { getFromUrl } from '../actions/index';
import { Modal } from './modal';

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = { content: null, notification: null, fetching: true };
	}
	
	componentDidMount() {
		const date = new Date();
		const dayOfMonth = date.getDate();
		let code;
		if(process.env.NODE_ENV === "production") {
			const DOMAIN = process.env.REACT_APP_DOMAIN;
			code = _.replace(window.location.hostname, `.${DOMAIN}`, '');
			code = _.replace(code, DOMAIN, 'dev');
		}else {
			code = 'dev';
		}
		const params = {
			table: 'content',
			select: ['content.name', 'content.html'],
			where: {
				name: 'dashboard'
			},
		},
		clientParams = {
			table: 'client',
			where: { code }
		}

		Promise.all([
			getFromUrl('show', params).then(({data:{ data }}) => {
				this.setState({ content: data });
			}),
			getFromUrl('show', clientParams).then(({data:{ data }}) => {
				// GET Client ID
				const nParams = { table: 'notification_client', select: 'notification_id', where: { client_id: data.id } };
				let notification = [];
				getFromUrl('get', nParams).then(({data:{ data }}) => {
					// GET Notification ID
					Promise.all(_.map(data, ({ notification_id }) => {
						const notificationParams = { table: 'notification', where:{ id: notification_id, 0: ['start', '<=', dayOfMonth], 1: ['end', '>=', dayOfMonth] } };
						return getFromUrl('show', notificationParams).then(({data:{ data }}) => {
							data && notification.push(data);
						});
					})).then(() => {
						this.setState({ notification });
					});
				});
			})
		]).then(() => {
			this.setState({ fetching: false });
		});
	}

	render() {
		const { fetching, content, notification } = this.state;

		return (
			<div className="container-fluid">
				{ fetching?
					<div className="row">
						<div className="col-12 text-center">
							<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
							</div>
					</div>:
					<div className="row justify-content-end" style={{ marginTop: '-15px', backgroundColor: '#e1ecef' }} dangerouslySetInnerHTML={{ __html: content && content.html }}></div> }
				<Modal title="Notifikasi" bsSize="lg" modal={!_.isEmpty(notification)} type="notice">
					{ _.map(notification, ({ text }, i) => {
						return <h3 key={i} className="text-center text-primary">{nl2br(text)}</h3>;
					}) }
				</Modal>
			</div>
		);
	}
}

export default Dashboard;
