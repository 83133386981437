import React, { Component } from 'react';
import { reduxForm, Field, submit, change } from 'redux-form';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { getFromUrl, mapField, getLogo } from '../../actions';
import { Modal } from '../modal';
import { FieldItem, Table, Form, Invoice } from '../common';

import { initialize } from '../../fields';

class DataTableTransaction extends Component {
	constructor(props) {
		super(props);
		const { slug, session, ...otherProps} = props;
		const { model, field, where, callback, action } = initialize(slug, session, otherProps);

		this.state = { model, field, where, callback, action, header: null, data: null, recipe: null, result: null, notFound: 0, modal: 0, initialized: false, fetching: true, note: [], showPrice: true, showRecipe: true, logo: [], value: null };
	}

	componentDidMount() {
		const { field } = this.state;
		const { session:{ store_id }} = this.props;

		Promise.all([
			..._.map(field, (v, i) => !_.includes(i, 'keys') && mapField(v, this.updateField.bind(this, i)).then((data) => {
				this.setState({ field: { ...this.state.field, [i]: _.mapKeys(data, 'name')} });
			})),
			getLogo(store_id).then(({data:{ data }}) => {
				this.setState({ logo: data });
			}),
			this.getNote()
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	updateField(index, column, data) {
		const { field } = this.state;
		const { change } = this.props;
		
		change(column, null);
		this.setState({ field: { ...field, [index]: {	...field[index], [column]: { ...field[index][column], option: data, value: null } } } });
	}

	getNote() {
		return getFromUrl('invoice-note').then(({data:{ data }}) => {
			this.setState({ note: _.orderBy(data, 'pos') });
		});
	}

	updateHeader(data) {
		const { change } = this.props;

		change('customer_code', data.customer.code);
		change('customer_name', data.customer.name);

		change('sum', data.sum.toLocaleString('id'));
		change('paid', data.paid.toLocaleString('id'));
		change('unpaid', data.unpaid.toLocaleString('id'));
		change('transaction_date', new Date(data.created_at).toLocaleDateString('id'));
		change('payment_name', data.debit.name);
		change('admin_name', data.admin.name);
	}

	onResetClick() {
		const { reset } = this.props,
			header = null, data = null, recipe = null, result = null;

		reset();
		this.setState({ header, data, recipe, result });
	}

	onSubmit(props) {
		const { valid } = this.props;
		const { model:{ header }, callback } = this.state;

		if(valid) {
			const params = _.cloneDeep(header);
			this.setState({ fetching: true });
			return getFromUrl(`transaction/${props.code}`, params).then(({data:{ data }}) => {
				callback && _.isFunction(callback.header) && callback.header(data, this);
				this.updateHeader(data);
				const recipe = _.mapKeys(data.recipe, 'eye');
				this.setState({ header: props, data, recipe, fetching: false });
			}).catch((err) => {
				if( err.response && err.response.status === 404) {
					this.setState({ notFound: this.state.notFound + 1, fetching: false });
				}
			});
		}
	}

	onSaveClick(props) {
		const { model:{ footer, method }, header, error } = this.state;

		const params = _.cloneDeep(footer);
		return _.isEmpty(error) && _.isFunction(method) && method(`transaction/${header.code}`, { props, ...params }).then(({data:{ data }}) => {
			this.setState({ result: data });
		}).catch((err) => {
			
		});
	}

	onValueChange({target: { value }}) {
		this.props.dispatch(change('footerForm', 'type', ''));
		this.setState({ value });
	}

	onValueClick(type, a) {
		const { footer, value } = this.state;
		let error = "";

		if(value) {
			footer.sum = footer.total;

			switch(type.value) {
				case 'DEAL':
					// value > footer.total?
					// error = "Harga Deal tidak bisa lebih besar dari total harga":
					footer.sum = _.parseInt(value);
					break;
				case 'REBATE':
					value > footer.total?error = "Harga Potongan tidak bisa lebih besar dari total harga":
					footer.sum -= _.parseInt(value);
					break;
				case 'DISCOUNT':
					value > 100?
					error = "Diskon tidak bisa lebih dari 100%":
					footer.sum *= (100 - _.parseInt(value))/100;
					break
				default:
					break
			}
			if(_.isEmpty(error)) {
				footer.value = { [type.value]: _.parseInt(value) };
				this.props.dispatch(change('footerForm', 'sum', footer.sum.toLocaleString('id')));
				this.setState({ footer, error });
			}else {
				this.setState({ error });
			}
		}
	}

	triggerSubmit(name) {
		const { dispatch } = this.props;

		dispatch(submit(name));
	}

	onPrintClick(showPrice = true, showRecipe = true) {
		Promise.resolve(this.setState({ showPrice, showRecipe })).then(() => {
			window.print();
		});
	}

	renderHeader() {
		const { header, field, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container row">
				<div className="col-6">
					{_.map(_.pickBy(_.cloneDeep(field.header), ['group','left']), ({validate, group, ...field}, index) => {
						field.disabled = !_.isEmpty(header) || field.disabled;
						return index === 'code'?
							<div key={index} className="row">
								<div className="col-8">
									<Field name={index} component={FieldItem} field={field} validate={validate} />
								</div>
								<div className="col-4" style={{paddingTop: '8px'}}>
								{!header && <Button type="submit" color="primary" style={{width: '100%'}} disabled={submitting}>Cari</Button>}
								{header && <Button type="reset" color="danger" onClick={this.onResetClick.bind(this)} style={{width: '100%'}}>Reset</Button>}
								</div>
							</div>:
							<Field key={index} name={index} component={FieldItem} field={field} validate={validate} />
					})}
				</div>
				<div className="col-3">
					{_.map(_.pickBy(field.header, ['group','middle']), ({validate, group, ...field}, index) => {
						field.disabled = !_.isEmpty(header) || field.disabled;
						return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} />
					})}
				</div>
				<div className="col-3">
					{_.map(_.pickBy(field.header, ['group','right']), ({validate, group, ...field}, index) => {
						field.disabled = !_.isEmpty(header) || field.disabled;
						return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} />
					})}
				</div>
			</div>
	}

	renderFooter() {
		const { name } = this.props;
		const { result, field, error } = this.state;

		return (
			<div className="input-container row">
				<div className="col-8">
					<Form form="footerForm" onSubmit={this.onSaveClick.bind(this)} keep>
						<div className="row" style={{width: '100%'}}>
							{_.map(_.omitBy(_.cloneDeep(field.footer), v => v.type === 'hidden'), ({validate, className, ...field}, index) => {
								if(index === 'value') {
									field.onChange = this.onValueChange.bind(this);
								}else if(index === 'type') {
									field.onChange = this.onValueClick.bind(this);
								}
								field.disabled = field.type === 'select'?_.isEmpty(field.option) || field.disabled:field.disabled;
								field.disabled = !_.isNull(result) || field.disabled;
								className = className?className:'col-6';
								return <Field key={index} name={index} component={FieldItem} field={field} validate={field.disabled?null:validate} className={className} />
							})}
							<div className="col-12 text-danger">{error}</div>
						</div>
					</Form>
				</div>
				<div className="col-4">
					<div className="col-12" style={{paddingTop: '8px'}}>
						<Button color="primary" style={{width: '100%'}} onClick={() => _.isEmpty(error) && this.setState({ modal: this.state.modal + 1 })} disabled={!_.isNull(result)}>{(result)?`${_.head(_.split(name, ' '))} Berhasil`:`Proses ${_.head(_.split(name, ' '))}`}</Button>
					</div>
					<div className="col-12" style={{paddingTop: '8px'}}>
						<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this, true)} disabled={!result}>Cetak Invoice</Button>
					</div>
					<div className="col-12" style={{paddingTop: '8px'}}>
						<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this, false)} disabled={!result}>Cetak Invoice Tanpa Harga</Button>
					</div>
					<div className="col-12" style={{paddingTop: '8px'}}>
						<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this, true, false)} disabled={!result}>Cetak Invoice Tanpa Resep</Button>
					</div>
					<div className="col-12" style={{paddingTop: '8px'}}>
						<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this, false, false)} disabled={!result}>Cetak Tanpa Harga & Resep</Button>
					</div>
					<div className="col-12" style={{paddingTop: '8px'}}>
						<Button color="primary" style={{width: '100%'}} onClick={this.onResetClick.bind(this)} disabled={!result}>Transaksi Selesai</Button>
					</div>
				</div>
			</div>
		);
	}

	renderInvoice() {
		const { session:{ store }} = this.props;
		const { header, data, recipe, result, note, showPrice, showRecipe, logo } = this.state;
		const { sum, total } = result;
		const payment = {
			total: (result.sum === data.sum && result.sum === result.paid)?data.unpaid:result.paid,
			paid: (result.sum === data.sum && result.sum === result.paid)?data.paid:result.paid,
			unpaid: _.max([result.sum - result.paid, 0]).toLocaleString('id')
		};

		return <Invoice header={data} store={store} customer={data.customer} data={data.detail} payment={payment} footer={{sum, total}} recipe={recipe} note={note} showPrice={showPrice} showRecipe={showRecipe} logo={logo} />;
	}

	render() {
		const 
			{ name, handleSubmit } = this.props,
			{ data, recipe, field:{ keys, keysRecipe }, result, notFound, modal, initialized, fetching } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && <Table data={data && data.detail} keys={keys} fetching={fetching} /> }
				{ initialized && !_.isEmpty(recipe) && <Table data={recipe} keys={keysRecipe} fetching={fetching} /> }
				{ !_.isEmpty(data) && this.renderFooter()}
				<Modal title="Tidak Ditemukan" type="notice" modal={notFound} onButtonClick={()=>{}}>
					<h2 className="text-center">Kode Invoice Tidak Ditemukan</h2>
				</Modal>
				<Modal title="Pelunasan Pembayaran" modal={modal} onButtonClick={this.triggerSubmit.bind(this, 'footerForm')}>
					<h2 className="text-center">Apakah anda yakin data sudah benar?</h2>
				</Modal>
				{ result && this.renderInvoice()}
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataTableTransaction);
