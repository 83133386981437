import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import _ from 'lodash';

import { getFromUrl, postFromUrl, putFromUrl, deleteFromUrl, mapField } from '../../actions';
import { Table } from '../common';
import { ModalAdd } from '../modal';

import { subtableFields } from '../../fields';

class DataSubtable extends Component {
	constructor(props) {
		super(props);

		const { slug } = this.props;
		const tableName = _.split(slug, '/')[2];
		const field = _.cloneDeep(subtableFields[tableName]);
		const keys = _.mapKeys(_.mapValues(_.omitBy(field, (value, index) =>	value.hidden || index === 'description'), 'label'), (value, index) => _.replace(index, '_id', '_name'));

		this.state = { data: {}, parent: {}, field, keys, fetching: true };
	}

	componentDidMount() {
		const { slug, match: { params } } = this.props;
		const { field } = this.state;

		Promise.all([
			this.initData(_.replace(slug, ':id', params.id)),
			this.initParent(_.split(slug, '/')[0], params.id),
			mapField(field).then((data) => {
				this.setState({ field: _.mapKeys(data, 'name') });
			})
		]).then(() => {
			this.setState({ fetching: false });
		});
	}

	initData(slug) {
		return getFromUrl(slug).then(({data:{ data }}) => {
			this.setState({ data: _.mapKeys(data, 'id') });
		});
	}

	initParent(slug, id) {
		return getFromUrl(`${slug}/${id}`).then(({data:{ data }}) => {
			this.setState({ parent: data });
		});
	}

	onAddSubmit(props) {
		const { slug, match: { params } } = this.props;
		return postFromUrl(_.replace(slug, ':id', params.id), props).then(({data:{ data }}) => {
			this.setState({ data: { ...this.state.data, [data.id]: data } });
		});
	}

	onEditSubmit(props, id) {
		const { slug, match: { params } } = this.props;
		return putFromUrl(`${_.replace(slug, ':id', params.id)}/${id}`, props).then(({data:{ data }}) => {
			this.setState({ data: { ...this.state.data, [id]: data } });
		});
	}

	onRemoveSubmit(id) {
		const { slug, match: { params } } = this.props;
		const props = { table: _.replace(slug, ':id', params.id), id };
		return deleteFromUrl('delete', props).then(({data:{ data }}) => {
			this.setState({ data: _.omit(this.state.data, [id]) });
		});
	}

	closeWindow(e) {
		e.preventDefault();
		window.close();
	}

	render() {
		const { name, slug, add, edit, remove, match: { params } } = this.props;
		// const menu = _.split(slug, '/')[0];
		const { data, parent, field, keys, fetching } = this.state;

		return (
			<div className="container-fluid">
				{/*/<h3 className="text-center">Data {name}: <Link to={`/${menu}`} className="mui--text-danger">{parent.name}</Link></h3>/*/}
				<h3 className="text-center">Data {name}: <a href="#close" className="mui--text-danger" onClick={this.closeWindow}>{parent.name}</a></h3>
				{ (add === 1) && <ModalAdd field={field} fetching={fetching} onAddSubmit={this.onAddSubmit.bind(this)} close={true}/> }
				<Table data={data} slug={_.replace(slug, ':id', params.id)} field={field} keys={keys} edit={edit} remove={remove} fetching={fetching} onEditSubmit={this.onEditSubmit.bind(this)} onRemoveSubmit={this.onRemoveSubmit.bind(this)} advanced />
			</div>
		);
	}
}

export default DataSubtable;
