import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import _ from 'lodash';

import { getFromUrl, putFromUrl } from '../../actions';
import { ModalAdd } from '../modal';
import { Table } from '../common';

import { subdebitField, maxValue } from '../../fields';

class DataSubdebit extends Component {
	constructor(props) {
		super(props);

		const headerField = _.cloneDeep(subdebitField.header);
		const keys = _.cloneDeep(subdebitField.data);
		const add = _.cloneDeep(subdebitField.add);
		const validate = _.cloneDeep(add.paid.validate);

		this.state = { headerField, keys, add, validate, debit: null, data: null, total: 0, initialized: false };
	}

	componentDidMount() {
		const { match: { params } } = this.props;
		const { add, validate } = this.state;

		Promise.all([
			getFromUrl(`debit/${params.id}`).then(({data:{ data }}) => {
				const total = _.replace(data.total, /\./g, '');
				add.paid.validate = [ ...validate, maxValue(total - data.paid) ];
				this.setState({ debit: data, total, add });
			}),
			getFromUrl(`debit/${params.id}/debit-detail`).then(({data:{ data }}) => {
				this.setState({ data });
			})
		]).then(() => {
			this.setState({ initialized: true });
		});

	}

	onAddSubmit(props) {
		const { match: { params } } = this.props;
		const { add, validate } = this.state;

		return putFromUrl(`debit/${params.id}`, props).then(({data:{ data }}) => {
			const total = _.replace(data.total, /\./g, '');
			add.paid.validate = [ ...validate, maxValue(total - data.paid) ];
			this.setState({ debit: data, total, add });
		});
	}

	renderDebit() {
		const { debit, headerField } = this.state;

		return _.map(_.cloneDeep(headerField), (value, index) => {
			return (
				<div key={index} style={{display: 'flex', flexBasis: '25%', margin: '5px 0'}}>
					<div style={{flexBasis: '30%'}}>{value.label}</div>
					<div style={{flexBasis: '5%'}}>:</div>
					<div style={{flexBasis: '65%', color: 'red', fontWeight: '600'}}>{debit[index]}</div>
				</div>
			);
		});
	}

	closeWindow(e) {
		e.preventDefault();
		window.close();
	}

	render() {
		const
			{ name } = this.props,
			{ keys, add, debit, data, total, initialized } = this.state;

		return (
			<div className="container-fluid">
				{/*/<h3 className="text-center">Data {name}: <Link to={`/debit`} className="mui--text-danger">Hutang Supplier</Link></h3>/*/}
				<h3 className="text-center">Data {name}: <a href="#close" className="mui--text-danger" onClick={this.closeWindow}>Hutang Supplier</a></h3>
				{ !initialized && <div className="row">
					<div className="col-12 text-center">
						<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
						</div>
				</div>}
				{ initialized && <div className="input-container" style={{margin: '15px 0'}}>
					{ this.renderDebit() }
				</div>}
				{ initialized && debit && debit.paid < total &&
					<ModalAdd field={add} onAddSubmit={this.onAddSubmit.bind(this)} close={true} bsSize="small" button="Bayar" title="Bayar Hutang">
						<div>Bayar <span style={{color: 'red'}}>{debit.code}</span>?</div>
					</ModalAdd> }
				{ data && <Table data={data} keys={keys} /> }
			</div>
		);
	}
}

export default DataSubdebit;
