import React, { Component } from 'react';
import ReactFileReader from 'react-file-reader';
import { Progress } from 'reactstrap';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { Modal } from '../modal';
import { getFromUrl, postFromUrl } from '../../actions';

class DataUploadLogo extends Component {
	constructor(props) {
		super(props);
		const model = {
			table: 'store',
			select: ['image as image_path', 'store.name'],
			column: 'image',
			path: 'logo',
			where: {
				'id': props.session.store.id
			}
		}

		this.state = { model, header: null, file: [], data: [], modal: 0, error: '', initialized: false, fetching: true, uploading: false, progress: null };
	}

	componentDidMount() {
		const { model } = this.state;

		Promise.all([
			getFromUrl('show', model).then(({data: { data }}) => {
				this.setState({ data });
			})
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	handleFiles([file]) {
		const { model } = this.state;

		if(file) {
			const formData = new FormData();
			formData.append('image', file);
			_.each(model, (v, i) => {
				if(_.isObject(v)) {
					_.each(v, (vV, vI) => {
						formData.append(`${i}[${vI}]`, vV);
					});
				}else {
					formData.append(i, v);
				}
			});

			this.setState({ uploading: true });
			return postFromUrl('upload-image', formData, {
				onUploadProgress: (progress) => {
					if (progress.lengthComputable) {
						 this.setState({ progress: _.parseInt(progress.loaded / progress.total * 100) });
					}
 				}
			}).then(({data: { data }}) => {
				this.setState({ data: { ...this.state.data, image_path: data }, file, uploading: false, progress: null });
			}).catch((err) => {
				err && err.response && err.response.data && this.setState({ error : JSON.stringify(err.response.data.message), modal: this.state.modal + 1, uploading: false, progress: null });
			});
		}else {
			this.setState({ file: [], data: [] });
		}
	}

	renderHeader() {
		const { initialized, data, uploading, progress } = this.state;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container justify-content-center">
				<div className="col-6">
					<ReactFileReader fileTypes="image/*" handleFiles={uploading?()=>{}:this.handleFiles.bind(this)}>
						<div className="row align-items-center" style={{paddingTop: '8px'}}>
							<div className="col-12 d-flex justify-content-center" style={{ cursor: 'pointer' }}>
								{ data.image_path?
									<img src={data.image_path} height="100%" className="col-8" alt={data.name} />:
									<div className="col-8 d-flex justify-content-center align-items-center" style={{ height: '100px', border: '1px solid #ccc' }}>Belum ada logo</div> }
							</div>
							<Button type="reset" color="secondary" className="col-12" style={{ marginTop: '8px'}}>Select Image File</Button>
						</div>
					</ReactFileReader>
				</div>
				{ uploading && progress && <div className="col-12">
					<div className="text-center">Uploading {progress}%</div>
					<Progress animated color="info" value={progress} />
				</div> }
			</div>
	}

	render() {
		const { name } = this.props;
		const { error, modal, initialized } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				{ this.renderHeader() }
				{ initialized && <div style={{flexBasis: '100%', color: 'red', paddingTop: '15px'}}>
					* Harap tunggu proses data selesai, proses data tergantung besar image file
				</div> }
				<Modal title="Error" type="notice" modal={modal} onButtonClick={()=>{}}>
					<h2 className="text-center">{error}</h2>
				</Modal>
			</div>
		);
	}
}

export default DataUploadLogo;
