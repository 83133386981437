import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import ReactFileReader from 'react-file-reader';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { FieldItem } from '../common';
import { Modal } from '../modal';
import { postFromUrl, mapField } from '../../actions';
import { required } from '../../fields';

class DataOrderImport extends Component {
	constructor(props) {
		super(props);
		const field = {
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: 'brand_id',
				validate: required,
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				validate: required,
			},
		}

		this.state = { field, header: null, file: [], data: [], modal: 0, modalError: 0, modalSuccess: 0, error: '', initialized: false, fetching: true };
	}

	componentDidMount() {
		const { field } = this.state;

		Promise.all([
			mapField(field, this.updateField.bind(this)).then((data) => {
				this.setState({ field: _.mapKeys(data, 'name') });
			}),
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	updateField(column, data) {
		const { field } = this.state;
		const { change } = this.props;

		change(column, null);
		this.setState({ field: { ...field, [column]: { ...field[column], option: data, value: null }} });
	}

	onSaveClick() {
		const { data, header } = this.state;

		this.setState({ fetching: true });
		return Promise.all([
			postFromUrl('order-multiple', { data, header }).then(({data:{ data }}) => {
				this.setState({ message: data });
			})
		]).then(() => {
			this.setState({ fetching: false, modalSuccess: this.state.modalSuccess + 1 });
		});
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			return this.setState({ header: props, modal: this.state.modal + 1 });
		}
	}

	handleFiles([file]) {
		if(file) {
			const formData = new FormData();
			formData.append('file', file);
			return postFromUrl('upload', formData).then(({data: { data }}) => {
				this.setState({ data, file });
			}).catch((err) => {
				err && err.response && err.response.data && this.setState({ error : JSON.stringify(err.response.data.message), modalError: this.state.modalError + 1 });
			});
		}else {
			this.setState({ file: [], data: [] });
		}
	}

	renderHeader() {
		const { field, file, initialized, fetching } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container">
				<div className="col-6">
					<ReactFileReader fileTypes=".csv" handleFiles={this.handleFiles.bind(this)}>
						<div className="row align-items-center" style={{paddingTop: '8px'}}>
							<Button type="reset" color="secondary" className="col-6">Select File (.CSV)</Button>
							<div className="col-6">{file.name || 'No file selected'}</div>
						</div>
					</ReactFileReader>
				</div>
				{_.map(_.cloneDeep(field), ({validate, ...field}, index) => {
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className="col-3" />
				})}
				<div className="col-12 text-center" style={{paddingTop: '8px'}}>
					<Button color="primary" disabled={submitting || _.isEmpty(file.name) || fetching}>Submit Data</Button>
				</div>
			</div>
	}

	render() {
		const { name, handleSubmit } = this.props;
		const { error, modal, modalError, modalSuccess, initialized, fetching } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{ this.renderHeader() }
				</form>
				{ initialized && fetching &&
					<div className="row" style={{paddingTop: '15px'}}>
						<div className="col-12 text-center">
							<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; UPLOADING DATA ...
							</div>
					</div>}
				{ initialized && <div style={{flexBasis: '100%', color: 'red', paddingTop: '15px'}}>
					* Harap tunggu proses data selesai, proses data tergantung besar file
				</div> }
				<Modal title="Error" type="notice" modal={modalError} onButtonClick={()=>{}}>
					<h2 className="text-center">{error}</h2>
				</Modal>
				<Modal title="Success" type="notice" modal={modalSuccess} onButtonClick={()=>{ this.props.reset() }}>
					<h2 className="text-center">Import data berhasil!</h2>
				</Modal>
				<Modal title="Submit Data" modal={modal} onButtonClick={this.onSaveClick.bind(this)}>
					<h2 className="text-center">Apakah anda yakin data sudah benar?</h2>
				</Modal>
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataOrderImport);
