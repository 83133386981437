import React, { Component } from 'react';
import { Divider } from 'muicss/react';
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';

export class MenuItem extends Component {
  renderSubmenu() {
    const { submenu } = this.props;
    const { pathname } = window.location;

    return _.map(submenu, (value, index) => {
      return (
        <NavItem key={index}>
          <NavLink tag={Link} to={`/${_.trim(value.slug, '/')}`} className={pathname === `/${value.slug}`?'active':''}><i className={`fa ${value.icon?value.icon:'fa-circle-o'}`}></i><span>{value.name}</span></NavLink>
        </NavItem>
      );
    });
  }

  render() {
    return (
      <Nav vertical>
        {this.renderSubmenu()}
      </Nav>
    );
  }
}

export class Menu extends Component {
	constructor(props) {
	  super(props);

	  this.state = { active: null, subactive: null };
	}

	onMouseEnter(e) {
		if(document.body.classList.contains('sidebar-collapse')) {
			e.currentTarget.classList.add('active');
		}
	}

	onMouseLeave(e) {
		if(document.body.classList.contains('sidebar-collapse')) {
			e.currentTarget.classList.remove('active');
		}
	}

	onMenuClick(id, e) {
		const { active } = this.state;

		if(!document.body.classList.contains('sidebar-collapse')) {
			this.setState({
				active: active === id? null: id
			});
		}
		e.preventDefault();
	}

  onSubmenuClick(id, e) {
    const { subactive } = this.state;

    if(subactive === id) {
      e.preventDefault();
    } else {
      this.setState({
        subactive: id
      });
    }
  }

	renderMenu() {
		const { menu, submenu } = this.props;
		const { active } = this.state;

		return _.map(menu, (value, index) => {
			if(!_.isEmpty(submenu[value.id])){
				return (
					<NavItem key={index} onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)}>
			    	<NavLink tag={Link} to={`#${_.kebabCase(value.name)}`} onClick={this.onMenuClick.bind(this, value.id)} className={value.id === active?'active':''}><i className={`fa ${value.icon}`}></i><span>{value.name}</span></NavLink>
			    	<Collapse isOpen={value.id === active}>
			    		<MenuItem submenu={submenu[value.id]}/>
			    	</Collapse>
          </NavItem>
				);
			}
		});
	}

  render() {
		const { session } = this.props;
    return (
    	<Nav className="sidebar-menu">
    		<li className="header">MAIN NAVIGATION</li>
  			<Divider />
  			<NavItem onMouseEnter={this.onMouseEnter.bind(this)} onMouseLeave={this.onMouseLeave.bind(this)}>
		    	{ session.usergroup_id === Number(process.env.REACT_APP_CUSTOMER) ? <NavLink tag={Link} to="/profile" className={window.location.pathname === '/profile'?'active':''}><i className="fa fa-dashboard"></i><span>Profil</span></NavLink> : <NavLink tag={Link} to="/dashboard" className={window.location.pathname === '/dashboard'?'active':''}><i className="fa fa-dashboard"></i><span>Dashboard</span></NavLink> }
        </NavItem>
      	{this.renderMenu()}
      </Nav>
    );
  }
}

export class SideMenu extends Component {
  render() {
  	const { menu, submenu, session } = this.props;

    return (
      <aside id="sidebar" className="main-sidebar">
        <div className="user-panel">
          <div className="image">
            <i className="fa fa-user"></i>
          </div>
          <div className="info">
            <div className="name">{session.name}</div>
            <div className="store">
              <i className="fa fa-circle text-success"></i> {session.store.name}
            </div>
          </div>
        </div>
		    <section className="sidebar">
	    		<Menu menu={menu} submenu={submenu} session={session} />
		    </section>
		  </aside>
    );
  }
}
