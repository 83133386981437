import React from 'react';
import useScript from './hooks/useScript';
import { CmsStyle } from './components/style';

const Arc = () => {
  const url = process.env.NODE_ENV === "production" ? "https://arc.io/widget.js#4xQBfeTT" : "";
  useScript(url);
  
  return <CmsStyle />
};

export default Arc;