import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { getFromUrl } from '../../actions';
import { Table } from '../common';
import { A5 } from '../style';

import { substockField } from '../../fields';

class DataSubstock extends Component {
	constructor(props) {
		super(props);

		const headerField = _.cloneDeep(substockField.header);
		const keys = _.cloneDeep(substockField.data);

		this.state = { headerField, keys, product: null, data: null, initialized: false };
	}

	componentDidMount() {
		const { match: { params } } = this.props;

		Promise.all([
			getFromUrl(`product/${params.product_id}`, { store_id: params.id }).then(({data:{ data }}) => {
				this.setState({ product: data });
			}),
			getFromUrl(`product/${params.product_id}/stock_history`, { store_id: params.id }).then(({data:{ data }}) => {
				this.setState({ data });
			})
		]).then(() => {
			this.setState({ initialized: true });
		});
	}

	renderProduct(product) {
		const { headerField } = this.state;

		return _.map(_.cloneDeep(headerField), (value, index) => {
			return (
				<div key={index} style={{display: 'flex', flexBasis: '25%', margin: '5px 0'}}>
					<div style={{flexBasis: '30%'}}>{value.label}</div>
					<div style={{flexBasis: '5%'}}>:</div>
					<div style={{flexBasis: '65%', color: '#F44336', fontWeight: '600'}}>{product[index]}</div>
				</div>
			);
		});
	}

	closeWindow(e) {
		e.preventDefault();
		window.close();
	}
	
	onPrintClick() {
		window.print();
	}

	render() {
		const
			{ name
				// , match: { params }
			} = this.props,
			{ keys, product, data, initialized } = this.state;

		return (
			<div className="container-fluid">
				{/*/<h3 className="text-center">Data {name}: <Link to={`/stock/${params.id}`} className="mui--text-danger">{product && product.store_name}</Link></h3>/*/}
				<h3 className="text-center">Data {name}: <a href="#close" className="mui--text-danger" onClick={this.closeWindow}>{product && product.store_name}</a></h3>
				{ !initialized && <div className="row">
					<div className="col-12 text-center">
						<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
						</div>
				</div>}
				{ initialized && <div className="input-container" style={{margin: '15px 0'}}>
					{ product && this.renderProduct(product) }
				</div>}
				<div className="row d-print-none">
					<div className="col col-sm-3 d-sm-block">
						<Button type="reset" color="primary" style={{ width:'100%' }} onClick={this.onPrintClick.bind(this)}>Print</Button>
					</div>
				</div>
				{ data && <Table data={data} keys={keys} /> }
				<A5 />
			</div>
		);
	}
}

export default DataSubstock;
