import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from 'lodash';

import { ScrollToTop, Header, SideMenu } from './components/common';
import Dashboard from './components/dashboard';
import Profile from './components/profile';
import DataProfile from './components/data/profile/data_profile';

import DataTable from './components/data/data_table';
import DataSubtable from './components/data/data_subtable';
import DataTableHeader from './components/data/data_table_header';
import DataTableTransaction from './components/data/data_table_transaction';
import DataInput from './components/data/data_input';
import DataInputSimple from './components/data/data_input_simple';
import DataInputSell from './components/data/data_input_sell';
import DataOpname from './components/data/data_opname';
import DataInputPrice from './components/data/data_input_price';
import DataSubstock from './components/data/data_substock';
import DataDebit from './components/data/data_debit';
import DataSubdebit from './components/data/data_subdebit';
import DataTransfer from './components/data/data_transfer';
import DataRetur from './components/data/data_retur';
import DataTransaction from './components/data/data_transaction';
import DataTransactionRepayment from './components/data/data_transaction_repayment';
import DataTransactionCancel from './components/data/data_transaction_cancel';
import DataTransactionHistory from './components/data/data_transaction_history';
import DataSubTransactionCancel from './components/data/data_sub_transaction_cancel';
import DataCustomerHistory from './components/data/data_customer_history';
import DataCustomerDebit from './components/data/data_customer_debit';
import DataPrint from './components/data/data_print';
import DataOrderBooking from './components/data/data_order_booking';
import DataOrderComplete from './components/data/data_order_complete';
import DataReprintInvoice from './components/data/data_reprint_invoice';
import DataReprintRecipe from './components/data/data_reprint_recipe';

import DataPropertyStock from './components/data/data_property_stock';
import DataPropertyActiva from './components/data/data_property_activa';
import DataActivity from './components/data/data_activity';

import DataMigration from './components/data/data_migration';
import DataOrderImport from './components/data/data_order_import';
import DataUploadLogo from './components/data/data_upload_logo';

import Arc from './arc';

// const HOMEPAGE = "/";
const DASHBOARD = "/dashboard";
const PROFILE = "/profile";
const MIGRATION = "/migration";

class Cms extends Component{
	onContentClick() {
		if(window.innerWidth < 768) {
			document.body.classList.remove('sidebar-open');
		}
	}

	renderRoute(submenu, session) {
		return _.map(submenu, (submenuPerMenuId, i) => {
			return _.map(submenuPerMenuId, (value, index) => {
				return (
					<Route exact key={index} path={`/${_.trim(value.slug, '/')}`} render={(routeProps) => {
						const props = {
							key: value.slug,
							name: value.name,
							slug: value.slug,
							add: value.add,
							edit: value.edit,
							remove: value.delete,
							session: session,
							submenu: value.submenu,
						}
						document.title = `${process.env.REACT_APP_TITLE} | ${props.name}`;
						switch(value.component){
							case 'DataProfile':
								return <DataProfile {...routeProps} {...props} />
							case 'DataTable':
								return <DataTable {...routeProps} {...props} />
							case 'DataSubtable':
								return <DataSubtable {...routeProps} {...props} />;
							case 'DataTableHeader':
								return <DataTableHeader {...routeProps} {...props} />;
							case 'DataTableTransaction':
								return <DataTableTransaction {...routeProps} {...props} />;
							case 'DataInput':
								return <DataInput {...routeProps} {...props} />;
							case 'DataInputSimple':
								return <DataInputSimple {...routeProps} {...props} />;
							case 'DataInputSell':
								return <DataInputSell {...routeProps} {...props} />;
							case 'DataOpname':
								return <DataOpname {...routeProps} {...props} />;
							case 'DataInputPrice':
								return <DataInputPrice {...routeProps} {...props} />;
							case 'DataSubstock':
								return <DataSubstock {...routeProps} {...props} />;
							case 'DataDebit':
								return <DataDebit {...routeProps} {...props} />;
							case 'DataSubdebit':
								return <DataSubdebit {...routeProps} {...props} />;
							case 'DataTransfer':
								return <DataTransfer {...routeProps} {...props} />;
							case 'DataRetur':
								return <DataRetur {...routeProps} {...props} />;
							case 'DataTransaction':
								return <DataTransaction {...routeProps} {...props} />;
							case 'DataTransactionRepayment':
								return <DataTransactionRepayment {...routeProps} {...props} />;
							case 'DataTransactionCancel':
								return <DataTransactionCancel {...routeProps} {...props} />;
							case 'DataTransactionHistory':
								return <DataTransactionHistory {...routeProps} {...props} />;
							case 'DataSubTransactionCancel':
								return <DataSubTransactionCancel {...routeProps} {...props} />;
							case 'DataCustomerHistory':
								return <DataCustomerHistory {...routeProps} {...props} />;
							case 'DataCustomerDebit':
								return <DataCustomerDebit {...routeProps} {...props} />;
							case 'DataPrint':
								return <DataPrint {...routeProps} {...props} />;
							case 'DataOrderBooking':
								return <DataOrderBooking {...routeProps} {...props} />;
							case 'DataOrderComplete':
								return <DataOrderComplete {...routeProps} {...props} />;
							case 'DataReprintInvoice':
								return <DataReprintInvoice {...routeProps} {...props} />;
							case 'DataReprintRecipe':
								return <DataReprintRecipe {...routeProps} {...props} />;
							case 'DataPropertyStock':
								return <DataPropertyStock {...routeProps} {...props} />;
							case 'DataPropertyActiva':
								return <DataPropertyActiva {...routeProps} {...props} />;
							case 'DataActivity':
								return <DataActivity {...routeProps} {...props} />;
							case 'DataOrderImport':
								return <DataOrderImport {...routeProps} {...props} />;
							case 'DataUploadLogo':
								return <DataUploadLogo {...routeProps} {...props} />;
							default:
								return <div className="container">Coming Soon</div>;
						}
					}} />
				);
			});
		});
	}

	render() {
		const { session: { menu, submenu, ...session } } = this.props;

		return (
			<div className={`app-container theme-${_.kebabCase(session.usergroup_name)}`}>
				<Header user={session.name} session={session} />
				<SideMenu menu={menu} submenu={submenu} session={session} />
				<main className="content" onClick={this.onContentClick.bind(this)}>
				<Switch>
					<Route exact path={DASHBOARD} render={() => {
						document.title = `${process.env.REACT_APP_TITLE} | Dashboard`;
						return <Dashboard />
					}} />
					<Route exact path={PROFILE} render={() => {
						document.title = `${process.env.REACT_APP_TITLE} | Profile`;
						return <Profile session={session} />
					}} />
					{ this.renderRoute(submenu, session) }
					{ session.usergroup_id === Number(process.env.REACT_APP_SUPERADMIN) && <Route path={MIGRATION} render={() => {
						document.title = `${process.env.REACT_APP_TITLE} | Data Migration`;
						return <DataMigration name="Data Migration"/>
					}} /> }
					<Route render={() => {
						return <Redirect to={DASHBOARD} />
					}} />
				</Switch>
				<ScrollToTop />
				<Arc />
				</main>
			</div>
		);
	}
}

export default Cms;
