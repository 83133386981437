import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import Select, { Async, Creatable } from 'react-select-virtualized';
import { Input, Textarea, Checkbox } from 'muicss/react';
import DatePicker from 'react-date-picker';
import { getAsync } from '../../actions';
import moment from 'moment';
import _ from 'lodash';

const DEFAULT_STYLE = {
		flexBasis: '100%'
};

class SelectAsync extends Component {
	constructor(props) {
		super(props);

		this.state = { selected: null };
		
		this.onChange = this.onChange.bind(this);
	}
	
	onChange(target) {
		this.props.onChange(target);
		this.setState({ selected: target });
	}
	
  render() {
    return <Async {...this.props} onChange={this.onChange} value={this.props.value && this.state.selected} />;
  }
}

class Picker extends Component {
	constructor(props) {
		super(props);
		
		const date = new Date();
		this.state = { value: date };
		
		this.props.onChange(moment(this.props.value || date).format('YYYY-MM-DD'));
		this.onChange = this.onChange.bind(this);
	}

	onChange(target) {
		this.props.onChange(moment(target).format('YYYY-MM-DD'));
		this.setState({ value: target });
	}
	
	render() {
	  return <DatePicker {...this.props} onChange={this.onChange} value={this.state.value} />;
	}
}

export const FieldItem = ({ input, field, className = '', children, meta: { touched, invalid, error, warning } }) => {
	const { style, option, ...others } = field;
	const attr = _.omitBy(others, (value, index) => {return index === 'hidden' || index === 'value'});

	const renderCheckbox = (name, option) => {
		return (
			<FieldArray name={name} component={({ fields }) => 
				<div>
				{_.map(option, (opt, index) => {
					opt.checked = false;
					fields.forEach((name, i) => {
						if(Number(fields.get(i)) === opt.value) {
							opt.checked = true;
						}
					});
					return (
						<div key={index} className="col-xs-6">
							<Checkbox value={opt.value} label={opt.label} checked={opt.checked} onChange={event => {
								if(event.target.checked) {
									fields.push(event.target.value);
								}else {
									fields.forEach((name, i) => {
										if(fields.get(i) === event.target.value) {
											fields.remove(i);
										}
									});
								};
							}} />
						</div>
					);
				})}
				</div>
			 }/>
		);
	}
	
	const filterOptions = (inputValue: string) => {
		return getAsync({ ...option, q: inputValue.toLowerCase() }).then(({data:{ data }}) => {
			return data;
		});
	};

	const promiseOptions = (inputValue, callback) => {
		new Promise(resolve => {
			resolve(filterOptions(inputValue));
		}).then(results => callback(results));
		return;
	}

	const renderField = () => {
		switch(attr.type){
			case 'textarea':
				return <Textarea {...input} {...attr} floatingLabel={true} />
			case 'select':
				return (
					<div className="mui-textfield">
						{ !option.table && <Select
							optionHeight={40} {...input} {...attr}
							value={
				        input.value && {
				          value: input.value,
				          label: option.find(o => o.value === input.value).label,
				        }}
							options={option} 
							placeholder={attr.placeholder || `Pilih ${attr.label}`}
							onBlur={() => {input.onBlur(input.value)}}
							onChange={(target) => {
								_.isFunction(attr.onChange) && attr.onChange(target);
								input.onChange(target && target.value);
							}}
							isClearable={attr.clearable || true}
							isDisabled={attr.disabled}
						/> }
						<label>{attr.label}</label>
					</div>
				);
			case 'history':
				return (
					<div className="mui-textfield">
						<Creatable optionHeight={40} {...input} {...attr}
							value={
								input.value && {
									value: input.value,
									label: input.value,
								}}
							options={input.value && !_.find(option, ['value', input.value])?_.concat({ value: input.value, label: input.value }, option):option}
							placeholder={attr.label}
							onBlur={() => {input.onBlur(input.value)}}
							onChange={(target) => {
								Promise.all([
									_.isFunction(attr.onChange) && attr.onChange(target),
									target && target.creatable && _.isFunction(attr.onNewOptionClick) && attr.onNewOptionClick(target)
								]).then(() => {
									input.onChange(target?target.value:'');
								});
							}}
							getNewOptionData={(label) => {
								const value = label = _.toUpper(label);
								return { value, label, creatable: true };
							}}
							formatCreateLabel={ (label) => label }
							noOptionsMessage={ () => "Input data baru" }
							isClearable={attr.clearable || true}
							isDisabled={attr.disabled}
							components={{
					      DropdownIndicator: () => null,
					      IndicatorSeparator: () => null,
					    }}
						/>
						<label>{attr.label}</label>
					</div>
				);
			case 'async':
				return (
					<div className="mui-textfield">
						{ option.table && <SelectAsync cacheOptions loadOptions={_.debounce(promiseOptions, input.wait || 750)}
							optionHeight={40} {...input} {...attr}
							placeholder={attr.placeholder || `Cari ${attr.label}`}
							noOptionsMessage={q => q.inputValue ? "Data tidak ditemukan" : "Masukkan huruf untuk mencari data"}
							loadingMessage={() => "Mencari data ..."}
							onBlur={() => {input.onBlur(input.value)}}
							onChange={(target) => {
								_.isFunction(attr.onChange) && attr.onChange(target);
								input.onChange(target && target.value);
							}}
							isClearable={attr.clearable || true}
							isDisabled={attr.disabled}
						/> }
						<label>{attr.label}</label>
					</div>
				);
			case 'checkbox':
				if(option) {
					return (
						<div name={input.name}>
							<legend>{attr.label}</legend>
							{renderCheckbox(input.name, option)}
						</div>
					);
				}else {
					return <Checkbox {...input} {...attr} />
				}
			case 'date':
				return (
					<div className="mui-textfield">
						<Picker {...input} {...attr}
						  format="yyyy-MM-dd"
							locale="id"
							clearIcon={null}
							defaultValue={new Date()}
							onBlur={() => input.onBlur(input.value)}
						  onChange={(target) => input.onChange(target)}
						/>
						<label>{attr.label}</label>
					</div>
				);
			case 'hidden':
				return <input {...input} {...attr} />
			default:
				return <Input autoComplete="off" {...input} {...attr} onChange={(target) => { _.isFunction(attr.onChange) && attr.onChange(target); input.onChange(target); }} floatingLabel={!attr.readOnly} />
	  }
	}

	return (
	  <div className={`${className} ${touched && invalid ? 'has-danger' : ''}`} style={!_.includes(className, 'col') && !_.includes(className, 'd-none')?(style || DEFAULT_STYLE):_.omit(style, ['flexBasis'])}>
	    {renderField()}
      {touched && ((error && <div className="mui--text-danger">{error}</div>) || (warning && <div className="mui--text-accent">{warning}</div>))}
	  </div>
	);
}

export class MyField extends Component {
	renderField(fields) {
		return _.map(fields, ({validate, ...field}, index) => {
			return (
				<Field 
					key={index}
					name={index}
					component={FieldItem}
					field={field}
					validate={validate}/>
			);
		});
	}

  render() {
  	const { fields } = this.props;
    return (
    	<div className="input-container">
      	{this.renderField(fields)}
      </div>
    );
  }
}
