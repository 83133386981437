import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { getFromUrl, mapField } from '../../actions';
import { FieldItem, Table, Barcode, HeaderForm } from '../common';
import { ModalPrint } from '../modal';

import { TnJ109 } from '../style';
import { initialize } from '../../fields';

class DataPrint extends Component {
	constructor(props) {
		super(props);
		const { slug, session, ...otherProps} = props;
		const { model, field: { header, field, keys } } = initialize(slug, session, otherProps);
		const tab = _.keys(header)[0];

		this.state = { model, header, field, keys, print: false, data: null, tab, initialized: false, fetching: true };
	}

	componentDidMount() {
		const { header } = this.state;

		Promise.all([
			..._.map(header, (v, i) => mapField(v, this.updateHeader.bind(this, i)).then((data) => {
				this.setState({ header: { ...this.state.header, [i]: _.mapKeys(data, 'name')} });
			}))
			/*/mapField(header).then((data) => {
				this.setState({ header: _.mapKeys(data, 'name') });
			})/*/
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	updateHeader(index, column, data) {
		const { header } = this.state;

		this.setState({ header: { ...header, [index]: { ...header[index], [column]: { ...header[index][column], option: data, value: null }}} });
	}

	onSubmit({code, ...props}) {
		const { valid, session: { store_id }} = this.props;
		const { model, tab } = this.state;

		if(valid) {
			(tab !== 'PerKodeProduk' || _.isEmpty(this.state.data)) && this.setState({ fetching: true });
		return getFromUrl(`${model[tab].url}${code?code:''}`, { ...props, store_id }).then(({data:{ data }}) => {
				data = tab === 'PerKodeProduk'?_.extend({}, this.state.data ,_.mapKeys(data, 'id')):_.mapKeys(data, 'id');
				this.setState({ data: data, fetching: false });
			});
		}
	}

	onPrintClick({skip, ...props}) {
		this.setState({ print: true, skip: skip, pField: _.mapKeys(props, (v, i) => _.replace(i, '_id', '_name')) });
		// window.print();
	}

	toggle(tab) {
		if (this.state.tab !== tab) {
			this.setState({ tab, data: null });
		}
	}

	renderHeader() {
		const { header, tab, initialized } = this.state;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div>
				<Nav tabs>
			{_.map(header, (v, i) => {
				return <NavItem key={i}>
					<NavLink className={`${i === tab?'active':''}`} onClick={() => { this.toggle(i) }} >{_.startCase(i)}</NavLink>
				</NavItem>
			})}
				</Nav>
				<TabContent activeTab={tab} style={{paddingTop: '15px'}}>
			{_.map(header, (v, i) => {
				return <TabPane key={i} tabId={i}>
					<HeaderForm form={_.camelCase(i)} onSubmit={this.onSubmit.bind(this)}>
						{_.map(v, ({validate, className, ...field}, index) => {
							return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={className}/>
						})}
					</HeaderForm>
				</TabPane>
			})}
				</TabContent>
			</div>
	}

	renderFooter() {
		const { field, print } = this.state;
		
		return (
			<div className="input-container d-print-none">
				<div className="col-12 col-md-4">
					<ModalPrint button="Pengaturan Print" field={field || []} onSubmit={this.onPrintClick.bind(this)} />
				</div>
				<div className="col-12 col-md-4">
					<Button color="primary" style={{width: '100%'}} onClick={() => {window.print()}} disabled={!print}>Print Label</Button>
				</div>
			</div>
		);
	}

	render() {
		const 
			{ name, slug } = this.props,
			{ keys, data, skip, pField, fetching } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				{ this.renderHeader() }
				{ <Table id="code" data={data} slug={slug} keys={keys} fetching={fetching} /> }
				{ data && this.renderFooter()}
				<TnJ109/>
				{<Barcode data={data} skip={skip} field={pField} />}
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataPrint);
