import React, { Component } from 'react';
import { reduxForm, Form as RForm } from 'redux-form';
// import _ from 'lodash';

class FormClass extends Component {
  // componentDidUpdate() {
  //   const { updateValues, change } = this.props;
  // 
  //   if(updateValues) {
  //     _.each(updateValues, (v, i) => {
  //       if(typeof v !== undefined) {
  //         change(i, v);
  //       }
  //     });
  //   }
  // }

	onSubmit(props) {
  	const { onSubmit, reset, keep } = this.props;

  	if(!keep) {
      reset();
    }
  	onSubmit(props);
	}

  render() {
  	const { form, handleSubmit, children } = this.props;

    return (
    	<RForm form={form} onSubmit={handleSubmit(this.onSubmit.bind(this))}>
      	{children}
      </RForm>
    );
  }
}

export const Form = reduxForm({
  form: 'defaultForm',
  enableReinitialize: true
})(FormClass);
