import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { reduxForm, Form } from 'redux-form';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { MyField } from './common';
import { login } from '../actions';
import { loginField } from '../fields';

let initialValues = {};
const $ = window.$;

class Login extends Component {
	constructor(props) {
		super(props);
		document.title = `${process.env.REACT_APP_TITLE} | Login`;

		this.state = { error: null, benefits: {} };
	}

	componentDidMount() {
		axios.get('/benefits.json').then(({ data }) => {
			this.setState({ benefits: data });
		});
		$('#loginCarousel').on('slide.bs.carousel', function (e) {
			$(`.body-caption > [data-index="${e.from}"]`).fadeOut(function() {
				$(`.body-caption > [data-index="${e.to}"]`).fadeIn();
			});
		});
	}

	scrollTo(target) {
		$('html, body').animate({
        scrollTop: $(target).offset().top
    }, 1000);
	}

	onSubmit(props) {
		return this.props.login(props)
			.then((result)=>{
				if(result.error) {
					return this.setState({ error: result.payload.response.data.message });
				}
			});
	}

	render() {
		const { handleSubmit, submitting } = this.props;
		const { benefits } = this.state;

		return (
			<div id="login" className="container-fluid">
				<div className="row login-header">
					<div className="col position-relative">
						<div className="row">
							<div className="header-logo col-3 d-flex align-items-center justify-content-center">
								<span className="logo-lg d-none d-sm-block">efata<b>optical</b></span>
								<span className="logo-sm d-sm-none">E<b>O</b></span>
							</div>
							<div className="header-contact col text-right">
								<div>KONTAK KAMI</div>
								<div>|</div>
								<div><a href="https://efataopto.id" target="_blank" rel="noopener noreferrer">efataopto.id</a></div>
								<div></div>
							</div>
						</div>
						<div className="row header-body">
							<div className="col-12 col-sm-3 body-caption">
								{ _.map(benefits, ({ caption }, i) => {
									return <div key={i} data-index={i} className={i === 0?'active':''}>
										<span>{ caption }</span>
									</div>
								}) }
							</div>
							<div className="col-12 col-sm body-carousel">
								<div id="loginCarousel" className="carousel slide" data-ride="carousel">
									<ol className="carousel-indicators">
										{ _.map(benefits, (v, i) => {
											return <li data-target="#loginCarousel" key={i} data-slide-to={i} className={i === 0?'active':''}></li>
										}) }
									</ol>
									<div className="carousel-inner">
										{ _.size(benefits) === 0 && <div className="carousel-item active" style={{ minHeight: '30vw' }} /> }
										{ _.map(benefits, ({ image, alt }, i) => {
											return <div key={i} className={`carousel-item ${i === 0?'active':''}`}>
												<img className="w-100" src={image} alt={alt} />
											</div>
										}) }
									</div>
									<a className="carousel-control-prev" href="#loginCarousel" role="button" data-slide="prev">
										<span className="carousel-control-prev-icon" aria-hidden="true"></span>
										<span className="sr-only">Previous</span>
									</a>
									<a className="carousel-control-next" href="#loginCarousel" role="button" data-slide="next">
										<span className="carousel-control-next-icon" aria-hidden="true"></span>
										<span className="sr-only">Next</span>
									</a>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12 col-sm-3 d-flex justify-content-center">
								<div className="body-sticky" onClick={this.scrollTo.bind(this, '.login-form')}>SCROLL KEBAWAH UNTUK LOGIN INVENTORY</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row login-body">
					<div className="col body-container">
						<div className="row">
							<div className="col-12 col-sm-6 body-caption">
								<span>Semakin mudah kelola bisnis optik anda dengan sistem efataoptical</span>
							</div>
							<div className="col-12 col-sm-6 body-form">
								<Form onSubmit={handleSubmit(this.onSubmit.bind(this))} className="login-form">
									<h3>
										<img className="logo" src="/images/mini-logo.png" alt="Efata Optical"/>
									</h3>
									<MyField fields={loginField} />
									<div className="form-group mui--text-danger text-center">{this.state.error}</div>
									<Button type="submit" color="primary" disabled={submitting}>Login <i className={`fa fa-${submitting?'spinner fa-pulse':'lock'}`} aria-hidden="true"></i>
									</Button>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default reduxForm({
	form: 'LoginForm',
	initialValues,
})(connect(null, { login })(Login));
