import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import moment from 'moment';
import _ from 'lodash';

import { getFromUrl, mapField } from '../../actions';
import { FieldItem, Table } from '../common';

import { debitField } from '../../fields';

class DataDebit extends Component {
	constructor(props) {
		super(props);

		const headerField = _.cloneDeep(debitField.header);
		const keys = _.cloneDeep(debitField.data);

		this.state = { headerField, keys, data: null, initialized: false, fetching: true };
	}

	componentDidMount() {
		const { headerField } = this.state;

		Promise.all([
			mapField(headerField).then((data) => {
				this.setState({ headerField: _.mapKeys(data, 'name') });
			}),
			this.updateDate()
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	updateDate() {
		// const { session: { store_id }, change } = this.props;
		const { change } = this.props;

		const now = Date.now();
		const lasMonth = now - (30*24*3600*1000);
		// change('store_id', store_id);
		change('date_from', moment(lasMonth).format('YYYY-MM-DD'));
		change('date_to', moment(now).format('YYYY-MM-DD'));
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			this.setState({ data: [], fetching: true });
			return getFromUrl('input', props).then(({data:{ data }}) => {
				// data = _.mapKeys(data, 'id');
				// _.each(data, (value) => {
				// 	value.totalInt = 0;
				// 	_.each(value.detail, (detail) => {
				// 		value.totalInt += parseInt(detail.price, 10);
				// 	});
				// 	value.total = value.totalInt.toLocaleString('id');
				// });
				this.setState({ data: _.mapKeys(data, 'id'), fetching: false });
			});
		}
	}

	renderHeader() {
		const { submitting } = this.props;
		const	{ headerField, initialized } = this.state;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container" style={{justifyContent: 'flex-end'}}>
				{_.map(_.cloneDeep(headerField), ({validate, ...field}, index) => {
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate}/>
				})}
				<div style={{flexBasis: '25%', paddingTop: '8px'}}>
					<Button type="submit" color="primary" style={{width: '100%'}} disabled={submitting}>Cari</Button>
				</div>
			</div>
	}

	renderNote() {
		return (
			<div style={{flexBasis: '100%', color: 'red'}}>
				* Tampilan Daftar hutang di isi berdasarkan harga barang masuk awal sesuai Harga Netto yang di terima
			</div>
		);
	}

	render() {
		const 
			{ name, slug, submenu, handleSubmit } = this.props,
			{ keys, data, initialized, fetching } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">Data {name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{ this.renderHeader()}
				</form>
				{ initialized && this.renderNote() }
				{ initialized && <Table id="code" data={data} slug={slug} keys={keys} submenu={submenu} fetching={fetching} /> }
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataDebit);
