import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form } from 'redux-form';
import { Modal as RModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// Props:
// form = form name
// onSubmit = function form on submit

class ModalClass extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null, modal: false };
  }

  onSubmit(props) {
    const { onSubmit, valid, reset } = this.props;

    valid && Promise.resolve(onSubmit(props)).then(() => {
      this.toggle();
      reset();
    }).catch((err) => {
      // console.log(err);
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    const { type, title, size, ok, cancel, children, handleSubmit, submitting } = this.props;

    return (
      <div className="d-print-none">
        <button className="btn btn-primary" onClick={this.toggle.bind(this)} disabled={false}>{'Tambah'}</button>
        <RModal id={`modal-${type}`} size={size} isOpen={this.state.modal} toggle={this.toggle.bind(this)}>
          <ModalHeader toggle={this.toggle.bind(this)}>{title || "Tambah Baru"}</ModalHeader>
          <Form name={`modal-${type}`} className="form-horizontal" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <ModalBody>
            {children}
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="btn btn-primary" disabled={submitting}>{ok}</button>
            <button type="reset" className="btn btn-link" onClick={this.toggle.bind(this)}>{cancel}</button>
          </ModalFooter>
          </Form>
        </RModal>
      </div>
    );
  }
}

ModalClass.defaultProps = {
  onSubmit: () => {},
  title: 'Modal',
  size: 'sm',
  ok: 'OK',
  cancel: 'Cancel',
  // initialValues: {}
};

ModalClass.propTypes = {
  type: PropTypes.oneOf(['view', 'add', 'edit', 'delete']).isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  size: PropTypes.string,
  ok: PropTypes.string,
  cancel: PropTypes.string,
  // initialValues: PropTypes.object
}

export const Modal = reduxForm({
  form: 'default-form',
  enableReinitialize: true
})(ModalClass);