import React, { Component } from 'react';
import _ from 'lodash';

import { postFromUrl } from '../actions/index';
import { ModalAdd } from './modal';
import { profile } from '../fields';

class Profile extends Component {
	constructor(props) {
		super(props);

		this.state = { error: '', success: '' };
	}

	componentDidMount() {

	}

	onChangePasswordClick(props) {
		const { session } = this.props;

		postFromUrl("change-password", { ...props, slug: session.slug || session.code, usergroup_id: session.usergroup_id }).then(({data:{ data }}) => {
			this.setState({ error : '', success: 'Password berhasil diubah' });
		}).catch((err) => {
			err && err.response && err.response.data && this.setState({ error : _.map(err.response.data.message), success: '' });
		});
	}

	render() {
		const { data, changePasswordField } = profile;
		const { session } = this.props;
		const { error, success } = this.state;

		return (
			<div className="container-fluid">
				<div className="row py-3">
					<h1 className="col-12 col-md-6 offset-md-3 mb-5">Profile</h1>
					<div className="col-12 col-md-6 offset-md-3">
						{ _.map(data, (v, i) => {
						return (
							<div key={i} className="form-group row">
								<div className="col-6 col-form-label">{v.label}</div>
								<div className="col-6 col-form-label">{session[i] || '-'}</div>
							</div>
						)}) }
					</div>
				</div>
				<div className="row">
					<div className="col col-md-6 offset-md-3 py-3">
						<span className="text-danger">{ error || '' }</span>
						<span className="text-success">{ success || '' }</span>
					</div>
				</div>
				<div className="row">
					<div className="col col-md-6 offset-md-3">
						<ModalAdd title="Change Password" field={changePasswordField} onAddSubmit={this.onChangePasswordClick.bind(this)} button="Change Password" close />
					</div>
				</div>
			</div>
		);
	}
}

export default Profile;
