import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Col, Collapse } from 'reactstrap';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { getSelect, getFromUrl } from '../../actions';
import { FieldItem } from '../common';

import { mobileField } from '../../fields';

let field;

class DataPropertyActiva extends Component {
	constructor(props) {
		super(props);

		this.state = { state: 0, header: [], data: [], option: [], loading: false, init: false, collapse: false };
	}

	componentDidMount() {
		this.initField();
		this.getData([]);
	}

	initField() {
		field = _.cloneDeep(mobileField.property);
		field = this.mapValues(field, true);
	}

	mapValues(field, state = false) {
		return _.mapValues(field, (value, index) => {
			if(value.option && value.option.table) {
				getSelect(value.option).then(({data:{ data }}) => {
					value.option = data;
					if(state) this.setState({state: this.state.state + 1});
					// else {
						const { option } = this.state;
						this.setState({ option: {...option, [index]: _.mapKeys(data, (v) => {return v.value})}});
					// }
				});
			}
			return value;
		});
	}

	toggle() {
		this.setState({ collapse: !this.state.collapse });
	}

	getData(props) {
		this.setState({ loading: true });
		return getFromUrl('property-activa', props).then(({data:{ data }}) => {
			this.setState({ data, header: props, loading: false, init: true });
		});
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			return this.getData(props);
		}
	}

	renderField() {
		const { submitting } = this.props;

		return (
			<div className="row" style={{alignItems: 'baseline'}}>
				{_.map(field, ({validate, className, ...field}, index) => {
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={className} />
				})}
				<Col xs="4">
					<Button type="submit" color="primary" style={{width: '100%'}} disabled={submitting}>Filter</Button>
				</Col>
			</div>
		);
	}

	renderData() {
		const { data } = this.state;

		return _.map(data, (v) => {
			return (
				<Col xs="6" key={v.id}>
					<div className="category">
						<h3 className="name">{v.name}</h3>
						<h3 className="activa">
							<span className="badge badge-info">Rp {parseInt(v.activa, 10).toLocaleString('id')}</span>
						</h3>
					</div>
				</Col>
			);
		});
	}

	render() {
		const 
			{ name, handleSubmit } = this.props,
			{ state, header, option, data, loading, init, collapse } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<Collapse isOpen={collapse}>
					<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
						{(state !== 0) && this.renderField()}
					</form>
				</Collapse>
				<div id="filter" className={collapse?`open`:``} onClick={this.toggle.bind(this)}><i className="fa fa-angle-double-down"></i></div>
				<div id="mobile-content" className="row">
					{ loading && 
						<div className="col loading"><i className="fa fa-spinner fa-pulse fa-2x fa-fw"></i></div>}
					{ !loading && header.store_id && !_.isEmpty(option) && <h3 className="header">{option.store_id[header.store_id].label}</h3>}
					{ !loading && this.renderData()}
					{ init && !loading && _.isEmpty(data)	&& <h3 className="col empty text-danger">Stok di toko tidak tersedia</h3>}
				</div>
			</div>
		);
	}
}

export default reduxForm({
	form: 'filterForm'
})(DataPropertyActiva);
