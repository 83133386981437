import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTopClass extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
			if(window.innerWidth < 768) {
				document.body.classList.remove('sidebar-open');
			}
    }
  }

  render() {
    return null;
  }
}

export const ScrollToTop = withRouter(ScrollToTopClass);