import React, { Component } from 'react';
import { reduxForm, Form } from 'redux-form';
import { Navbar, NavbarBrand, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'muicss/react';

import { logout } from '../../actions/index';

class HeaderClass extends Component {
	constructor(props) {
		super(props);

		this.onToggleClick = this.onToggleClick.bind(this);
		this.onDropdownClick = this.onDropdownClick.bind(this);
		this.state = { dropdown: false };
	}

	onToggleClick() {
		if(window.innerWidth < 768) {
			document.body.classList.remove('sidebar-collapse');
			document.body.classList.toggle('sidebar-open');
		}else {
			document.body.classList.remove('sidebar-open');
			document.body.classList.toggle('sidebar-collapse');
		}
	}

	onDropdownClick() {
		this.setState({
			dropdown: !this.state.dropdown
		});
	}

	render() {
		const { logout, session } = this.props;

		return (
			<Navbar fixed="top" light>
				<NavbarBrand tag={Link} to={session.usergroup_id === Number(process.env.REACT_APP_CUSTOMER)?"/profile":"/dashboard"}>
					<span className="logo-lg"><img src="/images/logo-dashboard.png" alt="EfataOPTICAL" width="100%" height="100%" /></span>
					<span className="logo-sm"><b>E</b>O</span>
				</NavbarBrand>
				<div className="navbar-custom-menu" data-usergroup={session.usergroup_name}>
					<span className="sidebar-toggle" onClick={this.onToggleClick} data-toggle="offcanvas" role="button">
						<span className="sr-only">Toggle navigation</span>
					</span>
					<Dropdown isOpen={this.state.dropdown} toggle={this.onDropdownClick}>
						<DropdownToggle tag="a">
							<i className="fa fa-user"></i>
							<span className="d-none d-sm-block">{session.name}</span>
						</DropdownToggle>
						<DropdownMenu>
							<li className="user-header">
								<i className="fa fa-user"></i>
								<p>
									{session.name}
									<small> {session.store.name}</small>
								</p>
							</li>
							<li className="user-footer">
								<Button color="danger" onClick={logout}>
									<i className="fa fa-sign-out"></i>
									<span> Logout</span>
								</Button>
							</li>
						</DropdownMenu>
					</Dropdown>
				</div>
			</Navbar>
		);
	}
}

export const Header = connect(null, { logout })(HeaderClass);

class HeaderFormClass extends Component {
	onSubmit(props) {
		const { onSubmit } = this.props;

		return onSubmit && Promise.resolve(onSubmit(props));
	}

	onReset() {
		const { onReset } = this.props;

		return onReset && Promise.resolve(onReset());
	}

	render() {
		const { form, handleSubmit, children, onReset, submitting, props } = this.props;

		return (
			<Form form={form} onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<div className="input-container">
				 {children}
				 <div className="d-print-none col-12 col-md-2 d-flex" style={{paddingTop: '8px', marginLeft: 'auto'}}>
						{ (!onReset || !props) && <Button type="submit" color="primary" className="col" disabled={submitting}>Tetapkan</Button>}
						{ onReset && props && <Button type="reset" color="danger" className="col" onClick={this.onReset.bind(this)}>Reset</Button>}
					</div>
				</div>
			</Form>
		);
	}
}

export const HeaderForm = reduxForm({
	form: 'headerForm',
	enableReinitialize: true
})(HeaderFormClass);
