import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery';
import 'datatables.net';
import _ from 'lodash';

export class Datatable extends Component {
	constructor(props) {
		super(props);
		const { keys, action } = props;

		const custom = _.pickBy(keys, (v) => _.isObject(v) && v.type === 'action');

		const header = _.map(_.omit(keys, _.keys(custom)), (v, i) => {
			const column = _.assign({ render: (data) => data || '' }, _.isString(v)?{data: i, title: v}:{data: i, ...v});
			return column;
		});
	
		this.state = { header, action: _.assign({}, custom, action), redirect: '' };
	}

	componentDidMount() {
		// const { keys, action, child, onClick, simple = false, saveState = true, serverSide = false, model, onAjaxChange } = this.props;
		const { data, keys, onClick, advanced = false, saveState = false, footer = false } = this.props;
		const { header, action } = this.state;

		$.fn.dataTable.Api.register( 'processing()', function ( show ) {
			return this.iterator( 'table', function ( ctx ) {
				ctx.oApi._fnProcessingDisplay( ctx, show );
			});
		});
		// $.fn.dataTableExt.errMode = 'ignore';
		$.extend( $.fn.dataTable.defaults, {
			lengthMenu: [ [10, 25, 50, -1], [10, 25, 50, "All"] ],
			stateSave: saveState,
			paging: advanced,
			ordering: advanced,
			order: [],
			info: advanced,
			filter: advanced,
			// serverSide: serverSide,
			autoWidth: false,
			processing: true,
			// ajax: serverSide && {
			// 	url: document.head.querySelector('meta[name="api-url"]').content + '/datatable',
			// 	type: 'POST',
			// 	data: model,
			// 	dataSrc: function ({ data }) {
			// 		_.isFunction(onAjaxChange) && onAjaxChange(data);
			// 		return data;
			// 	}
			// },
			// dom: '<"datatable-header"fl><"datatable-scroll-wrap"tr><"datatable-footer"ip>',
			dom: '<"datatable-header d-print-none"l><"datatable-scroll-wrap"tr><"datatable-footer d-print-none"ip>',
			columns: _.compact([
				...header,
				!_.isEmpty(_.compact(_.values(action))) && {
					data: "action", title: "Action", className: "text-center action", width: '8%',searchable: false, orderable:false,
					render: (data, type, full, meta) => {
						return ReactDOMServer.renderToStaticMarkup(this.renderAction(action, full.id, full));
					}
				}
			]),
			language: {
				search: '<span>Filter:</span> _INPUT_',
				searchPlaceholder: 'Type to filter...',
				lengthMenu: '<span>Show:</span> _MENU_',
				paginate: { 'first': 'First', 'last': 'Last', 'next': '<i class="fa fa-angle-double-right" aria-hidden="true"></i>', 'previous': '<i class="fa fa-angle-double-left" aria-hidden="true"></i>' },
				processing: '<i class="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp;Loading...',
			},
			stateSaveCallback: saveState && this.stateSaveCallback.bind(this),
			stateLoadCallback: saveState && this.stateLoadCallback.bind(this),
			footerCallback: footer && function ( row, data, start, end, display ) {
				var api = this.api();
				var count = -1;
				
				_.each(footer, (value, index) => {
					count += value;
					if(index === 0) {
						$(row).find('th').eq(index).html("Total");
					}
					else if(value !== 0){
						$(row).find('th').eq(index).html(api.column( count ).data().reduce((a, b) => _.parseInt(a) + _.parseInt(b), 0).toLocaleString('id'));
					}
				});
			}
		});
		if(footer) $(this.refs.dt).append(`<thead/><tbody/><tfoot><tr>${_.join(_.map(footer, (value) => `<th colspan="${value}"></th>`))}<th></th></tr></tfoot>`);
		this.table = $(this.refs.dt).DataTable({ data: _.map(data) });
		$('#root .datatable-basic').on('click', '.action [data-toggle="modal"]', ({ target }) => {
			const id = target.getAttribute('data-id');
			const type = target.getAttribute('data-type');

			id && _.isFunction(onClick) && onClick(id, type);
		});
		$('#root .datatable-basic').on('click', '.action [data-toggle="href"]', (e) => {
			e.preventDefault();
			const id = e.target.getAttribute('data-id');
			const href = e.target.getAttribute('data-href');

			const redirect = _.replace(href, ':id', id);
			$('body [data-tooltip]').tooltip('dispose');
			this.openInNewTab(redirect);
			// this.setState({ redirect });
		});
		$('#root .datatable-basic').on('click', '.action [data-toggle="click"]', (e) => {
			e.preventDefault();
			const id = e.target.getAttribute('data-id');
			const action = e.target.getAttribute('data-action');

			action && keys[action] && _.isFunction(keys[action].onClick) && keys[action].onClick(e, id);
		});
		$('#root .datatable-basic').on('change', '.action [data-toggle="change"]', (e) => {
			e.preventDefault();
			const id = e.target.getAttribute('data-id');
			const action = e.target.getAttribute('data-action');

			action && keys[action] && _.isFunction(keys[action].onChange) && keys[action].onChange(e, id);
		});
	}

	componentDidUpdate(prevProps) {
		const { data, serverSide = false, fetching } = this.props;
		
		this.table.processing(fetching);
		if(!_.isEqual(prevProps.data, data) && !serverSide) {
			this.table.clear().rows.add(_.map(data)).draw();
		}
	}

	stateSaveCallback(settings, data) {
		const { initialized } = this.props;

		if(initialized && settings.sInstance.indexOf(':id') === -1) {
			localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) );
		}
	}

	stateLoadCallback(settings) {
		return JSON.parse( localStorage.getItem( 'DataTables_' + settings.sInstance ) );
	}

	openInNewTab(url) {
		var win = window.open(url, '_blank');
		win.focus();
	}

	renderAction(action, id, full) {
		const { slug, data } = this.props;
		const icon = {
			edit: 'fa-pencil-square-o',
			remove: 'fa-trash-o',
			submenu: 'fa-eye'
		};

		return (
			<div>
				{ _.map(_.pickBy(action, (v) => v && _.parseInt(v) !== 0), (v, i) => {
					if(_.isFunction(v.render)) return v.render(full[i], full, i);
					const isSubmenu = _.isString(v);
					const href = isSubmenu && (slug.indexOf('/') === -1)?`/${slug}/${id}/${v}`:`/${slug}/${v}/${id}`;
					return <i key={i} title={_.startCase(isSubmenu?`View ${_.startCase(v)}`:i)} data-toggle={isSubmenu?"href":"modal"} data-tooltip data-id={_.findKey(data, full)} {...isSubmenu?{'data-href':href}:{'data-type':i}} className={`fa ${icon[i]}`} aria-hidden="true"></i>
				}) }
			</div>
		);
	}

	render() {
		const { slug } = this.props;
		const { redirect } = this.state;

		return redirect?<Redirect push to={redirect} />:(
			<table id={slug} ref="dt" className="table datatable-basic" />
		);
	}
}