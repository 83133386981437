import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
// import moment from 'moment';
import _ from 'lodash';

import { getFromUrl, postFromUrl, putFromUrl, deleteFromUrl, mapField } from '../../actions';
import { ModalAdd } from '../modal';
import { FieldItem, Table } from '../common';

import { initialize } from '../../fields';

class DataTable extends Component {
	constructor(props) {
		super(props);

		const { slug, session, ...otherProps} = props;
		const { field:{ header, field, keys } } = initialize(slug, session, otherProps);
		const map = keys || _.mapKeys(_.mapValues(_.omitBy(field, (value, index) =>	value.hidden || index === 'description'), 'label'), (value, index) => _.replace(index, '_id', '_name'));

		this.state = { data: {}, header, field, keys: map, initialized: false, fetching: true };
	}
	
	componentDidMount() {
		const { slug } = this.props;
		const { header, field } = this.state;

		Promise.all([
			_.isEmpty(header) && this.getData(slug),
			mapField(header, this.updateHeader.bind(this)).then((data) => {
				this.setState({ header: _.mapKeys(data, 'name') });
			}),
			mapField(field, this.updateField.bind(this)).then((data) => {
				this.setState({ field: _.mapKeys(data, 'name') });
			})
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	getData(slug, props) {
		return getFromUrl(slug, props).then(({data:{ data }}) => {
			this.setState({ data: _.mapKeys(data, 'id') });
		});
	}

	updateField(column, data) {
		const { field } = this.state;
		// const { change } = this.props;

		// change(column, null);
		this.setState({ field: { ...field, [column]: { ..._.mapValues(field[column], (v) => _.omit(v, 'value')), option: data, value: null }} });
	}

	updateHeader(column, data) {
		const { header } = this.state;
		const { change } = this.props;

		change(column, null);
		this.setState({ header: { ...header, [column]: { ..._.mapValues(header[column], (v) => _.omit(v, 'value')), option: data, value: null }} });
	}

	onSubmit(props) {
		const { valid, slug } = this.props;

		if(valid) {
			this.setState({ fetching: true });
			return this.getData(slug, props).then(() => {
				this.setState({ fetching: false });
			});
		}
	}

	onAddSubmit(props) {
		const { slug } = this.props;
		return postFromUrl(slug, props).then(({data:{ data }}) => {
			this.setState({ data: { ...this.state.data, [data.id]: data } });
		});
	}

	onEditSubmit(props, id) {
		const { slug } = this.props;
		return putFromUrl(`${slug}/${id}`, props).then(({data:{ data }}) => {
			this.setState({ data: { ...this.state.data, [id]: data } });
		});
	}

	onRemoveSubmit(id) {
		const { slug } = this.props;
		const params = { table: slug, id };
		return deleteFromUrl('delete', params).then(({data:{ data }}) => {
			this.setState({ data: _.omit(this.state.data, [id]) });
		});
	}

	renderHeader() {
		const { header, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			!_.isEmpty(header) && <div className="input-container row">
				{_.map(_.cloneDeep(header), ({validate, className, ...field}, index) => {
					const empty = field.type === 'select' && _.isEmpty(field.option);
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={`${className || "col-12 col-md"} ${empty?'d-none':''}`} />
				})}
				<div className="d-print-none col-12" style={{paddingTop: '8px'}}>
					<div className="row d-flex justify-content-end">
						<div className="col col-sm-3">
							<Button type="submit" color="primary" style={{ width:'100%' }} disabled={submitting}>Cari</Button>
						</div>
					</div>
				</div>
			</div>
	}

	render() {
		const 
			{ name, slug, add, edit, remove, submenu, handleSubmit } = this.props,
			{ data, field, keys, fetching, initialized } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">Data {name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && add === 1 && <ModalAdd field={_.cloneDeep(field)} fetching={fetching} onAddSubmit={this.onAddSubmit.bind(this)} close={true}/> }
				{ initialized && <Table data={data} slug={slug} field={_.cloneDeep(field)} keys={keys} edit={edit} remove={remove} submenu={submenu} fetching={fetching} onEditSubmit={this.onEditSubmit.bind(this)} onRemoveSubmit={this.onRemoveSubmit.bind(this)} advanced /> }
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataTable);
