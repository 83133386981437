import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { getFromUrl } from '../../actions';
import { Table } from '../common';
import { A5 } from '../style';

import { transactionCancelField } from '../../fields';

class DataSubTransactionCancel extends Component {
	constructor(props) {
		super(props);

		const headerField = {
			'admin.name': {
				label: 'Admin',
			},
			'customer.name': {
				label: 'Pelanggan',
			},
			'cancel': {
				label: 'Alasan Batal',
			},
		};
		const keys = _.cloneDeep(transactionCancelField.data);
		const keysRecipe = _.cloneDeep(transactionCancelField.recipe);

		this.state = { headerField, keys, keysRecipe, transaction: null, recipe: null, initialized: false };
	}

	componentDidMount() {
		const { match: { params } } = this.props;

		Promise.all([
			getFromUrl(`transaction/${params.id}`).then(({data:{ data }}) => {
				if(data.cancel === null) {
					window.close();
				}
				const recipe = _.mapKeys(data.recipe, 'eye');
				this.setState({ transaction: data, recipe });
			}),
		]).then(() => {
			this.setState({ initialized: true });
		});
	}

	renderTransaction(transaction) {
		const { headerField } = this.state;

		return _.map(_.cloneDeep(headerField), (value, index) => {
			return (
				<div key={index} style={{display: 'flex', flexBasis: '50%', margin: '5px 0'}}>
					<div style={{flexBasis: '30%'}}>{value.label}</div>
					<div style={{flexBasis: '5%'}}>:</div>
					<div style={{flexBasis: '65%', color: '#F44336', fontWeight: '600'}}>{_.get(transaction, index)}</div>
				</div>
			);
		});
	}

	closeWindow(e) {
		e.preventDefault();
		window.close();
	}
	
	onPrintClick() {
		window.print();
	}

	render() {
		const
			{ name
				// , match: { params }
			} = this.props,
			{ keys, keysRecipe, transaction, recipe, initialized } = this.state;
			console.log(transaction);

		return (
			<div className="container-fluid">
				{/*/<h3 className="text-center">Data {name}: <Link to={`/stock/${params.id}`} className="mui--text-danger">{transaction && transaction.store_name}</Link></h3>/*/}
				<h3 className="text-center">Data {name}: <a href="#close" className="mui--text-danger" onClick={this.closeWindow}>{transaction && transaction.code}</a></h3>
				{ !initialized && <div className="row">
					<div className="col-12 text-center">
						<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
						</div>
				</div>}
				{ initialized && <div className="input-container" style={{margin: '15px 0'}}>
					{ transaction && this.renderTransaction(transaction) }
				</div>}
				<div className="row d-print-none">
					<div className="col col-sm-3 d-sm-block">
						<Button type="reset" color="primary" style={{ width:'100%' }} onClick={this.onPrintClick.bind(this)}>Print</Button>
					</div>
				</div>
				{ initialized && <Table data={transaction && transaction.detail} keys={keys} /> }
				{ initialized && !_.isEmpty(recipe) && <Table data={recipe} keys={keysRecipe} /> }
				<A5 />
			</div>
		);
	}
}

export default DataSubTransactionCancel;
