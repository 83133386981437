import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import _ from 'lodash';

import { getSelect, getHistory, getDetail, getCount, saveData, mapField } from '../../actions';
import { Modal, ModalAdd, ModalPrint } from '../modal';
import { FieldItem, Table, Barcode } from '../common';

import { TnJ109, A5 } from '../style';
import { inputSimpleField, required } from '../../fields';

let field, keys;

class DataInput extends Component {
	constructor(props) {
		super(props);

		const headerField = _.cloneDeep(inputSimpleField.header);

		this.state = { headerField, field: [], keys: [], state: 0, count: 0, header: null, data: [], input: null, option: [], saving: false, printing: false, initialized: false, fetching: true, modal: 0, label: false };
	}

	componentDidMount() {
		const { headerField } = this.state;

		Promise.all([
			mapField(headerField).then((data) => {
				this.setState({ headerField: _.mapKeys(data, 'name') });
			})
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	initField(data, header) {
		const { code, brand_id, name, ...otherField } = _.cloneDeep(inputSimpleField.data);
		field = _.merge({code, brand_id, name}, _.mapKeys(_.map(data.detail, (value) => {return value.field}), (value) => {return value.name}), otherField);
		keys = _.mapKeys(_.mapValues(_.omitBy(field, (value, index) => { return value.hidden || index === 'description'; }), (value) => { return value.label }), (value, index) => { return _.replace(index, '_id', '_name') });
		field.name.history.supplier_id = header.supplier_id;
		field.name.history.category_id = header.category_id;
		field = this.mapValues(field, header);
	}

	mapValues(field, header = [], state = false) {
		return _.mapValues(field, (value, index) => {
			if(value.option && value.option.table) {
				_.each(value.option, (v, i) => {
					if(_.endsWith(i, '_id')) {
						value.option[i] = header[i] || value.option[i];
					}
				});
				getSelect(value.option).then(({data: {data}}) => {
					value.option = data;
					if(state) this.setState({state: this.state.state + 1});
					else {
						const { option } = this.state;
						this.setState({ option: {...option, [index]: _.mapKeys(data, (v) => {return v.value})}});
					}
				});
			}else if(value.history && value.history.table) {
				getHistory(value.history).then(({data: {data}}) => {
					value.option = data;
					value.onNewOptionClick = (target) => {
						value.option = _.concat(target, data);
						this.setState({state: this.state.state + 1});
					};
					if(state) this.setState({state: this.state.state + 1});
				});
			}if(value.required) {
				value.validate = required;
				value.required = undefined;
			}
			return value;
		});
	}

	onResetClick() {
		const { reset } = this.props,
			header = null, data = [], input = null, saving = false, printing = false, modal = 0;

		reset();
		this.setState({ header, data, input, saving, printing, modal });
	}

	onSubmit(props) {
		const { valid } = this.props;

		if(valid) {
			const header = _.cloneDeep(props);
			this.setState({ fetching: true });
			return getDetail('category', props.category_id).then(({ data: { data } }) => {
				header.category = data;
				Promise.all([
					this.initField(data, header),
					getCount(props.category_id).then(({ data: { data } }) => {
						return data;
					}),
				]).then((data) => {
					const count = data[1];
					this.setState({ header, count, fetching: false });
				});
			});
		}
	}

	onAddSubmit(props) {
		const { data, option } = this.state;
		let { count } = this.state;

		const add = _.cloneDeep(props);
		_.each(add, (value, index) => {
			if(_.endsWith(index, '_id')) {
				const table = _.replace(index, '_id', '_name');
				add[table] = option[index][value].label;
			}
			add[index] = _.toUpper(value);
		});

		this.setState({data: {...data, [add.code]: add}, count: ++count});
	}

	onEditSubmit(props, code) {
		const { data } = this.state;

		this.setState({data: {...data, [code]: {...data[code], ...props}}});
	}

	onRemoveSubmit(code) {
		const { data } = this.state;

		this.setState({data: _.omitBy(data, (value, index) => { return index === code })});
	}

	onSaveClick() {
		const { header, data } = this.state,
			{ session, change } = this.props;

		this.setState({ saving: true });
		saveData("input", { header, data, session }).then(({data:{ data }}) => {
			change('code', data.code);
			this.setState({ input: data });
		}).catch((err) => {
			this.setState({ saving: false });
		});
	}

	onPrintClick(props) {
		const { input } = this.state;

		if(props !== false) {
			const { skip, ...other } = props;
			return Promise.resolve(
				getDetail('barcode', input.code, { store_id: input.store_id }).then(({data:{ data }}) => {
					this.setState({ label: true, print: data, skip: skip, printField: _.mapKeys(other, (value, index) => _.replace(index, '_id', '_name')) });
				})
			).then(()=>{
				window.print();
			});
		}else{
			return Promise.resolve(
				this.setState({ label: false })
			).then(()=>{
				window.print();
			});
		}
	}

	renderHeader() {
		const { header, headerField, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container">
				{_.map(_.cloneDeep(headerField), ({validate, ...field}, index) => {
					field.disabled = !_.isEmpty(header) || field.disabled;
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate}/>
				})}
				<div className="d-print-none" style={{flexBasis: '25%', paddingTop: '8px'}}>
					{!header && <Button type="submit" color="primary" style={{width: '100%'}} disabled={submitting}>Tetapkan</Button>}
					{header && <Button type="reset" color="danger" onClick={this.onResetClick.bind(this)} style={{width: '100%'}}>Penambahan Baru</Button>}
				</div>
				<div className="d-print-none" style={{flexBasis: '100%', color: 'red'}}>
					* Kode akan ditampilkan saat proses input berhasil
				</div>
			</div>
	}

	renderFooter() {
		const { header, input, saving } = this.state;
		return (
			<div className="input-container d-print-none">
				<div style={{flexBasis: '100%', color: 'red'}}>
					* Harap simpan data sebelum meninggalkan halaman ini
				</div>
				<div style={{flexBasis: '25%', paddingRight: '15px'}}>
					<Button color="primary" style={{width: '100%'}} onClick={()=>{this.setState({ modal: this.state.modal + 1 })}} disabled={saving}>{(input)?"Data Tersimpan":"Simpan"}</Button>
				</div>
				<div style={{flexBasis: '25%', paddingRight: '15px'}}>
					<Button color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this, false)} disabled={!saving && !input}>Print BM</Button>
				</div>
				<div style={{flexBasis: '25%', paddingRight: '15px'}}>
					<ModalPrint field={_.omit(field, _.keys(_.mapKeys(header.category.detail, 'field.name'))) || []} onSubmit={this.onPrintClick.bind(this)} disabled={!saving && !input} />
				</div>
			</div>
		);
	}

	updateCode() {
		const { header: { category } } = this.state;
		let { count } = this.state;

		field.code.value = `${category.code}${_.padStart(++count, 4, '0')}`;
		return field.code.value;
	}

	onComponentDidUpdate() {
		return { 
			code: field.code.value
		};
	}

	render() {
		const 
			{ name, slug, edit, remove, handleSubmit } = this.props,
			{ header, data, input, print, printField, skip, initialized, fetching, modal, label } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && fetching && 
					<div className="row" style={{paddingTop: '20px'}}>
						<div className="col-12 text-center">
							<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
							</div>
					</div> }
				{ header && !input && this.updateCode() && <ModalAdd slug={slug} field={field} onAddSubmit={this.onAddSubmit.bind(this)} labelCancel="Selesai" onComponentDidUpdate={this.onComponentDidUpdate()} except={['expired']}/> }
				{ header && <Table id="code" data={data} slug={slug} field={field} keys={keys} edit={!input && edit} remove={!input && remove} onEditSubmit={this.onEditSubmit.bind(this)} onRemoveSubmit={this.onRemoveSubmit.bind(this)} footer={[ _.size(keys) - 3, 1, 0, 0 ]} /> }
				{ !_.isEmpty(data) && this.renderFooter()}
				<Modal title="Simpan Data" modal={modal} onButtonClick={this.onSaveClick.bind(this)}>
					<h2 className="text-center">Apakah anda yakin data sudah benar?</h2>
				</Modal>
				{ label?<TnJ109/>:<A5/> }
				{ label && <Barcode data={print} skip={skip} field={printField} /> }
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataInput);
