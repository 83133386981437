import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import moment from 'moment';
import _ from 'lodash';

import { getFromUrl, postFromUrl, putFromUrl, deleteFromUrl, mapField } from '../../actions';
import { ModalAdd } from '../modal';
import { FieldItem, Table } from '../common';

import { A5 } from '../style';
import { initialize } from '../../fields';

class DataTableHeader extends Component {
	constructor(props) {
		super(props);
		const { slug, session, ...otherProps} = props;
		const { header, model, field, where, action } = initialize(slug, session, otherProps);

		this.state = { session, header, model, field, where, action, props: null, data: [], initialized: false, fetching: true };
	}

	componentDidMount() {
		const { field } = this.state;

		Promise.all([
			this.resetDate(),
			..._.map(field, (v, i) => !_.includes(i, 'keys') && mapField(v, this.updateField.bind(this, i)).then((data) => {
				this.setState({ field: { ...this.state.field, [i]: _.mapKeys(data, 'name')} });
			}))
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	updateField(index, column, data) {
		const { field } = this.state;
		const { change } = this.props;
		
		change(column, null);
		this.setState({ field: { ...field, [index]: {	..._.mapValues(field[index], (v) => _.omit(v, 'value')), [column]: { ...field[index][column], option: data, value: null } } } });
	}

	onResetClick() {
		const //{ reset } = this.props,
			props = null, data = [];

		// reset();
		// this.resetDate();
		this.setState({ props, data });
	}

	resetDate() {
		const { change } = this.props;
		const { field:{ header }} = this.state;

		const now = moment().format('YYYY-MM-DD');

		_.each(header, (v, i) => {
			v.type === 'date' && change(i, now);
		});
	}

	onSubmit(props) {
		const { valid } = this.props;
		const { model:{ url, ...model }, where } = this.state;

		if(valid) {
			const params = _.merge(_.cloneDeep(model), url?
				_.omitBy(_.cloneDeep(props), _.isNull):
				{ props: _.map(_.omitBy(_.cloneDeep(props), _.isNull), where) });
			this.setState({ fetching: true });
			return getFromUrl(url || `get`, params).then(({data:{ data }}) => {
				this.setState({ props, data, fetching: false });
			});
		}
	}

	onAddSubmit(props) {
		const { action:{ add } } = this.state;

		const params = { ...add.props, ...props };
		return postFromUrl(add.url, params).then(({data:{ data }}) => {
			this.setState({ data: _.concat(this.state.data, data) });
		});
	}

	onEditSubmit(props, id) {
		const { action:{ edit }, data } = this.state;

		const params = { ...edit.props, ...props };
		return putFromUrl(_.replace(edit.url, ':id', data[id].id), params).then(({data:{ data }}) => {
			const edited = _.cloneDeep(this.state.data);
			edited[id] = { ...edited[id], ...data };
			this.setState({ data: edited });
		});
	}

	onRemoveSubmit(id) {
		const { action:{ remove }, data } = this.state;

		const pick = _.mapValues(_.mapKeys(remove.pick !== undefined?remove.pick:['id'], (v) => v), (v) => data[id][v]);
		const params = { ...remove.props, ...pick };
		return deleteFromUrl(remove.url?_.replace(remove.url, ':id', data[id].id):'delete', params).then(({data:{ data }}) => {
			const removed = _.cloneDeep(this.state.data);
			_.pullAt(removed, [id]);
			this.setState({ data: removed });
		});
	}

	onPrintClick() {
		const table = this.refs.table && this.refs.table.refs.datatable && this.refs.table.refs.datatable.table;
		const len = table && table.page.len();
		Promise.all([
			table && table.page.len(-1).draw()
		]).then(() => {
			window.print();
		}).then(() => {
			table && table.page.len(len).draw();
		});
	}

	renderHeader() {
		const { props, field:{ header }, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container row">
				{_.map(_.cloneDeep(header), ({validate, className, ...field}, index) => {
					field.disabled = !_.isNull(props) || field.disabled;
					const empty = field.type === 'select' && _.isEmpty(field.option);
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={`${className || "col-12 col-md"} ${props && props[index]?'':'d-print-none'} ${empty?'d-none':''}`} />
				})}
				<div className="d-print-none col-12" style={{paddingTop: '8px'}}>
					<div className="row d-flex justify-content-end">
						<div className="col col-sm-3">
							{ !props && <Button type="submit" color="primary" style={{ width:'100%' }} disabled={submitting}>Cari</Button>}
							{ props && <Button type="reset" color="danger" style={{ width:'100%' }} onClick={this.onResetClick.bind(this)}>Reset</Button>}
						</div>
						<div className="col col-sm-3 d-none d-sm-block">
							<Button type="reset" color="primary" style={{ width:'100%' }} onClick={this.onPrintClick.bind(this)} disabled={!props}>Print</Button>
						</div>
					</div>
				</div>
			</div>
	}

	render() {
		const 
			{ name, handleSubmit, slug, add, edit, remove, submenu } = this.props,
			{ session, header, data, field:{ field, keys }, action, initialized, fetching } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && (add === 1 && action && !_.isEmpty(action.add)) && <ModalAdd field={_.cloneDeep(field)} fetching={fetching} onAddSubmit={this.onAddSubmit.bind(this)} close /> }
				{ initialized && _.isFunction(header) && header(data, session) }
				{ initialized && <Table ref="table" slug={slug} data={data} field={_.cloneDeep(field)} keys={keys} edit={(action && !_.isEmpty(action.edit))?edit:0} remove={(action && !_.isEmpty(action.remove))?remove:0} submenu={submenu} fetching={fetching} onEditSubmit={this.onEditSubmit.bind(this)} onRemoveSubmit={this.onRemoveSubmit.bind(this)} advanced /> }
				<A5 />
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataTableHeader);
