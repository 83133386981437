import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'muicss/react';
import moment from 'moment';
import _ from 'lodash';

import { getFromUrl, mapField } from '../../actions';
import { FieldItem, Table } from '../common';

import { A5 } from '../style';
import { customerHistoryField } from '../../fields';

class DataTransactionHistory extends Component {
	constructor(props) {
		super(props);
		const headerField = _.cloneDeep(customerHistoryField.header);
		const keysRecipe = _.cloneDeep(customerHistoryField.recipe);
		const keysTransaction = _.cloneDeep(customerHistoryField.transaction);

		this.state = { header: null, headerField, keysRecipe, keysTransaction, transaction: [], recipe: [], option: [], initialized: false, fetching: true };
	}

	componentDidMount() {
		const { headerField } = this.state;
		const { match:{ params: { id } } } = this.props;

		const date_from = Date.now() - (7 * 24 * 60 * 60 * 1000);
		const params = {
			customer_id: Number(id),
			date_from: moment(date_from).format('YYYY-MM-DD')
		};

		Promise.all([
			!_.isNaN(Number(id)) && this.initData(params),
			this.resetDate(),
			mapField(headerField).then((data) => {
				this.setState({ headerField: _.mapKeys(data, 'name') });
			})
		]).then(() => {
			this.setState({ fetching: false, initialized: true });
		});
	}

	initData(params) {
		const { change } = this.props;

		return this.onSubmit(params, false).then(() => {
			_.each(params, (v, i) => {
				change(i, v);
			});
		});
	}

	onResetClick() {
		const
			// { change, untouch } = this.props,
			header = null, transaction = [], recipe = [];

		// change('customer_id', '');
		// untouch('customer_id');
		// this.resetDate();
		this.setState({ header, transaction, recipe });
	}

	resetDate() {
		const { change } = this.props;

		const now = Date.now();
		change('date_from', moment(now).format('YYYY-MM-DD'));
		change('date_to', moment(now).format('YYYY-MM-DD'));
	}

	onSubmit(props, init = true) {
		const { valid } = this.props;

		const params = { ...props };
		init && this.setState({ fetching: true });
		return valid && getFromUrl(`transaction`, params).then(({data:{ data }}) => {
			const transaction = _.concat(..._.map(data, 'detail'));
			const recipe = _.concat(..._.map(data, 'recipe'));
			this.setState({ header: params, transaction, recipe });
			init && this.setState({ fetching: false });
		});
	}

	onPrintClick() {

		window.print();
	}

	renderHeader() {
		const { header, headerField, initialized } = this.state;
		const { submitting } = this.props;

		return !initialized?
			<div className="row">
				<div className="col-12 text-center">
					<i className="fa fa-spinner fa-pulse" aria-hidden="true"></i> &nbsp; MEMUAT ...
					</div>
			</div>:
			<div className="input-container">
				{_.map(_.cloneDeep(headerField), ({validate, className, ...field}, index) => {
					field.disabled = !_.isEmpty(header) || field.disabled;
					return <Field key={index} name={index} component={FieldItem} field={field} validate={validate} className={`${className || "col-12 col-md"} ${header && header[index]?'':'d-print-none'}`} />
				})}
				<div className="d-print-none col" style={{paddingTop: '8px'}}>
					{ !header && <Button type="submit" color="primary" style={{width: '100%'}} disabled={submitting}>Cari</Button>}
					{ header && <Button type="reset" color="danger" style={{width: '100%'}} onClick={this.onResetClick.bind(this)}>Reset</Button>}
				</div>
				<div className="d-print-none col" style={{paddingTop: '8px'}}>
					<Button type="reset" color="primary" style={{width: '100%'}} onClick={this.onPrintClick.bind(this)} disabled={!header}>Print</Button>
				</div>
			</div>
	}

	render() {
		const
			{ name, handleSubmit } = this.props,
			{ recipe, transaction, keysRecipe, keysTransaction, initialized, fetching } = this.state;

		return (
			<div className="container-fluid">
				<h3 className="text-center">{name}</h3>
				<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
					{this.renderHeader()}
				</form>
				{ initialized && <div className="row" style={{marginTop: '10px'}}>
					<h4 className="col-9">Data Resep</h4>
				</div> }
				{ initialized && <Table data={recipe} keys={keysRecipe} fetching={fetching} /> }
				{ initialized && <div className="row" style={{marginTop: '20px'}}>
					<h4 className="col-9">Data Pembelian</h4>
					<h4 className="col-3">Total Pembelian: Rp {_.parseInt(_.sumBy(transaction, 'deal')).toLocaleString('id')}</h4>
				</div> }
				{ initialized && <Table data={transaction} keys={keysTransaction} fetching={fetching} /> }
				<A5 />
			</div>
		);
	}
}

export default reduxForm({
	form: 'headerForm'
})(DataTransactionHistory);
