import React from 'react';
import { change } from 'redux-form';
import { Input } from 'muicss/react';
import moment from 'moment';
import _ from 'lodash';

import { putFromUrl } from '../actions';

import { Transaction, Prescription, Invoice, Warranty } from '../components/data/profile/data_content';

export const required = value => value === 0 || value ? undefined : 'Harus diisi';
export const minValue = min => value => value && Number(value) < min ? `Harus ${min} atau lebih` : undefined;
export const maxValue = max => value => value && Number(value) > max ? `Harus ${max} atau kurang` : undefined;
export const length = size => value => value && value.length !== size ? `Harus ${size} karakter` : undefined;
export const minLength = min => value => value && value.length < min ? `Harus ${min} karakter atau lebih` : undefined;
export const maxLength = max => value => value && value.length > max ? `Harus ${max} karakter atau kurang` : undefined;
export const character = value => value && !isNaN(value) ? 'Harus karakter' : undefined;
export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?'Email address tidak valid' : undefined;
export const checkPriceCode = code => value => {
	let err = undefined;
	_.each(value, (v) => {
		if(isNaN(v) && !code[_.toUpper(v)]) {
			err = "Kode harga tidak cocok";
		}
	});
	return err;
};
export const renderLocale = (data, type) => type === 'display'?data.toLocaleString('id'):data;
export const renderDate = (data, type) => type === 'display'?moment(data).format("YYYY-MM-DD"):data;
export const renderDateTime = (data, type) => type === 'display'?moment(data).format("YYYY-MM-DD HH:mm"):data;
export const warehouse_id = _.parseInt(process.env.REACT_APP_WAREHOUSEID);
export const orderKind = [
	{value: 'SINGLE VISION', label: 'SINGLE VISION'},
	{value: 'SINGLE VISION PHOTOCHROMIC', label: 'SINGLE VISION PHOTOCHROMIC'},
	{value: 'SINGLE VISION TRANSITIONS', label: 'SINGLE VISION TRANSITIONS'},
	{value: 'BIFOKAL', label: 'BIFOKAL'},
	{value: 'BIFOKAL PHOTOCHROMIC', label: 'BIFOKAL PHOTOCHROMIC'},
	{value: 'BIFOKAL TRANSITIONS', label: 'BIFOKAL TRANSITIONS'},
	{value: 'BIFOKAL FLAPTOP', label: 'BIFOKAL FLAPTOP'},
	{value: 'BIFOKAL KRYPTOP', label: 'BIFOKAL KRYPTOP'},
	{value: 'PROGRESSIVE', label: 'PROGRESSIVE'},
	{value: 'PROGRESSIVE PHOTOCHROMIC', label: 'PROGRESSIVE PHOTOCHROMIC'},
	{value: 'PROGRESSIVE TRANSITIONS', label: 'PROGRESSIVE TRANSITIONS'}
];

export const loginField = {
	username: {
		type: 'text',
		label: 'Username',
		validate: required
	},
	password: {
		type: 'password',
		label: 'Password',
		validate: required
	}
}

export const profile = {
	data: {
		code: {
			type: 'text',
			label: 'Kode Customer',
			readOnly: true
		},
		name: {
			type: 'text',
			label: 'Nama Customer',
		},
		email: {
			type: 'email',
			label: 'Email Customer',
		},
		phone: {
			type: 'text',
			label: 'Telp Customer',
		},
		address: {
			type: 'textarea',
			label: 'Alamat Customer',
		},
	},
	changePasswordField: {
		old_password: {
			type: 'password',
			label: 'Password Lama',
			autoComplete: 'new-password',
			validate: required
		},
		new_password: {
			type: 'password',
			label: 'Password Baru',
			autoComplete: 'new-password',
			validate: [required, minLength(6)]
		},
		confirm_password: {
			type: 'password',
			label: 'Konfirmasi Password Baru',
			autoComplete: 'new-password',
			validate: [required, minLength(6)]
		}
	},
}

const paginate = (data, { items, page }) => {
	const start = items * (page - 1);
	const end = start + items;
	// return _.size(data) > items * page ? _.slice(data, start, end):[];
	return _.slice(data, start, end);
}

export const mobileField = {
	activity: {
		date_from: {
			type: 'date',
			label: 'Tanggal Dari',
			className: 'col-6'
		},
		date_to: {
			type: 'date',
			label: 'Tanggal Ke',
			className: 'col-6'
		},
		store_id: {
			type: 'select',
			label: 'Toko',
			option: {
				table: 'store'
			},
			className: 'col-8',
			validate: required
		},
	},
	property: {
		store_id: {
			type: 'select',
			label: 'Toko',
			option: {
				table: 'store'
			},
			className: 'col-8'
		}
	}
}

const tableFields = {
	supplier: {
		field: {
			name: {
				type: 'text',
				label: 'Nama Supplier',
				validate: required
			},
			phone: {
				type: 'text',
				label: 'HP'
			},
			address: {
				type: 'textarea',
				label: 'Alamat'
			},
			pic: {
				type: 'text',
				label: 'Sales'
			},
			pic_phone: {
				type: 'text',
				label: 'HP Sales'
			},
			// discount: {
			// 	type: 'number',
			// 	label: 'Diskon Supplier (%)',
			// 	min: 0, max: 100,
			// 	validate: [ required, minValue(0), maxValue(100) ]
			// },
			}
	},
	category: {
		field: {
			name: {
				type: 'text',
				label: 'Nama Kategori',
				validate: required
			},
			code: {
				type: 'text',
				label: 'Kode',
				validate: [ required, length(3) ]
			},
			detail_id: {
				type: 'checkbox',
				label: 'Detail Barang',
				option: {
					table: 'detail'
				}
			},
		}
	},
	profit: {
		field: {
			name: {
				type: 'text',
				label: 'Nama Kategori Profit',
				validate: required
			},
			type: {
				type: 'select',
				label: 'Tipe Profit',
				option: [
					{value: '+', label: 'Angka'},
					{value: '%', label: 'Persentase'},
				],
				validate: required
			},
			profit: {
				type: 'number',
				label: 'Nilai Profit',
				min: 0,
				validate: required
			},
			max_discount: {
				type: 'number',
				label: 'Maks Diskon (%)',
				min: 0,
				max: 100,
				validate: required
			},
		}
	},
	payment: {
		field: {
			name: {
				type: 'text',
				label: 'Nama Cara Pembayaran',
				validate: required
			},
			bank_id: {
				type: 'checkbox',
				label: 'Daftar Bank',
				option: {
					table: 'bank'
				}
			},
		}
	},
	bank: {
		field: {
			name: {
				type: 'text',
				label: 'Nama Bank',
				validate: required
			},
		}
	},
	promo: {
		field: {
			code: {
				type: 'text',
				label: 'Kode Promo',
				validate: required
			},
			name: {
				type: 'text',
				label: 'Nama Promo',
				validate: required
			},
		}
	},
	'price-code': {
		field: {
			number: {
				type: 'number',
				label: 'Angka (0 - 9)',
				min: 0, max: 9,
				validate: [required, minValue(0), maxValue(9)],
				// style: { flexBasis: '100%', padding: '0 15px' }
			},
			character: {
				type: 'text',
				label: 'Kode (1 karakter)',
				validate: [required, maxLength(1), character],
				// style: { flexBasis: '100%', padding: '0 15px' }
			},
		},
		keys: {
			number: {
				title: 'Angka (0 - 9)',
				render: renderLocale
			},
			character: 'Kode (1 karakter)',
		}
	},
	store: {
		field: {
			code: {
				type: 'hidden',
				label: 'ID Cabang'
			},
			name: {
				type: 'text',
				label: 'Nama Cabang',
				validate: required,
				// style: { flexBasis: '100%', padding: '0 15px' }
			},
			phone: {
				type: 'text',
				label: 'HP',
			},
			pic: {
				type: 'text',
				label: 'PIC',
			},
			city_id: {
				type: 'select',
				label: 'Kota',
				option: {
					table: 'city'
				},
				matchPos: 'any'
			},
			address: {
				type: 'textarea',
				label: 'Alamat'
			},
		}
	},
	sales: {
		field: {
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				validate: required
			},
			name: {
				type: 'text',
				label: 'Nama Sales',
				validate: required
			},
			phone: {
				type: 'text',
				label: 'Phone',
			},
			gender: {
				type: 'select',
				label: 'Jenis Kelamin',
				option: [
					{value: 'LAKI-LAKI', label: 'LAKI-LAKI'},
					{value: 'PEREMPUAN', label: 'PEREMPUAN'}
				]
			},
			birthdate: {
				type: 'date',
				label: 'Tanggal Lahir',
			},
			address: {
				type: 'textarea',
				label: 'Address',
			},
			family_status: {
				type: 'select',
				label: 'Status Pribadi',
				option: [
					{value: 'BELUM BERKELUARGA', label: 'BELUM BERKELUARGA'},
					{value: 'BERKELUARGA', label: 'BERKELUARGA'}
				]
			},
			education: {
				type: 'select',
				label: 'Status Pendidikan',
				option: [
					{value: 'SD', label: 'SD'},
					{value: 'SMP', label: 'SMP'},
					{value: 'SMA', label: 'SMA'},
					{value: 'S1', label: 'S1'},
					{value: 'RO', label: 'RO'}
				]
			},
			join_date: {
				type: 'date',
				label: 'Tanggal Bergabung',
			},
			employment_status: {
				type: 'select',
				label: 'Status Karyawan',
				option: [
					{value: 'TRAINING', label: 'TRAINING'},
					{value: 'KONTRAK', label: 'KONTRAK'},
					{value: 'TETAP', label: 'TETAP'}
				]
			},
			information: {
				type: 'textarea',
				label: 'Informasi',
			},
			position_id: {
				type: 'checkbox',
				label: 'Jabatan',
				option: {
					table: 'position'
				}
			},
		},
		keys: {
			store_name: 'Cabang',
			name: 'Nama Sales',
			phone: 'No Telp',
			position_name: 'Jabatan'
		}
	},
	admin: {
		field: {
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				validate: required
			},
			usergroup_id: {
				type: 'select',
				label: 'Tipe Admin',
				option: {
					table: 'usergroup',
					0: ['id', '>', process.env.REACT_APP_SUPERADMIN],
					1: ['id', '<>', process.env.REACT_APP_CUSTOMER],
				},
				validate: required
			},
			name: {
				type: 'text',
				label: 'Nama Admin',
				validate: required
			},
			username: {
				type: 'text',
				label: 'Username',
				autoComplete: 'new-username',
				validate: required
			},
			password: {
				type: 'password',
				label: 'Password',
				autoComplete: 'new-password',
				hidden: true,
				validate: required
			},
		}
	},
	customer: {
		header: {
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store',
				},
				className: 'col-12 col-md-3',
			},
			province_id: {
				type: 'select',
				label: 'Provinsi',
				option: {
					table: 'province',
				},
				onChange: ['city_id'],
				className: 'col-12 col-md-3',
			},
			city_id: {
				type: 'select',
				label: 'Kota',
				option: {
					table: 'city',
					province_id: 0
				},
				className: 'col-12 col-md-3',
			},
			name: {
				type: 'text',
				label: 'Nama',
				className: 'col-12 col-md-3',
			},
			phone: {
				type: 'text',
				label: 'HP',
				className: 'col-12 col-md-3',
			},
		},
		field: {
			code: {
				type: 'hidden',
				label: 'ID Pelanggan'
			},
			type: {
				type: 'select',
				label: 'Tipe Pelanggan',
				option: [
					{value: 'PRIBADI', label: 'PRIBADI'},
					{value: 'PERUSAHAAN', label: 'PERUSAHAAN'}
				]
			},
			name: {
				type: 'text',
				label: 'Nama Pelanggan',
				validate: required
			},
			email: {
				type: 'text',
				label: 'Email',
				validate: email
			},
			phone: {
				type: 'text',
				label: 'HP',
			},
			status: {
				type: 'text',
				label: 'Pekerjaan',
			},
			gender: {
				type: 'select',
				label: 'Jenis Kelamin',
				option: [
					{value: 'LAKI-LAKI', label: 'LAKI-LAKI'},
					{value: 'PEREMPUAN', label: 'PEREMPUAN'}
				]
			},
			birthdate: {
				type: 'date',
				label: 'Tanggal Lahir',
			},
			city_id: {
				type: 'select',
				label: 'Kota',
				option: {
					table: 'city'
				},
				matchPos: 'any'
			},
			address: {
				type: 'textarea',
				label: 'Alamat',
			},
		},
		keys: {
			code: 'ID Pelanggan',
			type: 'Tipe Pelanggan',
			name: 'Nama Pelanggan',
			email: 'Email',
			phone: 'HP',
			address: {
				title: 'Alamat',
				width: '25%'
			},
		}
	},
	'invoice-note': {
		field: {
			note: {
				type: 'textarea',
				label: 'Note',
				validate: required
			},
			pos: {
				type: 'number',
				label: 'Position',
				validate: required
			},
		}
	},
	'warranty-note': {
		field: {
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store',
				},
				validate: required
			},
			note: {
				type: 'textarea',
				label: 'Note',
				validate: required
			},
			pos: {
				type: 'number',
				label: 'Position',
				validate: required
			},
		}
	},
	warranty: {
		field: {
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store',
				},
				validate: required
			},
			value: {
				type: 'number',
				label: 'Lama Garansi (Bulan)',
				validate: required
			},
		}
	},
	shift: {
		field: {
			name: {
				type: 'text',
				label: 'Nama',
				validate: required
			},
			start: {
				type: 'time',
				label: 'Jam Mulai',
				validate: required,
				value: '08:00'
			},
			end: {
				type: 'time',
				label: 'Jam Berakhir',
				validate: required,
				value: '08:00'
			},
		}
	},
	position: {
		field: {
			name: {
				type: 'text',
				label: 'Nama',
				validate: required
			},
		}
	},
	cost: {
		field: {
			name: {
				type: 'text',
				label: 'Nama',
				validate: required
			},
		}
	},
	code: {
		field: {
			name: {
				type: 'text',
				label: 'Nama Kode',
				validate: required
			},
			code: {
				type: 'text',
				label: 'Kode (Bisa kosong)',
			},
			max_discount: {
				type: 'number',
				label: 'Max Discount (%)',
				min: 0, max: 100,
				validate: [ required, minValue(0), maxValue(100) ]
			},
		},
		keys: {
			name: 'Nama Kode',
			code: 'Kode',
			max_discount: 'Max Discount (%)',
		}
	},
}

export const subtableFields = {
	brand: {
		name: {
			type: 'text',
			label: 'Nama Merk',
			validate: required
		},
	},
	'payment-detail': {
		bank_id: {
			type: 'select',
			label: 'Bank',
			option: {
				table: 'bank'
			},
			validate: required
		},
	},
}

export const inputField = {
	header: {
		code: {
			type: 'text',
			label: 'Kode',
			disabled: true,
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' }
		},
		invoice: {
			type: 'text',
			label: 'Invoice',
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' }
		},
		supplier_id: {
			type: 'select',
			label: 'Supplier',
			option: {
				table: 'supplier'
			},
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
		category_id: {
			type: 'select',
			label: 'Kategori Barang',
			option: {
				table: 'category'
			},
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
	},
	data: {
		code: {
			type: 'text',
			label: 'Kode',
			readOnly: true,
			// style: { flexBasis: '100%', padding: '0 15px' }
		},
		brand_id: {
			type: 'select',
			label: 'Merk',
			option: {
				table: 'brand',
				supplier_id: 0
			},
			validate: required,
			'data-hidden-edit': true
		},
		name: {
			type: 'history',
			label: 'Tipe',
			history: {
				table: 'product',
				select: 'name'
			},
			validate: required,
			'data-hidden-edit': true
		},
		quantity: {
			type: 'number',
			label: 'Jumlah',
			min: 0,
			validate: [ required, minValue(0) ],
			value: ''
		},
		buy_code: {
			type: 'text',
			label: 'Harga Beli Net/ Satuan',
			validate: [ required ],
			hidden: true,
			'data-hidden-edit': true
		},
		profit_id: {
			type: 'select',
			label: 'Kategori Profit',
			option: {
				table: 'profit'
			},
			hidden: true,
			validate: required,
			'data-hidden-edit': true
		},
		buy: {
			type: 'hidden',
			label: 'Harga Beli',
			hidden: true,
			'data-hidden-edit': true
		},
		sell: {
			type: 'text',
			label: 'Harga Jual',
			readOnly: true,
			value: 0,
			'data-hidden-edit': true
		},
		price_code: {
			type: 'text',
			label: 'Kode Harga'
		},
	},
}

export const inputSimpleField = {
	header: {
		code: {
			type: 'text',
			label: 'Kode',
			disabled: true,
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' }
		},
		invoice: {
			type: 'text',
			label: 'Invoice',
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' }
		},
		supplier_id: {
			type: 'select',
			label: 'Supplier',
			option: {
				table: 'supplier'
			},
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
		category_id: {
			type: 'select',
			label: 'Kategori Barang',
			option: {
				table: 'category'
			},
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
	},
	data: {
		code: {
			type: 'text',
			label: 'Kode',
			readOnly: true,
			// style: { flexBasis: '100%', padding: '0 15px' }
		},
		brand_id: {
			type: 'select',
			label: 'Merk',
			option: {
				table: 'brand',
				supplier_id: 0
			},
			validate: required,
			'data-hidden-edit': true
		},
		name: {
			type: 'history',
			label: 'Tipe',
			history: {
				table: 'product',
				select: 'name'
			},
			validate: required,
			'data-hidden-edit': true
		},
		quantity: {
			type: 'number',
			label: 'Jumlah',
			min: 0,
			validate: [ required, minValue(0) ],
			value: ''
		},
		buy: {
			type: 'hidden',
			label: 'Harga Beli',
			hidden: true,
			value: 0,
			'data-hidden-edit': true
		},
		sell: {
			type: 'number',
			label: 'Harga Jual',
			'data-hidden-edit': true,
			validate: [ required, minValue(0) ],
		},
		price_code: {
			type: 'text',
			label: 'Kode Harga'
		},
	},
}

export const inputSellField = {
	header: {
		supplier_id: {
			type: 'select',
			label: 'Supplier',
			option: {
				table: 'supplier',
				join: [
					{ table: 'product', id: 'supplier.id', fk: 'product.supplier_id' },
				]
			},
			onChange: ['brand_id', 'name'],
			className: 'col-12 col-md-3',
			validate: required
		},
		brand_id: {
			type: 'select',
			label: 'Merk',
			option: {
				table: 'brand'
			},
			className: 'col-12 col-md-3',
			validate: required
		},
		category_id: {
			type: 'select',
			label: 'Kategori Produk',
			option: {
				table: 'category',
				join: [
					{ table: 'product', id: 'category.id', fk: 'product.category_id' },
				]
			},
			onChange: ['spherical', 'cylinder', 'addition'],
			className: 'col-12 col-md-3',
			validate: required
		},
		name: {
			type: 'select',
			label: 'Tipe',
			option: {
				table: 'product',
				id: 'name',
				supplier_id: 0
			},
			className: 'col-12 col-md-3',
			validate: required
		},
		color: {
			type: 'text',
			label: 'Color',
			className: 'col-12 col-md-3'
		},
		spherical: {
			type: 'select',
			label: 'Spherical',
			option: {
				table: 'detail',
				name: 'spherical',
				category_id: 0
			},
			className: 'col-12 col-md-3'
		},
		cylinder: {
			type: 'select',
			label: 'Cylinder',
			option: {
				table: 'detail',
				name: 'cylinder',
				category_id: 0
			},
			className: 'col-12 col-md-3'
		},
		addition: {
			type: 'select',
			label: 'Addition',
			option: {
				table: 'detail',
				name: 'addition',
				category_id: 0
			},
			className: 'col-12 col-md-3'
		},
		sell: {
			type: 'number',
			label: 'Harga Jual',
			className: 'col-12 col-md-3',
			validate: [ required, minValue(0) ],
		},
	},
	keys: {
		category_name: 'Kategori',
		supplier_name: 'Supplier',
		brand_name: 'Merk',
		code: 'Kode',
		name: 'Tipe',
		detail_string: 'Warna / Ukuran',
		sell: {
			title: 'Harga Jual',
			render: renderLocale
		},
	},
}

export const opnameField = {
	header: {
		code: {
			type: 'text',
			label: 'Kode',
			disabled: true,
			style: { flexBasis: '25%', flexGrow: '1', paddingRight: '15px' }
		},
		store_id: {
			type: 'select',
			label: 'Cabang',
			option: {
				table: 'store'
			},
			style: { flexBasis: '25%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
	},
	data: {
		product_id: {
			type: 'select',
			label: 'Produk',
			validate: required
		},
		stock: {
			type: 'number',
			label: 'Stok Update',
			validate: [ required, minValue(0) ],
			min: 0,
			value: 0,
			disabled: true
		},
		quantity: {
			type: 'number',
			label: 'Stok Opname (Hasil Stok Audit)',
			validate: [ required, minValue(0) ],
			min: 0,
			value: 0
		},
		reason: {
			type: 'textarea',
			label: 'Alasan Audit'
		},
	},
	keys: {
		label: 'Produk',
		stock: 'Stok Update',
		quantity: 'Stok Opname',
		reason: 'Alasan Audit'
	}
}

export const substockField = {
	header: {
		code: {
			label: 'Kode',
		},
		brand_name: {
			label: 'Merk',
		},
		name: {
			label: 'Tipe',
		},
		stock: {
			label: 'Stok',
		},
		sell_locale: {
			label: 'Harga',
		},
	},
	data: {
		created_at: {
		 title: 'Tanggal',
		},
		quantity:	'Jumlah',
		status:	'Deskripsi',
	}
}

export const debitField = {
	header: {
		// store_id: {
		// 	type: 'hidden',
		// 	label: 'Toko',
		// 	style: { flexBasis: '100%', flexGrow: '1', paddingRight: '15px' },
		// 	hidden: true
		// },
		invoice: {
			type: 'text',
			label: 'Invoice',
			style: { flexBasis: '20%', flexGrow: '1', paddingRight: '15px' }
		},
		supplier_id: {
			type: 'select',
			label: 'Supplier',
			option: {
				table: 'supplier'
			},
			style: { flexBasis: '20%', flexGrow: '1', paddingRight: '15px' }
		},
		month: {
			type: 'select',
			label: 'Bulan Barang Masuk',
			option: [
				{value: '1', label: 'Januari'},
				{value: '2', label: 'Februari'},
				{value: '3', label: 'Maret'},
				{value: '4', label: 'April'},
				{value: '5', label: 'Mei'},
				{value: '6', label: 'Juni'},
				{value: '7', label: 'Juli'},
				{value: '8', label: 'Agustus'},
				{value: '9', label: 'September'},
				{value: '10', label: 'Oktober'},
				{value: '11', label: 'November'},
				{value: '12', label: 'Desember'},
			],
			style: { flexBasis: '20%', flexGrow: '1', paddingRight: '15px' }
		},
		date_from: {
			type: 'date',
			label: 'Tanggal Dari',
			style: { flexBasis: '20%', flexGrow: '1', paddingRight: '15px' }
		},
		date_to: {
			type: 'date',
			label: 'Tanggal Ke',
			style: { flexBasis: '20%', flexGrow: '1', paddingRight: '15px' }
		},
	},
	data: {
		code: 'Kode',
		supplier_name: 'Supplier',
		invoice: 'Invoice',
		total: 'Total Hutang',
		created_at: {
			title: 'Tanggal Masuk',
			className: 'd-print-none'
		},
		duration: 'Durasi Hutang',
		status: 'Status',
	},
}

export const subdebitField = {
	header: {
		code: {
			label: 'Kode',
		},
		supplier_name: {
			label: 'Supplier',
		},
		invoice: {
			label: 'Invoice',
		},
		created_at: {
			label: 'Tanggal Input',
		},
		total: {
			label: 'Total Hutang'
		},
		duration: {
			label: 'Durasi Hutang',
		},
		status: {
			label: 'Status'
		}
	},
	data: {
		product_code: 'Kode Produk',
		product_name: 'Tipe Produk',
		brand_name: 'Merk',
		category_name: 'Kategori',
		quantity: 'Jumlah',
		price: 'Harga Beli',
	},
	add: {
		payment: {
			type: 'select',
			label: 'Cara Pembayaran',
			option: [
				{value: 'Tunai', label: 'Tunai'},
				{value: 'Transfer', label: 'Transfer'},
				{value: 'Giro', label: 'Giro'},
				{value: 'Cek', label: 'Cek'},
			],
			validate: required
		},
		bank_id: {
			type: 'select',
			label: 'Bank',
			option: {
				table: 'bank'
			},
		},
		date: {
			type: 'date',
			label: 'Tanggal Bayar',
			validate: required
		},
		paid: {
			type: 'number',
			label: 'Total Bayar',
			min: 0,
			validate: [ required, minValue(0) ]
		},
	}
}

export const transferField = {
	header: {
		code: {
			type: 'text',
			label: 'Kode',
			disabled: true,
			style: { flexBasis: '25%', flexGrow: '1', paddingRight: '15px' }
		},
		admin_id: {
			type: 'select',
			label: 'Admin',
			option: {
				table: 'admin'
			},
			disabled: true,
			style: { flexBasis: '25%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
		transfer_to: {
			type: 'select',
			label: 'Cabang Tujuan',
			option: {
				table: 'store'
			},
			style: { flexBasis: '25%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
	},
	data: {
		product_id: {
			type: 'select',
			label: 'Produk',
			validate: required
		},
		quantity: {
			type: 'number',
			label: 'Jumlah',
			validate: [ minValue(1), required]
		},
	},
	keys: {
		label: 'Produk',
		brand_name: 'Merk',
		sell_locale: 'Harga Jual',
		quantity: 'Jumlah'
	}
}

export const returField = {
	header: {
		code: {
			type: 'text',
			label: 'Kode',
			disabled: true,
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' }
		},
		admin_id: {
			type: 'select',
			label: 'Admin',
			option: {
				table: 'admin'
			},
			disabled: true,
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
		store_id: {
			type: 'select',
			label: 'Cabang',
			option: {
				table: 'store'
			},
			disabled: true,
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' }
		},
		supplier_id: {
			type: 'select',
			label: 'Nama Supplier',
			option: {
				table: 'supplier'
			},
			style: { flexBasis: '15%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
		transfer_to: {
			type: 'select',
			label: 'Retur Ke',
			option: {
				table: 'store',
				0: [ 'id', warehouse_id ]
			},
			disabled: true,
			style: { flexBasis: '25%', flexGrow: '1', paddingRight: '15px' },
			validate: required
		},
	},
	data: {
		product_id: {
			type: 'select',
			label: 'Produk',
			validate: required
		},
		quantity: {
			type: 'number',
			label: 'Jumlah',
			validate: [ minValue(1), required]
		},
		reason: {
			type: 'textarea',
			label: 'Alasan Retur'
		},
	},
	keys: {
		label: 'Produk',
		brand_name: 'Merk',
		sell_locale: 'Harga Jual',
		quantity: 'Jumlah',
		reason: 'Alasan Retur'
	}
}

export const transactionField = {
	header: {
		// admin_id: {
		// 	type: 'select',
		// 	label: 'Admin',
		// 	option: {
		// 		table: 'admin',
		// 		0: ['id', '<>', '1'],
		// 		1: ['id', '<>', '2']
		// 	},
		// 	className: 'd-none',
		// 	// disabled: true,
		// 	validate: required
		// },
		sales_id: {
			type: 'select',
			label: 'Sales',
			option: {
				table: 'sales',
			},
			className: 'col-12',
			// disabled: true,
			// validate: required
		},
		code: {
			type: 'text',
			label: 'Invoice',
			readOnly: true,
			className: 'd-none d-sm-block col-12',
			// validate: required
		},
		customer_id: {
			type: 'async',
			label: 'Customer Terdaftar',
			option: {
				table: 'customer',
				select: ['customer.name', 'customer.phone'],
				name: 'concat(code, " - ", name, " - ", phone, " - ", address)'
			},
			className: 'col-12',
		},
		name: {
			type: 'text',
			label: 'Customer Baru',
			validate: required,
			className: 'col-6',
			autoComplete: 'new-address'
		},
		phone: {
			type: 'text',
			label: 'HP Customer Baru',
			validate: required,
			className: 'col-6',
			autoComplete: 'new-address'
		},
		address: {
			type: 'textarea',
			label: 'Alamat Customer Baru',
			className: 'col-12',
			autoComplete: 'new-address'
		},
	},
	data: {
		product_id: {
			type: 'select',
			label: 'Kata Kunci Produk',
			filterOption: (option, filter) => {
				switch(filter) {
					case "": return true;
					default:
						const label = _.toLower(option.label);
						return _.every(_.words(filter, /[-+.\w]+/g), (v) => _.includes(label, v));
				}
			},
			validate: required,
			className: 'col-sm col-12'
		},
		brand_id: {
			type: 'hidden',
			label: 'Merk'
		},
		name: {
			type: 'hidden',
			label: 'Type Produk'
		},
		axis: {
			type: 'hidden',
			label: 'Axis',
			// placeholder: 'Axis',
			values: '0',
			validate: required,
			className: 'col-sm-1 col-6',
		},
		pd: {
			type: 'hidden',
			label: 'PD',
			// placeholder: 'PD',
			values: '50',
			validate: required,
			className: 'col-sm-1 col-6',
		},
		eye: {
			type: 'hidden',
			label: 'Mata',
			option: [
				{value: 'KANAN', label: 'KANAN'},
				{value: 'KIRI', label: 'KIRI'},
				{value: 'BOTH', label: 'KANAN + KIRI'}
			],
			validate: required,
			className: 'col-sm-2 col',
		},
		sell_locale: {
			type: 'hidden',
			label: 'Harga Satuan'
		},
		quantity: {
			type: 'number',
			label: 'Jumlah',
			className: 'col-sm-2 col',
			validate: [ minValue(1), required]
		},
		total_locale: {
			type: 'hidden',
			label: 'Harga Total'
		},
	},
	keys: {
		label: {
			title: 'Kata Kunci Produk',
		},
		brand_name: {
			title: 'Merk',
			className: 'd-none d-sm-table-cell'
		},
		// name: {
		// 	title: 'Type Produk',
		// 	className: 'd-none d-sm-table-cell'
		// },
		axis: {
			title: 'Axis',
			className: 'd-none d-sm-table-cell'
		},
		pd: {
			title: 'PD',
			className: 'd-none d-sm-table-cell'
		},
		eye: {
			title: 'Mata',
			className: 'd-none d-sm-table-cell'
		},
		sell_locale: {
			title: 'Harga Satuan',
			className: 'd-none d-sm-table-cell'
		},
		quantity: {
			title: 'Jumlah',
		},
		total_locale: {
			title: 'Harga Total',
		},
	},
	recipe: {
		eye: {
			title: 'Mata'
		},
		spherical: {
			title: 'Sph',
			className: 'd-none d-sm-table-cell'
		},
		cylinder: {
			title: 'Cyl',
			className: 'd-none d-sm-table-cell'
		},
		addition: {
			title: 'Add',
			className: 'd-none d-sm-table-cell'
		},
		axis: {
			title: 'Axis',
			className: 'd-none d-sm-table-cell'
		},
		pd: {
			title: 'PD',
			className: 'd-none d-sm-table-cell'
		},
	},
	order: {
		header: {
			brand_id: {
				type: 'select',
				label: 'Supplier / Merk',
				option: {
					table: 'brand',
					name: 'CONCAT(supplier.name, " / ", brand.name)',
					join: [
						{table: 'product', id: 'brand.id', fk: 'product.brand_id'},
						{table: 'supplier', id: 'supplier.id', fk: 'product.supplier_id'}
					],
					0: ['product.order_id', '<>', null]
				},
				className: 'col-12 col-sm-6 col-md-2'
			},
			kind: {
				type: 'select',
				label: 'Jenis Lensa',
				option: orderKind,
				className: 'col-12 col-sm-6 col-md-2'
			},
			name: {
				type: 'select',
				label: 'Type Lensa',
				option: {
					table: 'product',
					name: 'CONCAT(product.code, " - ", product.name)',
					select: ['CONCAT(supplier.name, " / ", brand.name) as brand_name', 'code', 'kind', 'product.name', 'sell', 'sell as sell_locale', 'sell as price'],
					join: [
						{ table: 'brand', id: 'brand.id', fk: 'product.brand_id' },
						{ table: 'supplier', id: 'supplier.id', fk: 'product.supplier_id' }
					],
					0: ['product.order_id', '<>', null],
					brand_id: 0,
					kind: 0
				},
				className: 'col-12 col-sm-8 col-md-6'
			},
			sell: {
				type: 'hidden',
				label: 'Harga Satuan',
				readOnly: true,
				style: { display: 'none' }
			},
			sell_locale: {
				type: 'text',
				label: 'Harga Satuan',
				readOnly: true,
				className: 'col-12 col-sm-4 col-md-2'
			},
		},
		body: {
			spherical: {
				type: 'select',
				label: 'Sph',
				placeholder: 'Sph',
				option: {
					table: 'detail',
					name: 'spherical'
				},
				clearable: false,
				validate: required,
				values: "0.00",
				className: 'col'
			},
			cylinder: {
				type: 'select',
				label: 'Cyl',
				placeholder: 'Cyl',
				option: {
					table: 'detail',
					name: 'cylinder'
				},
				clearable: false,
				validate: required,
				values: "0.00",
				className: 'col'
			},
			addition: {
				type: 'select',
				label: 'Add',
				placeholder: 'Add',
				option: {
					table: 'detail',
					name: 'addition'
				},
				clearable: false,
				validate: required,
				values: "0.00",
				className: 'col'
			},
			axis: {
				type: 'number',
				label: 'Axis',
				placeholder: 'Axis',
				validate: required,
				values: '0',
				className: 'col'
			},
			pd: {
				type: 'number',
				label: 'PD',
				placeholder: 'PD',
				validate: required,
				values: '50',
				className: 'col'
			},
		},
		keys: {
			code: {
				title: 'Kode',
			},
			brand_name: {
				title: 'Supplier / Merk Lensa',
				className: 'd-none d-sm-table-cell'
			},
			kind: {
				title: 'Jenis Lensa',
				className: 'd-none d-sm-table-cell'
			},
			name: {
				title: 'Type Lensa',
			},
			sell_locale: {
				title: 'Harga Satuan',
				className: 'd-none d-sm-table-cell'
			},
			eye: {
				title: 'Mata'
			},
			spherical: {
				title: 'Sph',
				className: 'd-none d-sm-table-cell'
			},
			cylinder: {
				title: 'Cyl',
				className: 'd-none d-sm-table-cell'
			},
			addition: {
				title: 'Add',
				className: 'd-none d-sm-table-cell'
			},
			axis: {
				title: 'Axis',
				className: 'd-none d-sm-table-cell'
			},
			pd: {
				title: 'PD',
				className: 'd-none d-sm-table-cell'
			},
		}
	},
	footer: {
		value: {
			type: 'number',
			label: 'Tetapkan Harga Deal / Potongan Harga / Diskon (%)'
		},
		type: {
			type: 'select',
			label: 'Tipe Diskon',
			placeholder: 'Pilih Tipe Diskon',
			options: [
				{value: 'DEAL', label: 'Harga Deal'},
				{value: 'REBATE', label: 'Potongan'},
				{value: 'DISCOUNT', label: 'Diskon (%)'}
			],
			clearable: false
		},
		total: {
			type: 'text',
			label: 'Total Harga',
		},
		sum: {
			type: 'text',
			label: 'Harga yang harus dibayar',
		},
	},
	payment: {
		total: {
			type: 'hidden',
			label: 'Total Pembayaran',
			value: 0,
			className: 'd-none'
		},
		unpaid: {
			type: 'text',
			label: 'Sisa Bayar',
			disabled: true,
			value: 0,
			className: 'col-12 col-sm-6'
		},
	},
	payment_detail: {
		// type: {
		// 	type: 'select',
		// 	label: 'Jenis Pembayaran',
		// 	option: [
		// 		{value: 'DP', label: 'DP'},
		// 		{value: 'LUNAS', label: 'LUNAS'}
		// 	],
		// 	validate: required,
		// 	style: { flexBasis: '100%' }
		// },
		payment_id: {
			type: 'select',
			label: 'Cara Pembayaran',
			option: {
				table: 'payment'
			},
			onChange: 'payment_detail_id',
			validate: required,
			className: 'col-6 col-sm-3'
		},
		payment_detail_id: {
			type: 'select',
			label: 'Bank',
			option: {
				table: 'payment_detail',
				name: 'bank.name',
				join: [
					{table: 'bank', id: 'bank.id', fk: 'payment_detail.bank_id'}
				],
				payment_id: 0
			},
			validate: required,
			className: 'col-6 col-sm-3'
		},
		total: {
			type: 'number',
			label: 'Total Pembayaran',
			min: 0,
			validate: [ required, minValue(0) ],
			className: 'col-12 col-sm-3'
		},
	},
}

export const transactionRepaymentField = {
	header: {
		code: {
			type: 'select',
			label: 'Invoice',
			option: {
				table: 'transaction',
				name: 'code',
				0: ['sum-paid', '>', '0'],
				1: ['cancel', null]
			},
			validate: required,
			group: 'left'
		},
		customer_code: {
			type: 'text',
			label: 'ID Pelanggan',
			readOnly: true,
			group: 'left'
		},
		customer_name: {
			type: 'text',
			label: 'Nama Pelanggan',
			readOnly: true,
			group: 'left'
		},
		sum: {
			type: 'text',
			label: 'Total Belanja',
			readOnly: true,
			group: 'middle'
		},
		paid: {
			type: 'text',
			label: 'Pembayaran',
			readOnly: true,
			group: 'middle'
		},
		unpaid: {
			type: 'text',
			label: 'Sisa Pembayaran',
			readOnly: true,
			group: 'middle'
		},
		transaction_date: {
			type: 'text',
			label: 'Tanggal Penjualan',
			readOnly: true,
			group: 'right'
		},
		payment_name: {
			type: 'text',
			label: 'Metode Pembayaran',
			readOnly: true,
			group: 'right'
		},
		admin_name: {
			type: 'text',
			label: 'Admin',
			readOnly: true,
			group: 'right'
		},
	},
	data: {
		code: 'Kode Barang',
		brand_name: 'Merk',
		name: 'Tipe Barang',
		kind: 'Jenis Barang',
		quantity: 'Jumlah',
		price_locale: 'Harga Satuan',
		discount_percent: 'Discount(%)',
		total_locale: 'Harga Total',
	},
	recipe: {
		name: 'Tipe Lensa',
		eye: 'Mata',
		spherical: 'Spherical',
		cylinder: 'Cylinder',
		addition: 'ADD',
		axis: 'Axis',
		// color: 'Warna',
		pd: 'PD',
	},
	footer: {
		total: {
			type: 'text',
			label: 'Sisa Pembayaran',
			readOnly: true,
		},
		payment_id: {
			type: 'select',
			label: 'Cara Pembayaran',
			option: {
				table: 'payment'
			},
			onChange: ['payment_detail_id'],
			validate: required,
		},
		paid: {
			type: 'number',
			label: 'Pelunasan',
			min: 0,
			// readOnly: true,
		},
		payment_detail_id: {
			type: 'select',
			label: 'Bank',
			option: {
				table: 'payment_detail',
				name: 'bank.name',
				join: [
					{table: 'bank', id: 'bank.id', fk: 'payment_detail.bank_id'}
				],
				payment_id: 0
			},
			validate: required,
		},
		unpaid: {
			type: 'text',
			label: 'Sisa',
			readOnly: true,
		},
		spelled: {
			type: 'text',
			label: 'Terbilang',
			readOnly: true,
			className: 'col-12'
		},
	},
}

export const transactionCancelField = {
	header: {
		store_id: {
			type: 'hidden',
			label: 'Cabang',
			group: 'left',
		},
		code: {
			type: 'async',
			label: 'Invoice',
			option: {
				table: 'transaction',
				name: 'code',
				1: ['cancel', null]
			},
			matchPos: 'any',
			validate: required,
			group: 'left'
		},
		customer_code: {
			type: 'text',
			label: 'ID Pelanggan',
			readOnly: true,
			group: 'left'
		},
		customer_name: {
			type: 'text',
			label: 'Nama Pelanggan',
			readOnly: true,
			group: 'left'
		},
		sum: {
			type: 'text',
			label: 'Total Belanja',
			readOnly: true,
			group: 'middle'
		},
		paid: {
			type: 'text',
			label: 'Pembayaran',
			readOnly: true,
			group: 'middle'
		},
		unpaid: {
			type: 'text',
			label: 'Sisa Pembayaran',
			readOnly: true,
			group: 'middle'
		},
		transaction_date: {
			type: 'text',
			label: 'Tanggal Penjualan',
			readOnly: true,
			group: 'right'
		},
		payment_name: {
			type: 'text',
			label: 'Metode Pembayaran',
			readOnly: true,
			group: 'right'
		},
		admin_name: {
			type: 'text',
			label: 'Admin',
			readOnly: true,
			group: 'right'
		},
	},
	data: {
		code: 'Kode Barang',
		brand_name: 'Merk',
		name: 'Tipe Barang',
		kind: 'Jenis Barang',
		quantity: 'Jumlah',
		price_locale: 'Harga Satuan',
		discount_percent: 'Discount(%)',
		total_locale: 'Harga Total',
	},
	recipe: {
		name: 'Tipe Lensa',
		eye: 'Mata',
		spherical: 'Spherical',
		cylinder: 'Cylinder',
		addition: 'ADD',
		axis: 'Axis',
		// color: 'Warna',
		pd: 'PD',
	},
	footer: {
		cancel: {
			type: 'textarea',
			label: 'Alasan Pembatalan',
			className: 'col-12',
			validate: [ required, maxLength(200) ]
		},
	},
}

export const transactionHistoryField = {
	header: {
		store_id: {},
		admin_id: {
			type: 'select',
			label: 'Admin',
			option: {
				table: 'admin',
				0: [ 'usergroup_id', '<>', '1' ],	// 1: Super Admin
				1: [ 'usergroup_id', '<>', '5' ]	// 5: Mobile Owner
			},
			// validate: required,
			style: {}
		},
		date_from: {
			type: 'date',
			label: 'Tanggal Dari',
			style: {}
		},
		date_to: {
			type: 'date',
			label: 'Tanggal Ke',
			style: {}
		},
		shift_id: {
			type: 'select',
			label: 'Shift',
			option: {
				table: 'shift'
			},
			style: {}
		}
	},
	data: {
		date: 'Tanggal',
		invoice: 'Nomor Invoice',
		customer_code: 'ID Pelanggan',
		code: 'Kode Barang',
		brand_name: 'Merk',
		name: 'Tipe Barang',
		kind: 'Jenis Barang',
		quantity: 'Jumlah',
		price_locale: 'Harga',
		discount_locale: 'Discount',
		total_locale: 'Total Harga',
	},
	unpaid: {
		date: 'Tanggal',
		code: 'Nomor Invoice',
		customer_code: 'ID Pelanggan',
		sum_locale: 'Total Belanja',
		paid_locale: 'Pembayaran',
		unpaid_locale: 'Sisa',
		payment_name: 'Metode Pembayaran',
	},
}

export const customerHistoryField = {
	header: {
		customer_id: {
			type: 'select',
			label: 'Pelanggan',
			option: {
				table: 'customer',
				name: 'concat(name, " / ", phone)'
			},
			matchPos: 'any',
			validate: required,
			style: {}
		},
		date_from: {
			type: 'date',
			label: 'Tanggal Dari',
			style: {}
		},
		date_to: {
			type: 'date',
			label: 'Tanggal Ke',
			style: {}
		},
	},
	recipe: {
		date: 'Tanggal',
		code: 'Kode Barang Lensa',
		spherical: 'Spherical',
		cylinder: 'Cylinder',
		addition: 'ADD',
		axis: 'Axis',
		pd: 'PD',
		// color: 'Warna',
		name: 'Tipe Lensa',
		kind: 'Jenis Lensa',
		eye: 'Mata',
		invoice: 'Kode Invoice',
	},
	transaction: {
		date: 'Tanggal',
		invoice: 'Nomor Invoice',
		// customer_code: 'ID Pelanggan',
		code: 'Kode Barang',
		brand_name: 'Merk',
		name: 'Tipe Barang',
		kind: 'Jenis Barang',
		quantity: 'Jumlah',
		price_locale: 'Harga',
		discount_locale: 'Discount',
		total_locale: 'Total Harga',
		note: 'Internal Note',
	},
}

export const customerDebitField = {
	header: {
		customer_id: {
			type: 'select',
			label: 'Customer',
			option: {
				table: 'customer',
				name: 'concat(name, " / ", phone)'
			},
			matchPos: 'any',
			style: {}
		},
		date_from: {
			type: 'date',
			label: 'Tanggal Dari',
			style: {}
		},
		date_to: {
			type: 'date',
			label: 'Tanggal Ke',
			style: {}
		},
	},
	data: {
		invoice: 'Nomor Invoice',
		customer_code: 'ID Pelanggan',
		customer_name: 'Nama Pelanggan',
		customer_phone: 'Telp Pelanggan',
		// sum_locale: 'Total Transaksi',
		// paid_locale: 'Total Bayar',
		unpaid_locale: 'Sisa Hutang',
		// admin_name: 'Admin / Karyawan',
		// payment_name: 'Cara Pembayaran',
		date: 'Tanggal Transaksi',
		duration: 'Durasi Hutang',
	},
}

export const reprintInvoiceField = {
	header: {
		code: {
			type: 'async',
			label: 'Invoice',
			option: {
				table: 'transaction',
				name: 'code',
				0: ['cancel', null],
				direction: 'DESC'
			},
			validate: required,
			group: 'left'
		},
		customer_code: {
			type: 'text',
			label: 'ID Pelanggan',
			readOnly: true,
			group: 'left'
		},
		customer_name: {
			type: 'text',
			label: 'Nama Pelanggan',
			readOnly: true,
			group: 'left'
		},
		sum: {
			type: 'text',
			label: 'Total Belanja',
			readOnly: true,
			group: 'middle'
		},
		paid: {
			type: 'text',
			label: 'Pembayaran',
			readOnly: true,
			group: 'middle'
		},
		unpaid: {
			type: 'text',
			label: 'Sisa Pembayaran',
			readOnly: true,
			group: 'middle'
		},
		transaction_date: {
			type: 'text',
			label: 'Tanggal Penjualan',
			readOnly: true,
			group: 'right'
		},
		payment_name: {
			type: 'text',
			label: 'Metode Pembayaran',
			readOnly: true,
			group: 'right'
		},
		admin_name: {
			type: 'text',
			label: 'Admin',
			readOnly: true,
			group: 'right'
		},
	},
	data: {
		code: 'Kode Barang',
		brand_name: 'Merk',
		name: 'Tipe Barang',
		kind: 'Jenis Barang',
		quantity: 'Jumlah',
		price_locale: 'Harga Satuan',
		discount_percent: 'Discount(%)',
		total_locale: 'Harga Total',
	},
	recipe: {
		name: 'Tipe Lensa',
		eye: 'Mata',
		spherical: 'Spherical',
		cylinder: 'Cylinder',
		addition: 'ADD',
		axis: 'Axis',
		// color: 'Warna',
		pd: 'PD',
	},
	footer: {
		total: {
			type: 'text',
			label: 'Sisa Pembayaran',
			readOnly: true,
		},
		payment_id: {
			type: 'select',
			label: 'Cara Pembayaran',
			option: {
				table: 'payment'
			},
			onChange: ['payment_detail_id'],
			validate: required,
		},
		paid: {
			type: 'text',
			label: 'Pelunasan',
			readOnly: true,
		},
		payment_detail_id: {
			type: 'select',
			label: 'Bank',
			option: {
				table: 'payment_detail',
				name: 'bank.name',
				join: [
					{table: 'bank', id: 'bank.id', fk: 'payment_detail.bank_id'}
				],
				payment_id: 0
			},
			validate: required,
		},
		unpaid: {
			type: 'text',
			label: 'Sisa',
			readOnly: true,
		},
		spelled: {
			type: 'text',
			label: 'Terbilang',
			readOnly: true,
			className: 'col-12'
		},
	},
}

export const reprintRecipeField = {
	header: {
		code: {
			type: 'async',
			label: 'Invoice',
			option: {
				table: 'transaction',
				name: 'CONCAT(transaction.code, " - ", customer.name, " / ", customer.phone)',
				join: [
					{ table: 'customer_recipe', id: 'customer_recipe.transaction_id', fk: 'transaction.id' },
					{ table: 'customer', id: 'customer.id', fk: 'customer_recipe.customer_id' },
				],
				0: ['cancel', null],
				direction: 'DESC'
			},
			// filterOption: (option, filter) => {
			// 	switch(filter) {
			// 		case "": return true;
			// 		default:
			// 			const label = _.toLower(option.label);
			// 			return _.every(_.words(filter, /[-+.\w]+/g), (v) => _.includes(label, v));
			// 	}
			// },
			validate: required,
			group: 'left'
		},
		customer_code: {
			type: 'text',
			label: 'ID Pelanggan',
			readOnly: true,
			group: 'middle'
		},
		customer_name: {
			type: 'text',
			label: 'Nama Pelanggan',
			readOnly: true,
			group: 'right'
		},
	},
	recipe: {
		brand_name: 'Merk Lensa',
		name: 'Tipe Lensa',
		kind: 'Jenis Lensa',
		eye: 'Mata',
		spherical: 'Spherical',
		cylinder: 'Cylinder',
		addition: 'ADD',
		axis: 'Axis',
		pd: 'PD',
	},
}

const header = {
	'report/opname': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-6">Total Stok Update : {_.sumBy(data, 'stock').toLocaleString('id')}</h4>
				<h4 className="col-6">Total Stok Opname : {_.sumBy(data, 'quantity').toLocaleString('id')}</h4>
			</div>
		);
	},
	'transfer/list': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-6">Total Qty/Jumlah Barang Keluar</h4>
				<h4 className="col text-right">Total Qty Keluar: {_.sumBy(data, 'quantity').toLocaleString('id')}</h4>
			</div>
		);
	},
	'stock/1': (data, { usergroup_id }) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-6">Total Stok Inventory Gudang</h4>
				{ usergroup_id <= Number(process.env.REACT_APP_OWNER) && <h4 className="col text-right">Total Harga: Rp {_.sumBy(data, ({ sell, stock }) => _.multiply(sell, stock)).toLocaleString('id')}</h4> }
				<h4 className="col text-right">Total Stok: {_.sumBy(data, 'stock').toLocaleString('id')}</h4>
			</div>
		);
	},
	'stock/:id': (data, { usergroup_id }) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-6">Total Stok Cabang</h4>
				{ usergroup_id <= Number(process.env.REACT_APP_OWNER) && <h4 className="col text-right">Total Harga: Rp {_.sumBy(data, ({ sell, stock }) => _.multiply(sell, stock)).toLocaleString('id')}</h4> }
				<h4 className="col text-right">Total Stok: {_.sumBy(data, 'stock').toLocaleString('id')}</h4>
			</div>
		);
	},
	'stock/periode': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-6">Total Stok</h4>
				<h4 className="col text-right">Total Stok: {_.sumBy(data, 'stock_periode').toLocaleString('id')}</h4>
				<h4 className="col text-right">Total Harga: Rp {_.sumBy(data, ({ sell, stock_periode }) => _.multiply(sell, stock_periode)).toLocaleString('id')}</h4>
				<h6 className="col-12">* Lama proses pengambilan data tergantung jumlah produk yang akan tertampil</h6>
			</div>
		);
	},
	'retur/list': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-6">Total Qty/Jumlah Barang Retur</h4>
				<h4 className="col text-right">Total Qty Retur: {_.sumBy(data, 'quantity').toLocaleString('id')}</h4>
			</div>
		);
	},
	'retur/store': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-6">Total Qty/Jumlah Barang Retur</h4>
				<h4 className="col text-right">Total Qty Retur: {_.sumBy(data, 'quantity').toLocaleString('id')}</h4>
			</div>
		);
	},
	'expense': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-12 text-right">Total Pengeluaran : Rp {_.sumBy(data, 'price').toLocaleString('id')}</h4>
			</div>
		);
	},
	'customer/payment': (data) => {
		const header = {
			customer: {
				type: 'text',
				label: 'Customer',
				readOnly: true,
				className: 'col-12',
				value: data[0]?data[0].customer:''
			},
			sum: {
				type: 'text',
				label: 'Total Harga',
				readOnly: true,
				className: 'col-12 col-md-4',
				value: data[0]?data[0].transaction_sum.toLocaleString('id'):''
			},
			paid: {
				type: 'text',
				label: 'Total Pembayaran',
				readOnly: true,
				className: 'col-12 col-md-4',
				value: data[0]?data[0].transaction_paid.toLocaleString('id'):''
			},
			unpaid: {
				type: 'text',
				label: 'Sisa Pembayaran',
				readOnly: true,
				className: 'col-12 col-md-4',
				value: data[0]?(data[0].transaction_sum - data[0].transaction_paid).toLocaleString('id'):''
			},
		}
		return (
			<div className="input-container row" style={{marginTop: '10px'}}>
				{ data.length > 0 && _.map(header, ({className, ...v}, i) => {
					return <div key={i} className={className}><Input name={i} {...v} onChange={()=>{}} /></div>
				})}
			</div>
		);
	},
	'report/unpaid': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-12 col-md-4">Data Penjualan Belum Lunas</h4>
				<h4 className="col text-center">Total Pembayaran DP: Rp {_.sumBy(data, 'paid').toLocaleString('id')}</h4>
				<h4 className="col text-right">Total Sisa Pembayaran: Rp {_.sumBy(data, 'unpaid').toLocaleString('id')}</h4>
			</div>
		);
	},
	'report/paid': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-6">Data Penjualan Lunas</h4>
				<h4 className="col text-right">Total Penjualan Lunas: Rp {_.sumBy(data, 'sum').toLocaleString('id')}</h4>
			</div>
		);
	},
	'report/order': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-6">Data Lensa RX Terjual</h4>
				<h4 className="col text-right">Total Qty Terjual: {_.sumBy(data, 'quantity').toLocaleString('id')}</h4>
			</div>
		);
	},
	'report/product': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
					<h4 className="col text-right">Jumlah Qty Total: {_.sumBy(data, 'quantity').toLocaleString('id')}</h4>
				</div>
		);
	},
	'report/transaction': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
					<h4 className="col">Total Transaksi: Rp {_.sumBy(data, 'sum').toLocaleString('id')}</h4>
					<h4 className="col text-center">Total Bayar: Rp {_.sumBy(data, 'paid').toLocaleString('id')}</h4>
					<h4 className="col text-right">Sisa Pembayaran: Rp {_.sumBy(data, 'unpaid').toLocaleString('id')}</h4>
				</div>
		);
	},
	'report/transaction-complete': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-12 col-md-4">Data Laporan Penjualan</h4>
				<h4 className="col text-center">Total Barang Terjual: {_.sumBy(data, 'quantity').toLocaleString('id')}</h4>
				<h4 className="col text-right">Total Harga: Rp {_.sumBy(data, 'total').toLocaleString('id')}</h4>
			</div>
		);
	},
	'report/product-recap': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-12 col-md-4">Data Rekap Penjualan</h4>
				<h4 className="col text-center">Total Qty: {_.sumBy(data, 'quantity').toLocaleString('id')}</h4>
				<h4 className="col text-right">Total Harga: Rp {_.sumBy(data, 'total').toLocaleString('id')}</h4>
			</div>
		);
	},
	'report/customer-recap': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-12 col-md-4">Data Rekap Customer</h4>
				<h4 className="col text-center">Total Qty: {_.sumBy(data, 'quantity').toLocaleString('id')}</h4>
				<h4 className="col text-right">Total Harga: Rp {_.sumBy(data, 'total').toLocaleString('id')}</h4>
			</div>
		);
	},
	'report/transaction-recap': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-12 col-md-6">Total Omset: Rp {_.sumBy(data, 'sum').toLocaleString('id')}</h4>
				<h4 className="col-12 col-md-6">Total Pendapatan: Rp {_.sumBy(data, 'paid').toLocaleString('id')}</h4>
				<h4 className="col-12 col-md-6">Hutang Pembayaran: Rp {_.sumBy(data, 'unpaid').toLocaleString('id')}</h4>
				{_.map(_.groupBy(data, 'payment_name'), (value, index) => {
					return <h4 key={index} className="col-12 col-md-6">{ index }: Rp {_.sumBy(value, 'paid').toLocaleString('id')}</h4>
				})}
			</div>
		);
	},
	'report/payment-recap': (data) => {
		return (
			<div className="row" style={{marginTop: '10px'}}>
				<h4 className="col-12">Total Pendapatan: Rp {_.sumBy(data, 'paid').toLocaleString('id')}</h4>
				{_.map(_.groupBy(data, 'payment_name'), (value, index) => {
					return <h4 key={index} className="col-12 col-md-6">{ _.startCase(_.lowerCase(index)) }: Rp {_.sumBy(value, 'paid').toLocaleString('id')}</h4>
				})}
			</div>
		);
	},
}

const model = {
	'product/price': ({ store_id }) => {
		return {
			table: 'product',
			select: ['input_detail.input_id', 'brand.name as brand_name', 'input_detail.quantity', 'DATE(input_detail.created_at) as date_in', 'product.detail as detail_string', 'product.name', 'product.code', 'product.id', 'product.buy', 'product.max_discount'],
			join: [
				{
					table: 'input_detail',
					column: 'product.id',
					on: 'input_detail.product_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
			],
			where: {
				'product.buy': 0,
			},
		}
	},
	'stock/1': {
		url: 'product',
		except: ['brand_id', 'category_id', 'category_name', 'detail', 'kind', 'order_id', 'price_code', 'stocked_at', 'supplier_id', 'sell_locale'],
		store_id: warehouse_id,
	},
	'stock/:id': {
		url: 'product',
		except: ['brand_id', 'category_id', 'category_name', 'created_at', 'detail', 'kind', 'order_id', 'price_code', 'stocked_at', 'supplier_id', 'sell_locale'],
	},
	'stock/periode': {
		url: 'stock/periode',
	},
	'report/opname': {
		table: 'product',
		select: ['admin.name as admin_name', 'store.name as store_name', 'opname.code', 'DATE(opname.created_at) as opname_date', 'CONCAT(product.code, " - ", product.name) as product', 'opname_detail.stock', 'opname_detail.quantity', 'opname_detail.reason'],
		join: [
			{
				table: 'opname_detail',
				column: 'product.id',
				on: 'opname_detail.product_id'
			},
			{
				table: 'opname',
				column: 'opname_detail.opname_id',
			},
			{
				table: 'admin',
				column: 'opname.admin_id',
			},
			{
				table: 'store',
				column: 'opname.store_id',
			},
		],
		order: {
			'opname.id': 'ASC'
		},
	},
	'transfer/list': {
		table: 'product',
		select: ['transfer.code as transfer_code', 'category.name as category_name', 'store.name as store_name', 'brand.name as brand_name', 'transfer_detail.quantity', 'product.detail as detail_string', 'product.code', 'product.name'],
		join: [
			{
				table: 'transfer_detail',
				column: 'product.id',
				on: 'transfer_detail.product_id'
			},
			{
				table: 'transfer',
				column: 'transfer_detail.transfer_id'
			},
			{
				table: 'brand',
				column: 'product.brand_id'
			},
			{
				table: 'category',
				column: 'product.category_id'
			},
			{
				table: 'store',
				column: 'transfer.transfer_to'
			},
		],
		where: {
			'transfer.store_id': warehouse_id,
			'transfer.type': 'KELUAR',
		},
	},
	'retur/list': ({ store_id }) => {
		return {
			table: 'product',
			select: ['transfer.id as transfer_id', 'transfer.code as transfer_code', 'category.name as category_name', 'store.name as store_name', 'brand.name as brand_name', 'supplier.name as supplier_name', 'transfer_detail.quantity', 'product.detail as detail_string', 'DATE(transfer.created_at) as retur_date', 'transfer_detail.reason', 'DATEDIFF(NOW(), transfer.created_at) as duration', 'transfer.status', 'product.code', 'product.name'],
			join: [
				{
					table: 'transfer_detail',
					column: 'product.id',
					on: 'transfer_detail.product_id'
				},
				{
					table: 'transfer',
					column: 'transfer_detail.transfer_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'supplier',
					column: 'product.supplier_id'
				},
				{
					table: 'category',
					column: 'product.category_id'
				},
				{
					table: 'store',
					column: 'transfer.transfer_to',
					type: 'left'
				},
			],
			where: {
				// 'transfer.transfer_to': warehouse_id,
				'transfer.store_id': store_id,
				'transfer.type': 'RETUR',
			},
		}
	},
	'retur/store': ({ store_id }) => {
		return {
			table: 'product',
			select: ['transfer.id as transfer_id', 'transfer.code as transfer_code', 'category.name as category_name', 'store.name as store_name', 'brand.name as brand_name', 'supplier.name as supplier_name', 'transfer_detail.quantity', 'product.detail as detail_string', 'DATE(transfer.created_at) as retur_date', 'transfer_detail.reason', 'product.code', 'product.name'],
			join: [
				{
					table: 'transfer_detail',
					column: 'product.id',
					on: 'transfer_detail.product_id'
				},
				{
					table: 'transfer',
					column: 'transfer_detail.transfer_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'supplier',
					column: 'product.supplier_id'
				},
				{
					table: 'category',
					column: 'product.category_id'
				},
				{
					table: 'store',
					column: 'transfer.store_id',
					type: 'left'
				},
			],
			where: {
				'transfer.transfer_to': warehouse_id,
				'transfer.type': 'RETUR',
			},
		}
	},
	'input/list': ({ store_id }) => {
		return {
			table: 'product',
			select: ['category.name as category_name', 'brand.name as brand_name', 'supplier.name as supplier_name', 'product.detail as detail_string', 'DATE(input_detail.created_at) as input_date', 'input_detail.quantity', 'product.code', 'product.name', 'product.buy', 'product.sell'],
			join: [
				{
					table: 'input_detail',
					column: 'product.id',
					on: 'input_detail.product_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'supplier',
					column: 'product.supplier_id'
				},
				{
					table: 'category',
					column: 'product.category_id'
				},
			],
		}
	},
	'input/history': ({ store_id }) => {
		return {
			table: 'input',
			select: ['admin.name as admin_name', 'category.name as category_name', 'supplier.name as supplier_name', 'DATE(input.created_at) as input_date', 'input.invoice', 'input.code', 'input.total'],
			join: [
				{
					table: 'admin',
					column: 'input.admin_id'
				},
				{
					table: 'supplier',
					column: 'input.supplier_id'
				},
				{
					table: 'category',
					column: 'input.category_id'
				},
			],
		}
	},
	'expense': ({ store_id }) => {
		return {
			table: 'expense',
			select: ['store.name as store_name', 'sales.name as sales_name', 'DATE(expense.created_at) as date_expense', 'expense.name', 'expense.price', 'expense.id'],
			join: [
				{
					table: 'store',
					column: 'expense.store_id'
				},
				{
					table: 'sales',
					column: 'expense.sales_id'
				},
			],
			where: {
				'expense.store_id': store_id === warehouse_id?undefined:store_id,
			},
		}
	},
	'customer/payment': ({ store_id }) => {
		return {
			table: 'transaction_debit',
			select: ['transaction.code as transaction_code', 'transaction.paid as transaction_paid', 'transaction.sum as transaction_sum', 'CONCAT(customer.code, " / ", customer.name, " / ", customer.phone) as customer', 'payment.name as payment_name', 'DATE(transaction_debit.created_at) as payment_date', 'transaction_debit.paid'],
			join: [
				{
					table: 'transaction',
					column: 'transaction_debit.transaction_id',
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
				{
					table: 'payment',
					column: 'transaction_debit.payment_id'
				},
			],
			where: {
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction_debit.id': 'ASC'
			}
		}
	},
	'transaction/change': ({ store_id, id }) => {
		return {
			header: {
				cancel: null,
			},
			footer: {
				admin_id: id
			},
			method: putFromUrl
		}
	},
	'report/transaction-cancel': ({ store_id }) => {
		return {
			table: 'transaction',
			select: ['transaction.id', 'store.name as store_name', 'admin.name as admin_name', 'customer.name as customer_name', 'DATE(transaction.modified_at) as cancel_date', 'transaction.code', 'transaction.cancel'],
			join: [
				{
					table: 'store',
					column: 'transaction.store_id'
				},
				{
					table: 'admin',
					column: 'transaction.admin_id'
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
			],
			where: {
				0: ['transaction.cancel', '<>', null]
			},
			order: {
				'transaction.id': 'ASC'
			},
		}
	},
	'report/transaction-change': ({ store_id }) => {
		return {
			table: 'transaction_change',
			select: ['store.name as store_name', 'admin.name as admin_name', 'customer.name as customer_name', 'DATE(transaction_change.created_at) as history_date', 'transaction.code', 'transaction.total', 'transaction.sum', 'transaction.discount', 'transaction_change.discount as history_discount'],
			join: [
				{
					table: 'transaction',
					column: 'transaction_change.transaction_id'
				},
				{
					table: 'store',
					column: 'transaction.store_id'
				},
				{
					table: 'admin',
					column: 'transaction_change.admin_id'
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
			],
			where: {
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
		}
	},
	'reprint/barcode': {
		'PerKodeBarangMasuk': {
			url: 'barcode/'
		},
		'PerMerkProduk': {
			url: 'print'
		},
		'PerKodeProduk': {
			url: 'print/'
		}
	},
	'reprint/input': ({ store_id }) => {
		return {
			table: 'product',
			select: ['input.code as input_code', 'brand.name as brand_name', 'input_detail.quantity', 'DATE(input.created_at) as date_in', 'product.detail as detail_string', 'product.code', 'product.name'],
			join: [
				{
					table: 'input_detail',
					column: 'product.id',
					on: 'input_detail.product_id'
				},
				{
					table: 'input',
					column: 'input_detail.input_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
			],
			where: {
				'input.store_id': store_id,
			},
		}
	},
	'reprint/transfer': ({ store_id }) => {
		return {
			table: 'product',
			select: ['transfer.code as transfer_code', 'brand.name as brand_name', 'transfer_detail.quantity', 'DATE(transfer.created_at) as date_out', 'store.name as store_name', 'product.detail as detail_string', 'product.code', 'product.name'],
			join: [
				{
					table: 'transfer_detail',
					column: 'product.id',
					on: 'transfer_detail.product_id'
				},
				{
					table: 'transfer',
					column: 'transfer_detail.transfer_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'store',
					column: 'transfer.transfer_to'
				},
			],
			where: {
				'transfer.store_id': store_id,
				'type': 'KELUAR'
			},
		}
	},
	'report/unpaid': ({ store_id }) => {
		return {
			table: 'transaction',
			select: ['CONCAT(customer.code, " / ", customer.name, " / ", customer.phone) as customer', 'payment.name as payment_name', 'DATE(transaction.created_at) as transaction_date', 'transaction.sum - transaction.paid as unpaid', store_id === warehouse_id?'CONCAT(store.code, " - ", transaction.code) as invoice':'transaction.code as invoice', 'transaction.sum', 'transaction.paid'],
			join: [
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
				{
					table: 'store',
					column: 'transaction.store_id'
				},
				{
					table: 'transaction_debit',
					column: 'transaction.id',
					on: 'transaction_debit.transaction_id'
				},
				{
					table: 'payment',
					column: 'transaction_debit.payment_id',
				},
			],
			where: {
				0: ['transaction.sum - transaction.paid', '>', 0],
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
			group: ['transaction.id'],
		}
	},
	'report/paid': ({ store_id }) => {
		return {
			table: 'transaction',
			select: ['CONCAT(customer.code, " / ", customer.name, " / ", customer.phone) as customer', 'DATE(transaction.created_at) as transaction_date', 'DATE(transaction.modified_at) as paid_date', /*/'transaction_detail.price', 'transaction_detail.discount', 'transaction_detail.quantity', /*/'transaction.sum', store_id === warehouse_id?'CONCAT(store.code, " - ", transaction.code) as invoice':'transaction.code as invoice', 'transaction.id as dp', 'transaction.created_at', 'transaction.id'],
			join: [
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
				{
					table: 'store',
					column: 'transaction.store_id'
				},
			],
			where: {
				0: ['transaction.sum - transaction.paid', '<=', 0],
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
			group: ['transaction.id'],
		}
	},
	'report/product': ({ store_id }) => {
		return {
			table: 'product',
			select: ['transaction.code as invoice', 'admin.name as admin_name', 'customer.code as customer_code', 'brand.name as brand_name', 'transaction_detail.quantity', 'DATE(product_stock.created_at) as date_in', 'DATE(transaction.created_at) as date_out', 'DATEDIFF(transaction.created_at, product_stock.created_at) as duration', 'product.detail as detail_string', 'product.code', 'product.name'],
			join: [
				{
					table: 'transaction_detail',
					column: 'product.id',
					on: 'transaction_detail.product_id'
				},
				{
					table: 'transaction',
					column: 'transaction_detail.transaction_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'admin',
					column: 'transaction.admin_id'
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
				{
					table: 'product_stock',
					column: 'product.id',
					on: 'product_stock.product_id'
				},
			],
			where: {
				0: ['product.category_id', '<>', null],
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'product_stock.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
			// group: ['product_stock.product_id'],
		}
	},
	'report/transaction': ({ store_id }) => {
		return {
			table: 'transaction',
			select: ['CONCAT(customer.code, " - ", customer.name) as customer', 'admin.name as admin_name', 'payment.name as payment_name', 'bank.name as bank_name', 'transaction.created_at as transaction_date', 'transaction.sum - transaction.paid as unpaid', store_id === warehouse_id?'CONCAT(store.code, " - ", transaction.code) as invoice':'transaction.code as invoice', 'transaction.sum', 'transaction.paid'],
			join: [
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
				{
					table: 'admin',
					column: 'transaction.admin_id'
				},
				{
					table: 'store',
					column: 'transaction.store_id'
				},
				{
					table: 'transaction_debit',
					column: 'transaction.id',
					on: 'transaction_debit.transaction_id'
				},
				{
					table: 'payment',
					column: 'transaction_debit.payment_id',
				},
				{
					table: 'payment_detail',
					column: 'transaction_debit.payment_detail_id',
					type: 'left'
				},
				{
					table: 'bank',
					column: 'payment_detail.bank_id',
					type: 'left'
				},
			],
			where: {
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
			group: ['transaction.id'],
		}
	},
	'report/order': ({ store_id }) => {
		return {
			table: 'product',
			select: ['transaction.code as invoice', 'admin.name as admin_name', 'CONCAT(customer.code, " / ", customer.name, " / ", customer.phone) as customer', 'brand.name as brand_name', 'transaction_detail.quantity', 'transaction_detail.request', 'transaction_detail.order', 'DATE(transaction.created_at) as date_out', 'product.code', 'product.name', 'product.kind'],
			join: [
				{
					table: 'transaction_detail',
					column: 'product.id',
					on: 'transaction_detail.product_id'
				},
				{
					table: 'transaction',
					column: 'transaction_detail.transaction_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'admin',
					column: 'transaction.admin_id'
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
			],
			where: {
				'product.category_id': null,
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
		}
	},
	'report/transaction-complete': ({ store_id }) => {
		return {
			table: 'product',
			select: [store_id === warehouse_id?'CONCAT(store.code, " - ", transaction.code) as invoice':'transaction.code as invoice', 'DATE(transaction.created_at) as transaction_date', 'CONCAT(customer.code, " / ", customer.name, " / ", customer.phone) as customer', 'brand.name as brand_name', 'transaction_detail.quantity', 'transaction_detail.deal', 'transaction_detail.deal * transaction_detail.quantity as total', 'product.code', 'product.name', 'product.detail as detail_string'],
			join: [
				{
					table: 'transaction_detail',
					column: 'product.id',
					on: 'transaction_detail.product_id'
				},
				{
					table: 'transaction',
					column: 'transaction_detail.transaction_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'admin',
					column: 'transaction.admin_id'
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
				{
					table: 'store',
					column: 'transaction.store_id'
				},
				{
					table: 'product_stock',
					column: 'product.id',
					on: 'product_stock.product_id'
				},
			],
			where: {
				0: ['product.category_id', '<>', null],
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'product_stock.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
			group: ['transaction_detail.id'],
		}
	},
	'report/product-history': ({ store_id }) => {
		return {
			table: 'product',
			select: ['CONCAT(store.code, " - ", transaction.code) as invoice', 'category.name as category_name', 'CONCAT(customer.code, " / ", customer.name, " / ", customer.phone) as customer', 'brand.name as brand_name', 'transaction_detail.deal', 'DATE(product.created_at) as date_in', 'DATE(transaction.created_at) as date_out', 'product.detail as detail_string', 'product.code', 'product.name'],
			join: [
				{
					table: 'transaction_detail',
					column: 'product.id',
					on: 'transaction_detail.product_id'
				},
				{
					table: 'transaction',
					column: 'transaction_detail.transaction_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'category',
					column: 'product.category_id'
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
				{
					table: 'store',
					column: 'transaction.store_id',
				},
			],
			where: {
				0: ['product.category_id', '<>', null],
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
		}
	},
	'report/transaction-history': ({ store_id }) => {
		return {
			table: 'transaction',
			select: ['transaction.id', 'transaction.id as payment_name', 'CONCAT(customer.code, " / ", customer.name, " / ", customer.phone) as customer', 'DATE(transaction.created_at) as transaction_date', /*/'DATE(transaction_debit.created_at) as payment_date', /*/'transaction.id as dp', 'transaction.created_at', 'transaction.id as settled_date', /*/'transaction_debit.paid', /*/'transaction.sum', 'transaction.paid', store_id === warehouse_id?'CONCAT(store.code, " - ", transaction.code) as invoice':'transaction.code as invoice'],
			join: [
				// {
				// 	table: 'transaction_debit',
				// 	column: 'transaction.id',
				// 	on: 'transaction_debit.transaction_id'
				// },
				// {
				// 	table: 'payment',
				// 	column: 'transaction_debit.payment_id',
				// },
				{
					table: 'customer',
					column: 'transaction.customer_id',
				},
				{
					table: 'store',
					column: 'transaction.store_id',
				},
			],
			where: {
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			}
		}
	},
	'report/product-recap': ({ store_id }) => {
		return {
			table: 'product',
			select: ['store.name as store_name', 'category.name as category_name', 'brand.name as brand_name', 'transaction_detail.quantity', 'transaction_detail.deal', 'transaction_detail.deal * transaction_detail.quantity as total', 'product.detail as detail_string', 'product.code', 'product.name'],
			join: [
				{
					table: 'transaction_detail',
					column: 'product.id',
					on: 'transaction_detail.product_id'
				},
				{
					table: 'transaction',
					column: 'transaction_detail.transaction_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'category',
					column: 'product.category_id'
				},
				{
					table: 'store',
					column: 'transaction.store_id'
				},
			],
			where: {
				0: ['product.category_id', '<>', null],
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
		}
	},
	'report/customer-recap': ({ store_id }) => {
		return {
			table: 'product',
			select: ['transaction.code as invoice', 'category.name as category_name', 'CONCAT(customer.code, " / ", customer.name, " / ", customer.phone) as customer', 'brand.name as brand_name', 'transaction_detail.quantity', 'transaction_detail.deal', 'transaction_detail.deal * transaction_detail.quantity as total', 'DATE(transaction.created_at) as date_out', 'product.name'],
			join: [
				{
					table: 'transaction_detail',
					column: 'product.id',
					on: 'transaction_detail.product_id'
				},
				{
					table: 'transaction',
					column: 'transaction_detail.transaction_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'category',
					column: 'product.category_id'
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
			],
			where: {
				0: ['product.category_id', '<>', null],
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null
			},
			order: {
				'transaction.id': 'ASC'
			},
		}
	},
	'report/transaction-recap': ({ store_id }) => {
		return {
			table: 'transaction_debit',
			select: ['transaction.code as invoice', 'customer.name as customer_name', 'transaction.sum', 'SUM(transaction_debit.paid) as paid', 'MIN(transaction_debit.unpaid) as unpaid', 'GROUP_CONCAT(payment.name) as payment_name', 'admin.name as admin_name', 'sales.name as sales_name', 'transaction.ext_note'],
			join: [
				{
					table: 'transaction',
					column: 'transaction_debit.transaction_id',
				},
				{
					table: 'payment',
					column: 'transaction_debit.payment_id',
				},
				{
					table: 'customer',
					column: 'transaction.customer_id',
				},
				{
					table: 'admin',
					column: 'transaction.admin_id',
				},
				{
					table: 'sales',
					column: 'transaction.sales_id',
					type: 'left'
				},
			],
			where: {
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null,
				0: ['DATEDIFF(transaction.created_at, transaction_debit.created_at)', 0]
			},
			order: {
				'transaction.id': 'ASC'
			},
			group: ['transaction.id'],
		}
	},
	'report/payment-recap': ({ store_id }) => {
		return {
			table: 'transaction_debit',
			select: ['transaction.code as invoice', 'customer.name as customer_name', 'transaction.sum', 'transaction_debit.paid', 'transaction_debit.unpaid', 'payment.name as payment_name', 'admin.name as admin_name', 'sales.name as sales_name', 'transaction.ext_note', 'transaction.created_at as transaction_date'],
			join: [
				{
					table: 'transaction',
					column: 'transaction_debit.transaction_id',
				},
				{
					table: 'payment',
					column: 'transaction_debit.payment_id',
				},
				{
					table: 'customer',
					column: 'transaction.customer_id',
				},
				{
					table: 'admin',
					column: 'transaction.admin_id',
				},
				{
					table: 'sales',
					column: 'transaction.sales_id',
					type: 'left'
				},
			],
			where: {
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null,
				0: ['DATEDIFF(transaction.created_at, transaction_debit.created_at)', '<', 0]
			},
			order: {
				'transaction.id': 'ASC'
			},
		}
	},
	'order': {
		table: 'product',
		select: ['brand.name as brand_name', 'supplier.name as supplier_name', 'product.sell', 'product.code', 'product.name', 'product.kind', 'product.id', 'product.supplier_id', 'product.brand_id'],
		join: [
			{
				table: 'brand',
				column: 'product.brand_id'
			},
			{
				table: 'supplier',
				column: 'product.supplier_id'
			},
		],
		where: {
			'product.category_id': null,
			0: ['product.order_id', '<>', null]
		},
	},
	'order/booking': ({ store_id }) => {
		return {
			table: 'product',
			select: ['transaction.code as invoice', 'admin.name as admin_name', 'CONCAT(customer.code, " - ", customer.name) as customer', 'CONCAT(supplier.name, " / ", brand.name) as supplier_brand', 'transaction_detail.detail', 'transaction_detail.detail as detail_string', 'transaction_detail.request', 'CONCAT(product.code, " - ", product.name, " (", product.kind, ")") as product', 'DATEDIFF(NOW(), IFNULL(transaction_detail.request, NOW())) as duration', 'transaction_detail.id as transaction_detail_id'],
			join: [
				{
					table: 'transaction_detail',
					column: 'product.id',
					on: 'transaction_detail.product_id'
				},
				{
					table: 'transaction',
					column: 'transaction_detail.transaction_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'admin',
					column: 'transaction.admin_id'
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
				{
					table: 'supplier',
					column: 'product.supplier_id'
				},
			],
			where: {
				'product.category_id': null,
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null,
				'transaction_detail.order': null
			},
			order: {
				'transaction.id': 'ASC'
			},
		}
	},
	'order/complete': ({ store_id }) => {
		return {
			table: 'product',
			select: ['transaction.code as invoice', 'admin.name as admin_name', 'CONCAT(customer.code, " - ", customer.name) as customer', 'CONCAT(supplier.name, " / ", brand.name) as supplier_brand', 'transaction_detail.detail', 'transaction_detail.detail as detail_string', 'transaction_detail.order', 'transaction_detail.order as status', 'CONCAT(product.code, " - ", product.name, " (", product.kind, ")") as product', 'transaction_detail.id as transaction_detail_id'],
			join: [
				{
					table: 'transaction_detail',
					column: 'product.id',
					on: 'transaction_detail.product_id'
				},
				{
					table: 'transaction',
					column: 'transaction_detail.transaction_id'
				},
				{
					table: 'brand',
					column: 'product.brand_id'
				},
				{
					table: 'admin',
					column: 'transaction.admin_id'
				},
				{
					table: 'customer',
					column: 'transaction.customer_id'
				},
				{
					table: 'supplier',
					column: 'product.supplier_id'
				},
			],
			where: {
				'product.category_id': null,
				'transaction.store_id': store_id === warehouse_id?undefined:store_id,
				'transaction.cancel': null,
				0: ['transaction_detail.request', '<>', null]
			},
			order: {
				'transaction.id': 'ASC'
			},
		}
	},
	'profile/transaction': ({ id }) => {
		return {
		table: 'transaction',
		select: ['store.code as store_code', 'store.name as store_name', 'transaction.id as detail_simple', 'transaction.*'],
		join: [
			{
				table: 'store',
				column: 'transaction.store_id'
			},
		],
		where: {
			'transaction.cancel': null,
			'transaction.customer_id' : id
		},
		order: {
			'transaction.created_at': 'DESC'
		},
	}
	},
	'profile/prescription': ({ id }) => {
		return {
		table: 'customer_recipe',
		select: ['store.code as store_code', 'store.name as store_name', 'transaction.code', 'customer_recipe.*'],
		join: [
			{
				table: 'transaction',
				column: 'customer_recipe.transaction_id'
			},
			{
				table: 'store',
				column: 'transaction.store_id'
			},
		],
		where: {
			'transaction.cancel': null,
			'transaction.customer_id' : id
		},
		order: {
			'transaction.created_at': 'DESC'
		},
	}
	},
	'profile/invoice': ({ id }) => {
		return {
		table: 'transaction',
		select: ['store.code as store_code', 'store.name as store_name', 'store.address as store_address', 'store.city_id as city_name', 'transaction.id as detail_simple', 'transaction.*'],
		join: [
			{
				table: 'store',
				column: 'transaction.store_id'
			},
		],
		where: {
			'transaction.cancel': null,
			'transaction.customer_id' : id
		},
		order: {
			'transaction.created_at': 'DESC'
		},
	}
	},
	'profile/warranty': ({ id }) => {
		return {
		table: 'transaction',
		select: ['store.code as store_code', 'store.name as store_name', 'store.address as store_address', 'store.city_id as city_name', 'customer.name as customer_name', 'transaction.id as detail_simple', 'transaction.id as recipe', 'transaction.*'],
		join: [
			{
				table: 'store',
				column: 'transaction.store_id'
			},
			{
				table: 'customer',
				column: 'transaction.customer_id'
			},
		],
		where: {
			'transaction.cancel': null,
			'transaction.customer_id' : id
		},
		order: {
			'transaction.created_at': 'DESC'
		},
	}
	},
}

const field = {
	...tableFields,
	'product/price': ({ store_id }) => { return {
		header: {
			input_id: {
				type: 'select',
				label: 'Barang Masuk',
				option: {
					table: 'input',
					name: 'CONCAT(code, " - ", DATE_FORMAT(input.created_at, "%d %M %Y"))',
					store_id,
					join: [
						{ table: 'input_detail', id: 'input_detail.input_id', fk: 'input.id' }
					],
					0: ['input_detail.price', '0']
				},
				className: 'col-12 col-md-3',
				validate: required
			},
		},
		field: {
			input_id: {
				type: 'hidden',
				label: 'Input ID',
				validate: required
			},
			buy: {
				type: 'number',
				label: 'Harga Modal',
				validate: [ required, minValue(1) ]
			},
			max_discount: {
				type: 'number',
				label: 'Max Discount',
				validate: [ required, minValue(0), maxValue(100) ]
			}
		},
		keys: {
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			detail_string: 'Warna / Ukuran',
			quantity: 'Quantity',
			date_in: 'Tanggal Masuk',
		}}
	},
	'stock/1': ({ store_id }) => { return {
		header: {
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md-3'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3'
			},
			code: {
				type: 'text',
				label: 'Kode',
				className: 'col-12 col-md-3'
			},
			name: {
				type: 'text',
				label: 'Type',
				className: 'col-12 col-md-3'
			},
			stock: {
				type: store_id === warehouse_id?'number':'hidden',
				label: 'Pencarian Sisa Stok',
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none'
			},
			sell: {
				type: 'number',
				label: 'Harga Jual',
				className: 'col-12 col-md-3'
			},
			category_id: {
				type: 'select',
				label: 'Kategori Produk',
				option: {
					table: 'category'
				},
				onChange: ['spherical', 'cylinder', 'addition'],
				className: 'col-12 col-md-3',
			},
			spherical: {
				type: 'select',
				label: 'Spherical',
				option: {
					table: 'detail',
					name: 'spherical',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			cylinder: {
				type: 'select',
				label: 'Cylinder',
				option: {
					table: 'detail',
					name: 'cylinder',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			addition: {
				type: 'select',
				label: 'Addition',
				option: {
					table: 'detail',
					name: 'addition',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			color: {
				type: 'text',
				label: 'Color',
				className: 'col-12 col-md-3'
			},
			expired: {
				type: 'text',
				label: 'Expired',
				className: 'col-12 col-md-3'
			},
		},
		field: {
			store_id: {
				type: 'hidden',
				label: 'Toko ID',
			},
			store_name: {
				type: 'hidden',
				label: 'Toko',
				'data-readonly-edit': true
			},
			code: {
				type: 'hidden',
				label: 'Kode',
				'data-readonly-edit': true
			},
			name: {
				type: 'text',
				label: 'Tipe',
				validate: required,
				'data-readonly-edit': true
			},
			buy: {
				type: 'number',
				label: 'Harga Beli',
				min: 0,
				validate: [ required, minValue(0) ]
			},
			sell: {
				type: 'number',
				label: 'Harga Jual',
				min: 0,
				validate: [ required, minValue(0) ]
			},
			max_discount: {
				type: 'number',
				label: 'Max Discount (%)',
				min: 0,
				max: 100,
				validate: [ required, minValue(0), maxValue(100) ]
			},
			stock: {
				type: 'number',
				label: 'Stok',
				min: 0,
				validate: [ required, minValue(0) ]
			},
		},
		keys: {
			code: 'Kode',
			brand_name: 'Merk',
			name: 'Tipe',
			detail_string: 'Warna / Ukuran',
			stock: {
				title: 'Stok',
				className: store_id === warehouse_id?'':'d-none',
				render: (value) => _.toString(value)
			},
			sell: {
				title: 'Harga',
				render: renderLocale
			},
			stocked_date: 'Tanggal Masuk',
			duration: 'Durasi',
		}}
	},
	'stock/:id': ({ store_id, slug }) => { return {
		header: {
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store',
					0: ['store.id', '<>', warehouse_id]
				},
				// onChange: (target) => {
				// 	console.log(target);
				// },
				className: 'col-12 col-md-3',
				// validate: required
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md-3'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3'
			},
			code: {
				type: 'text',
				label: 'Kode',
				className: 'col-12 col-md-3'
			},
			name: {
				type: 'text',
				label: 'Type',
				className: 'col-12 col-md-3'
			},
			stock: {
				type: 'number',
				label: 'Pencarian Sisa Stok',
				className: 'col-12 col-md-3'
			},
			sell: {
				type: 'number',
				label: 'Harga Jual',
				className: 'col-12 col-md-3'
			},
			category_id: {
				type: 'select',
				label: 'Kategori Produk',
				option: {
					table: 'category'
				},
				onChange: ['spherical', 'cylinder', 'addition'],
				className: 'col-12 col-md-3',
			},
			spherical: {
				type: 'select',
				label: 'Spherical',
				option: {
					table: 'detail',
					name: 'spherical',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			cylinder: {
				type: 'select',
				label: 'Cylinder',
				option: {
					table: 'detail',
					name: 'cylinder',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			addition: {
				type: 'select',
				label: 'Addition',
				option: {
					table: 'detail',
					name: 'addition',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			color: {
				type: 'text',
				label: 'Color',
				className: 'col-12 col-md-3'
			},
			expired: {
				type: 'text',
				label: 'Expired',
				className: 'col-12 col-md-3'
			},
		},
		field: {
			store_id: {
				type: 'hidden',
				label: 'Toko ID',
			},
			store_name: {
				type: 'hidden',
				label: 'Toko',
				'data-readonly-edit': true
			},
			code: {
				type: 'hidden',
				label: 'Kode',
				'data-readonly-edit': true
			},
			name: {
				type: 'text',
				label: 'Tipe',
				validate: required,
				'data-readonly-edit': true
			},
			buy: {
				type: 'number',
				label: 'Harga Beli',
				min: 0,
				validate: [ required, minValue(0) ]
			},
			sell: {
				type: 'number',
				label: 'Harga Jual',
				min: 0,
				validate: [ required, minValue(0) ]
			},
			max_discount: {
				type: 'number',
				label: 'Max Discount (%)',
				min: 0,
				max: 100,
				validate: [ required, minValue(0), maxValue(100) ]
			},
			stock: {
				type: 'number',
				label: 'Stok',
				min: 0,
				validate: [ required, minValue(0) ]
			},
		},
		keys: {
			store_name: 'Cabang',
			code: 'Kode',
			brand_name: 'Merk',
			name: 'Tipe',
			detail_string: 'Warna / Ukuran',
			stock: {
				title: 'Stok',
				render: (value) => _.toString(value)
			},
			sell: {
				title: 'Harga',
				render: renderLocale
			},
			stocked_date: 'Tanggal Masuk',
			duration: 'Durasi',
			store_id: {
				type: 'action',
				render: (value, { id }, key) => {
					return <i key={key} title="View History" data-toggle="href" data-tooltip data-id={id} data-href={`/stock/${value}/history/${id}`} className="fa fa-eye" aria-hidden="true"></i>;
				}
			},
		}}
	},
	'stock/periode': ({ store_id, slug }) => { return {
		header: {
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Sampai',
				className: 'col-12 col-md-3'
			},
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store',
				},
				className: 'col-12 col-md-3',
			},
			category_id: {
				type: 'select',
				label: 'Kategori Produk',
				option: {
					table: 'category'
				},
				className: 'col-12 col-md-3',
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md-3'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3'
			},
			code: {
				type: 'text',
				label: 'Kode',
				className: 'col-12 col-md-3'
			},
			name: {
				type: 'text',
				label: 'Type',
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			code: 'Kode',
			category_name: 'Kategori',
			name: 'Tipe',
			// detail_string: 'Warna / Ukuran',
			created_at: {
				title: 'Tanggal Masuk',
				render: renderDate,
			},
			sell: {
				title: 'Harga Jual',
				render: renderLocale,
			},
			// initial: {
			// 	title: 'Stok Awal',
			// 	render: (value, type, { stock_card }) => renderLocale(stock_card['INITIAL'] || 0, type),
			// 	className: 'text-right'
			// },
			// in: {
			// 	title: 'Stok Masuk',
			// 	render: (value, type, { stock_card }) => renderLocale(stock_card['IN'] || 0, type),
			// 	className: 'text-right'
			// },
			// out: {
			// 	title: 'Retur Supplier',
			// 	render: (value, type, { stock_card }) => renderLocale(stock_card['OUT'] || 0, type),
			// 	className: 'text-right'
			// },
			// transaction: {
			// 	title: 'Penjualan',
			// 	render: (value, type, { stock_card }) => renderLocale(stock_card['TRANSACTION'] || 0, type),
			// 	className: 'text-right'
			// },
			// transfer: {
			// 	title: 'Transfer',
			// 	render: (value, type, { stock_card }) => renderLocale(stock_card['TRANSFER'] || 0, type),
			// 	className: 'text-right'
			// },
			// opname: {
			// 	title: 'Penyesuaian',
			// 	render: (value, type, { stock_card }) => renderLocale(stock_card['OPNAME'] || 0, type),
			// 	className: 'text-right'
			// },
			stock_periode: {
				title: 'Sisa Stok',
				render: renderLocale,
			},
			total: {
				title: 'Total Harga',
				render: (value, type, { sell, stock_periode }) => renderLocale(sell * stock_periode, type),
			},
		}}
	},
	'report/opname': ({ usergroup_id }) => { return {
		header: {
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: 'col-12 col-md-3',
			},
			category_id: {
				type: 'select',
				label: 'Kategori',
				option: {
					table: 'category'
				},
				className: 'col-12 col-md-3',
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				className: 'col-12 col-md-3',
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3',
			},
			admin_id: {
				type: 'select',
				label: 'Admin',
				option: {
					table: 'admin',
					0: ['usergroup_id', '<=', '3'],
					1: ['usergroup_id', '>=', usergroup_id === Number(process.env.REACT_APP_SUPERADMIN)?usergroup_id:process.env.REACT_APP_OWNER],
				},
				className: 'col-12 col-md-3',
			},
			name: {
				type: 'text',
				label: 'Tipe Produk',
				className: 'col-12 col-md-3'
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			opname_date: 'Tanggal',
			admin_name: 'Admin',
			product: 'Produk',
			store_name: 'Cabang',
			stock: 'Stok Update',
			quantity: 'Stok Opname',
			reason: 'Alasan Audit',
		}}
	},
	'transfer/list': ({ store_id }) => { return {
		header: {
			store_id: {
				type: 'select',
				label: 'Cabang Tujuan',
				option: {
					table: 'store',
					0: ['store.id', '<>', warehouse_id]
				},
				className: 'col-12 col-md-3',
			},
			category_id: {
				type: 'select',
				label: 'Kategori Produk',
				option: {
					table: 'category'
				},
				className: 'col-12 col-md-3',
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
			},
		},
		keys: {
			category_name: 'Kategori',
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			detail_string: 'Warna / Ukuran',
			quantity: 'Quantity',
			store_name: 'Cabang Tujuan',
			transfer_code: 'Kode BK',
		}}
	},
	'retur/list': ({ store_id }) => { return {
		header: {
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				// className: 'col-12 col-md',
			},
			category_id: {
				type: 'select',
				label: 'Kategori Produk',
				option: {
					table: 'category'
				},
				// className: 'col-12 col-md',
			},
			// store_id: {
			// 	type: 'select',
			// 	label: 'Cabang Asal',
			// 	option: {
			// 		table: 'store',
			// 		0: ['store.id', '<>', warehouse_id]
			// 	},
			// 	// className: 'col-12 col-md',
			// },
			status: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Status',
				option: [
					{ value: 'WAITING', label: 'MENUNGGU' },
					{ value: 'ONGOING', label: 'BERJALAN' },
					{ value: 'COMPLETED', label: 'SELESAI' },
				],
				className: store_id === warehouse_id?'col-12 col-md':'d-none',
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
			},
		},
		keys: {
			supplier_name: 'Supplier',
			category_name: 'Kategori',
			brand_name: 'Merk',
			code: 'Kode Barang',
			name: 'Tipe Barang',
			detail_string: 'Warna / Ukuran',
			quantity: 'Quantity',
			retur_date: 'Tanggal Retur',
			reason: 'Alasan Retur',
			duration: 'Durasi Retur',
			status: store_id === warehouse_id && {
				title: 'Status',
				type: 'action',
				render: (value, { transfer_id }, key) => {
					let option = [
						{ value: 'WAITING', label: 'MENUNGGU' },
						{ value: 'ONGOING', label: 'BERJALAN' },
						{ value: 'COMPLETED', label: 'SELESAI' },
					];
					const data = (v, i) => { return {
						key: i,
						className: "dropdown-item",
						'data-action': key,
						'data-toggle': 'click',
						'data-id': transfer_id,
						'data-value': v.value,
						href: `#${i}`
					}};
					switch(value) {
						case 'ONGOING': option = _.drop(option); break;
						case 'COMPLETED': option = _.drop(option, 2); break;
						default: break;
					}
					return <div key={key} className="dropdown">
						<button className="btn btn-info" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{_.find(option, ['value', value]).label}</button>
						<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
							{ _.map(option, (v, i) => {
								return <a {...data(v, i)}>{v.label}</a>
							}) }
						</div>
					</div>
				},
				onClick: (e, id) => {
					const value = e.target.getAttribute('data-value');

					return putFromUrl(`transfer/${id}`, { status: value });
				},
			},
		}}
	},
	'retur/store': ({ store_id }) => { return {
		header: {
			store_id: {
				type: 'select',
				label: 'Cabang Asal',
				option: {
					table: 'store',
					0: ['store.id', '<>', warehouse_id]
				},
				// className: 'col-12 col-md',
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
			},
		},
		keys: {
			store_name: 'Cabang Asal',
			category_name: 'Kategori',
			supplier_name: 'Supplier',
			brand_name: 'Merk',
			code: 'Kode Barang',
			name: 'Tipe Barang',
			detail_string: {
				title: 'Warna / Ukuran',
				width: '23%'
			},
			quantity: 'Qty',
			retur_date: 'Tanggal Retur',
			reason: 'Alasan Retur',
		}}
	},
	'input/list': ({ store_id }) => { return {
		header: {
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				// className: 'col-12 col-md',
			},
			category_id: {
				type: 'select',
				label: 'Kategori Produk',
				option: {
					table: 'category'
				},
				// className: 'col-12 col-md',
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
			},
		},
		keys: {
			category_name: 'Kategori',
			supplier_name: 'Supplier',
			brand_name: 'Merk',
			code: 'Kode Barang',
			name: 'Tipe Barang',
			detail_string: 'Warna / Ukuran',
			quantity: 'Qty',
			buy: {
				title: 'Harga Awal',
				render: renderLocale
			},
			sell: {
				title: 'Harga Jual',
				render: renderLocale
			},
			input_date: 'Tanggal BM'
		}}
	},
	'input/history': ({ store_id, usergroup_id }) => { return {
		header: {
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				// className: 'col-12 col-md',
			},
			category_id: {
				type: 'select',
				label: 'Kategori Produk',
				option: {
					table: 'category'
				},
				// className: 'col-12 col-md',
			},
			admin_id: {
				type: 'select',
				label: 'Admin',
				option: {
					table: 'admin',
					0: usergroup_id === Number(process.env.REACT_APP_SUPERADMIN)?undefined:['usergroup_id', '<>', process.env.REACT_APP_SUPERADMIN]
				},
				// className: 'col-12 col-md',
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
			},
		},
		keys: {
			category_name: 'Kategori',
			supplier_name: 'Supplier',
			admin_name: 'Admin',
			code: 'Kode BM',
			invoice: 'No Invoice',
			total: {
				title: 'Total Harga BM',
				render: renderLocale
			},
			input_date: 'Tanggal BM'
		}}
	},
	'expense': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md':'d-none',
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
			},
		},
		field: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				onChange: ['sales_id'],
				validate: store_id === warehouse_id?required:null,
				'data-readonly-edit': true
			},
			sales_id: {
				type: 'select',
				label: 'Sales',
				option: {
					table: 'sales',
					store_id: store_id === warehouse_id?0:store_id
				},
				validate: required,
				'data-readonly-edit': true
			},
			name: {
				type: 'history',
				label: 'Nama Biaya',
				history: {
					table: 'cost',
					select: 'name'
				},
				validate: required,
			},
			price: {
				type: 'number',
				label: 'Harga',
				min: 0,
				validate: [ required, minValue(0) ]
			},
		},
		keys: {
			store_name: 'Cabang',
			name: 'Nama Biaya',
			price: {
				title: 'Harga',
				render: renderLocale
			},
			sales_name: 'Sales',
			date_expense: 'Tanggal'
		}}
	},
	'customer/payment': ({ store_id }) => { return {
		header: {
			id: {
				type: 'async',
				label: 'Invoice',
				option: {
					table: 'transaction',
					name: store_id === warehouse_id?'CONCAT(store.code, " - ", transaction.code)':'code',
					store_id: store_id === warehouse_id?undefined:store_id,
					0:[ 'cancel', null],
					join: store_id === warehouse_id?
						[
							{table: 'store', id: 'store.id', fk: 'transaction.store_id'}
						]:undefined
				},
				// matchPos: 'any',
				validate: required,
				className: 'col-12 col-md-3',
			},
			store_id: {
				type: 'hidden',
				label: 'Cabang',
				className: ''
			}
		},
		keys: {
			payment_name: 'Cara Pembayaran',
			paid: {
				title: 'Jumlah',
				render: renderLocale
			},
			payment_date: 'Tanggal Pembayaran',
		}}
	},
	'transaction/change': ({ store_id }) => { return {
		header: {
			code: {
				type: 'select',
				label: 'Invoice',
				option: {
					table: 'transaction',
					name: store_id === warehouse_id?'CONCAT(store.code, " - ", transaction.code)':'code',
					store_id: store_id === warehouse_id?undefined:store_id,
					0:[ 'cancel', null],
					1:['transaction.sum - transaction.paid', '>', 0],
					join: store_id === warehouse_id?
						[
							{table: 'store', id: 'store.id', fk: 'transaction.store_id'}
						]:undefined
				},
				// matchPos: 'any',
				validate: required,
				group: 'left',
			},
			customer_code: {
				type: 'text',
				label: 'ID Pelanggan',
				readOnly: true,
				group: 'left'
			},
			customer_name: {
				type: 'text',
				label: 'Nama Pelanggan',
				readOnly: true,
				group: 'left'
			},
			sum: {
				type: 'text',
				label: 'Total Belanja',
				readOnly: true,
				group: 'middle'
			},
			paid: {
				type: 'text',
				label: 'Pembayaran',
				readOnly: true,
				group: 'middle'
			},
			unpaid: {
				type: 'text',
				label: 'Sisa Pembayaran',
				readOnly: true,
				group: 'middle'
			},
			transaction_date: {
				type: 'text',
				label: 'Tanggal Penjualan',
				readOnly: true,
				group: 'right'
			},
			payment_name: {
				type: 'text',
				label: 'Metode Pembayaran',
				readOnly: true,
				group: 'right'
			},
			admin_name: {
				type: 'text',
				label: 'Admin',
				readOnly: true,
				group: 'right'
			},
		},
		keys: {
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			kind: 'Jenis Barang',
			quantity: 'Jumlah',
			price: {
				title: 'Harga Satuan',
				render: renderLocale
			},
			discount_percent: 'Discount(%)',
			total: {
				title: 'Harga Total',
				render: renderLocale
			},
		},
		keysRecipe: {
			name: 'Tipe Lensa',
			eye: 'Mata',
			spherical: 'Spherical',
			cylinder: 'Cylinder',
			addition: 'ADD',
			axis: 'Axis',
			// color: 'Warna',
			pd: 'PD',
		},
		footer: {
			value: {
				type: 'number',
				label: 'Tetapkan Harga Deal / Potongan Harga / Diskon (%)',
				validate: required
			},
			type: {
				type: 'select',
				label: 'Tipe Diskon',
				placeholder: 'Pilih Tipe Diskon',
				option: [
					{value: 'DEAL', label: 'Harga Deal'},
					{value: 'REBATE', label: 'Potongan'},
					{value: 'DISCOUNT', label: 'Diskon (%)'}
				],
				clearable: false,
				validate: required
			},
			total: {
				type: 'text',
				label: 'Total Harga',
				readOnly: true
			},
			sum: {
				type: 'text',
				label: 'Harga yang harus dibayar',
				readOnly: true
			},
		}}
	},
	'report/transaction-cancel': ({ store_id }) => { return {
		header: {
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store'
				},
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
			},
		},
		keys: {
			store_name: 'Cabang',
			admin_name: 'Admin',
			customer_name: 'Customer',
			code: 'Invoice',
			cancel_date: 'Tanggal Pembatalan',
			cancel: 'Alasan',
		}}
	},
	'report/transaction-change': ({ store_id }) => { return {
		header: {
			store_id: {
				type: 'select',
				label: 'Cabang',
				option: {
					table: 'store'
				},
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
			},
		},
		keys: {
			store_name: 'Cabang',
			admin_name: 'Admin',
			customer_name: 'Customer',
			code: 'Invoice',
			total: {
				title: 'Harga',
				render: renderLocale
			},
			history_discount: {
				title: 'Discount Sebelum',
				width: '12%',
				render: renderLocale
			},
			discount: {
				title: 'Discount Setelah',
				width: '12%',
				render: renderLocale
			},
			sum: {
				title: 'Total Harga',
				render: renderLocale
			},
			history_date: 'Tanggal Perubahan'
		}}
	},
	'reprint/barcode': ({ store_id }) => { return {
		header: {
			'PerKodeBarangMasuk': {
				code: {
					type: 'select',
					label: 'Kode Barang Masuk',
					option: {
						table: 'input',
						id: 'code',
						name: 'code',
						store_id
					},
					className: 'col-12 col-md-5',
					validate: required
				},
			},
			'PerMerkProduk': {
				supplier_id: {
					type: 'select',
					label: 'Supplier',
					option: {
						table: 'supplier',
					},
					onChange: ['brand_id'],
					className: 'col-12 col-md-5',
					// validate: required
				},
				brand_id: {
					type: 'select',
					label: 'Merk',
					option: {
						table: 'brand',
						supplier_id: 0
					},
					className: 'col-12 col-md-5',
					// validate: required
				},
			},
			'PerKodeProduk': {
				code: {
					type: 'select',
					label: 'Kode Produk',
					option: {
						table: 'product',
						id: 'code',
						name: 'code',
						order_id: null
					},
					className: 'col-12 col-md-5',
					validate: required
				},
				quantity: {
					type: 'number',
					label: 'Jumlah',
					min: 0,
					className: 'col-12 col-md-5',
					validate: [ required, minValue(0) ]
				},
			}
		},
		field: {
			code: {
				type: 'checkbox',
				label: 'Kode',
			},
			brand_name: {
				type: 'checkbox',
				label: 'Merk',
			},
			name: {
				type: 'checkbox',
				label: 'Tipe',
			},
			sell_locale: {
				type: 'checkbox',
				label: 'Harga Jual',
			},
			price_code: {
				type: 'checkbox',
				label: 'Kode Harga'
			},
		},
		keys: {
			code: 'Kode',
			brand_name: 'Merk',
			name: 'Tipe',
			quantity: 'Quantity',
			sell_locale: 'Harga Jual',
			price_code: 'Kode Harga'
		}}
	},
	'reprint/input': ({ store_id }) => { return {
		header: {
			input_id: {
				type: 'select',
				label: 'Barang Masuk',
				option: {
					table: 'input',
					name: 'CONCAT(code, " - ", DATE_FORMAT(created_at, "%d %M %Y"))',
					store_id
				},
				className: 'col-12 col-md-3',
				validate: required
			},
			// date_from: {
			// 	type: 'date',
			// 	label: 'Tanggal Dari',
			// },
			// date_to: {
			// 	type: 'date',
			// 	label: 'Tanggal Ke',
			// },
		},
		keys: {
			// input_code: 'Kode BM',
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			detail_string: 'Warna / Ukuran',
			quantity: 'Quantity',
			date_in: 'Tanggal Masuk',
		}}
	},
	'reprint/transfer': ({ store_id }) => { return {
		header: {
			transfer_id: {
				type: 'select',
				label: 'Barang Keluar',
				option: {
					table: 'transfer',
					name: 'CONCAT(code, " - ", DATE_FORMAT(created_at, "%d %M %Y"))',
					type: 'KELUAR',
					store_id,
				},
				className: 'col-12 col-md-3',
				validate: required
			},
			// date_from: {
			// 	type: 'date',
			// 	label: 'Tanggal Dari',
			// },
			// date_to: {
			// 	type: 'date',
			// 	label: 'Tanggal Ke',
			// },
		},
		keys: {
			// input_code: 'Kode BM',
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			detail_string: 'Warna / Ukuran',
			quantity: 'Quantity',
			date_out: 'Tanggal BK',
			store_name: 'Cabang Tujuan',
		}}
	},
	'report/unpaid': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none',
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			transaction_date: 'Tanggal',
			invoice: 'Nomor Invoice',
			customer: 'Pelanggan',
			sum: {
				title: 'Total Belanja',
				render: renderLocale
			},
			paid: {
				title: 'Pembayaran',
				render: renderLocale
			},
			unpaid: {
				title: 'Sisa',
				render: renderLocale
			},
			payment_name: 'Metode Pembayaran',
		}}
	},
	'report/paid': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none',
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			transaction_date: 'Tanggal',
			invoice: 'Nomor Invoice',
			customer: 'Pelanggan',
			sum: {
				title: 'Total Belanja',
				render: renderLocale
			},
			dp: {
				title: 'DP',
				render: renderLocale
			},
			paid_date: 'Tanggal Lunas',
		}}
	},
	'report/product': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none',
				validate: store_id === warehouse_id?required:[]
			},
			category_id: {
				type: 'select',
				label: 'Category',
				option: {
					table: 'category'
				},
				onChange: ['spherical', 'cylinder', 'addition'],
				className: 'col-12 col-md-3',
			},
			spherical: {
				type: 'select',
				label: 'Spherical',
				option: {
					table: 'detail',
					name: 'spherical',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			cylinder: {
				type: 'select',
				label: 'Cylinder',
				option: {
					table: 'detail',
					name: 'cylinder',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			addition: {
				type: 'select',
				label: 'Addition',
				option: {
					table: 'detail',
					name: 'addition',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			'name': {
				type: 'text',
				label: 'Kata Kunci',
				className: 'col-12 col-md-3',
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3',
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md-3',
			},
		},
		keys: {
			invoice: 'Kode Invoice',
			admin_name: 'Admin',
			customer_code: 'ID Pelanggan',
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			detail_string: 'Warna / Ukuran',
			quantity: 'Jumlah',
			date_in: 'Tanggal Masuk',
			date_out: 'Tanggal Keluar',
			duration: 'Durasi (Hari)',
		}}
	},
	'report/transaction': ({ store_id }, { change }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none',
			},
			payment_id: {
				type: 'select',
				label: 'Cara Pembayaran',
				option: {
					table: 'payment'
				},
				onChange: ['payment_detail_id'],
				className: 'col-12 col-md-3'
			},
			payment_detail_id: {
				type: 'select',
				label: 'Bank',
				option: {
					table: 'payment_detail',
					name: 'bank.name',
					join: [
						{table: 'bank', id: 'bank.id', fk: 'payment_detail.bank_id'}
					],
					payment_id: 0
				},
				className: 'col-12 col-md-3'
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md-3'
			},
			time_start: {
				type: 'select',
				label: 'Shift',
				option: {
					id: 'start',
					select: ['end as time_end'],
					table: 'shift'
				},
				onChange: (target) => {
					change('time_end', target && target.time_end);
				},
				className: 'col-12 col-md-3'
			},
			total_min: {
				type: 'number',
				label: 'Total Transaksi Dari',
				className: 'col-12 col-md-3'
			},
			total_max: {
				type: 'number',
				label: 'Total Transaksi Ke',
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			invoice: 'Nomor Invoice',
			customer: 'Pelanggan',
			sum: {
				title: 'Total Transaksi',
				render: renderLocale
			},
			admin_name: 'Admin / Karyawan',
			payment_name: 'Cara Pembayaran',
			bank_name: 'Bank',
			paid: {
				title: 'Total Bayar',
				render: renderLocale
			},
			unpaid: {
				title: 'Sisa Pembayaran',
				render: renderLocale
			},
			transaction_date: {
				title: 'Tanggal Transaksi',
				render: renderDateTime
			},
		}}
	},
	'report/order': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store',
					// 0: ['id', '<>', warehouse_id]
				},
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none',
				validate: store_id === warehouse_id?required:[]
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md-3'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3'
			},
			kind: {
				type: 'select',
				label: 'Jenis Lensa',
				option: orderKind,
				className: 'col-12 col-md-3'
			},
			customer_id: {
				type: 'select',
				label: 'Customer',
				option: {
					table: 'customer',
					name: 'CONCAT(customer.code, " / ", customer.name, " / ", customer.phone)'
				},
				className: 'col-12 col-md-3'
			},
			'name': {
				type: 'text',
				label: 'Kata Kunci',
				className: 'col-12 col-md-3'
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			invoice: 'Kode Invoice',
			admin_name: 'Admin',
			customer: 'Pelanggan',
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			kind: 'Jenis Barang',
			quantity: 'Jumlah',
			request: {
				title: 'Status',
				render: (value, type, full) => {
					if(value === null) return 'Belum Dipesan';
					else {
						if(full.order) {
							return 'Sudah Tiba';
						}else{
							return 'Sudah Dipesan';
						}
					}
				}
			},
			date_out: 'Tanggal Keluar',
		}}
	},
	'report/transaction-complete': ({ store_id, usergroup_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none'
			},
			// admin_id: {
			// 	type: 'select',
			// 	label: 'Admin',
			// 	option: {
			// 		table: 'admin',
			// 		0: usergroup_id === Number(process.env.REACT_APP_SUPERADMIN)?undefined:['usergroup_id', '<>', process.env.REACT_APP_SUPERADMIN]
			// 	},
			// 	className: 'col-12 col-md-3'
			// },
			sales_id: {
				type: 'select',
				label: 'Sales',
				option: {
					table: 'sales'
				},
				className: 'col-12 col-md-3'
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md-3'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3'
			},
			category_id: {
				type: 'select',
				label: 'Category',
				option: {
					table: 'category'
				},
				onChange: ['spherical', 'cylinder', 'addition'],
				className: 'col-12 col-md-3'
			},
			spherical: {
				type: 'select',
				label: 'Spherical',
				option: {
					table: 'detail',
					name: 'spherical',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			cylinder: {
				type: 'select',
				label: 'Cylinder',
				option: {
					table: 'detail',
					name: 'cylinder',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			addition: {
				type: 'select',
				label: 'Addition',
				option: {
					table: 'detail',
					name: 'addition',
					category_id: 0
				},
				className: 'col-12 col-md-3'
			},
			color: {
				type: 'text',
				label: 'Color',
				className: 'col-12 col-md-3'
			},
			'name': {
				type: 'text',
				label: 'Tipe Barang',
				className: 'col-12 col-md-3'
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			invoice: 'Kode Invoice',
			transaction_date: 'Tanggal Transaksi',
			customer: 'Pelanggan',
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			detail_string: {
				title: 'Warna / Ukuran',
				width: '20%'
			},
			deal: {
				title: 'Harga Jual',
				render: renderLocale
			},
			quantity: 'Jumlah',
			total: {
				title: 'Total Harga',
				render: renderLocale
			},
		}}
	},
	'report/product-history': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md':'d-none',
				validate: store_id === warehouse_id?required:[]
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md'
			},
			name: {
				type: 'text',
				label: 'Tipe Barang',
				className: 'col-12 col-md'
			},
			code: {
				type: 'text',
				label: 'Kode Barang',
				className: 'col-12 col-md'
			},
		},
		keys: {
			category_name: 'Kategori',
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe',
			detail_string: {
				title: 'Warna / Ukuran',
				width: '15%'
			},
			invoice: 'No Inv',
			customer: 'Customer',
			deal: {
				title: 'Harga Terjual',
				render: renderLocale
			},
			date_in: 'Tanggal Masuk',
			date_out: 'Tanggal Terjual'
		}}
	},
	'report/transaction-history': ({ store_id }) => { return {
		header: {
			id: {
				type: 'async',
				label: 'Invoice',
				option: {
					table: 'transaction',
					name: store_id === warehouse_id?'CONCAT(store.code, " - ", transaction.code)':'code',
					join: store_id === warehouse_id?[
						{table: 'store', id: 'store.id', fk: 'transaction.store_id'}
					]:[],
					store_id: store_id === warehouse_id?undefined:store_id,
					1: ['cancel', null]
				},
				matchPos: 'any',
				className: 'col-12 col-md'
			},
			customer_id: {
				type: 'async',
				label: 'Customer',
				option: {
					table: 'customer',
					name: 'CONCAT(customer.code, " / ", customer.name, " / ", customer.phone)'
				},
				className: 'col-12 col-md'
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			invoice: 'No Invoice',
			customer: 'Pelanggan',
			transaction_date: 'Tanggal Transaksi',
			sum: {
				title: 'Jumlah Harga',
				render: renderLocale
			},
			dp: {
				title: 'Jumlah DP',
				render: renderLocale
			},
			paid: {
				title: 'Status Bayar',
				render: (value, type, { sum }) => sum - value?'Belum Lunas':'Lunas'
			},
			settled_date: 'Tanggal Pelunasan',
			payment_name: 'Cara Pembayaran',
		}}
	},
	'report/product-recap': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none',
				// validate: store_id === warehouse_id?required:[]
			},
			category_id: {
				type: 'select',
				label: 'Category',
				option: {
					table: 'category'
				},
				className: 'col-12 col-md-3'
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md-3'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3'
			},
			'name': {
				type: 'text',
				label: 'Tipe Barang',
				className: 'col-12 col-md-3'
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md-3'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			store_name: {
				title: 'Cabang',
				visible: store_id === warehouse_id?true:false
			},
			category_name: 'Kategori Produk',
			code: 'Kode Barang',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			quantity: 'Qty',
			deal: {
				title: 'Harga Terjual',
				render: renderLocale
			},
			total: {
				title: 'Total Harga',
				render: renderLocale
			},
		}}
	},
	'report/customer-recap': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md':'d-none',
				validate: store_id === warehouse_id?required:[]
			},
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-md'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-md'
			},
		},
		keys: {
			invoice: 'Kode Invoice',
			date_out: 'Tanggal Transaksi',
			customer: 'Pelanggan',
			category_name: 'Kategori',
			brand_name: 'Merk',
			name: 'Tipe Barang',
			quantity: 'Qty',
			deal: {
				title: 'Harga Jual',
				render: renderLocale
			},
			total: {
				title: 'Total Harga',
				render: renderLocale
			},
		}}
	},
	'report/transaction-recap': ({ store_id }, { change }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md':'d-none',
				validate: store_id === warehouse_id?required:[]
			},
			transaction_date: {
				type: 'date',
				label: 'Tanggal',
				className: 'col-12 col-md'
			},
			time_start: {
				type: 'select',
				label: 'Shift',
				option: {
					id: 'start',
					select: ['end as time_end'],
					table: 'shift'
				},
				onChange: (target) => {
					change('time_end', target && target.time_end);
				},
				className: 'col-12 col-md'
			},
		},
		keys: {
			invoice: 'Invoice',
			customer_name: 'Pelanggan',
			sum: {
				title: 'Total Transaksi',
				render: renderLocale
			},
			paid: {
				title: 'Total Bayar',
				render: renderLocale
			},
			unpaid: {
				title: 'Sisa Pembayaran',
				render: renderLocale
			},
			payment_name: 'Cara Pembayaran',
			admin_name: 'Admin',
			sales_name: 'Sales',
			ext_note: {
				title: 'Keterangan',
				width: '12%'
			}
		}}
	},
	'report/payment-recap': ({ store_id }, { change }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md':'d-none',
				validate: store_id === warehouse_id?required:[]
			},
			transaction_date: {
				type: 'date',
				label: 'Tanggal',
				className: 'col-12 col-md'
			},
			time_start: {
				type: 'select',
				label: 'Shift',
				option: {
					id: 'start',
					select: ['end as time_end'],
					table: 'shift'
				},
				onChange: (target) => {
					change('time_end', target && target.time_end);
				},
				className: 'col-12 col-md'
			},
		},
		keys: {
			invoice: 'Invoice',
			transaction_date: {
				title: 'Tanggal Transaksi',
				render: renderDateTime
			},
			customer_name: 'Pelanggan',
			sum: {
				title: 'Total Transaksi',
				render: renderLocale
			},
			paid: {
				title: 'Total Bayar',
				render: renderLocale
			},
			unpaid: {
				title: 'Sisa Pembayaran',
				render: renderLocale
			},
			payment_name: 'Cara Pembayaran',
			admin_name: 'Admin',
			sales_name: 'Sales',
			ext_note: {
				title: 'Keterangan',
				width: '12%'
			}
		}}
	},
	'order': {
		header: {
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md-3'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3'
			},
			name: {
				type: 'text',
				label: 'Tipe',
				className: 'col-12 col-md-3'
			},
			kind: {
				type: 'select',
				label: 'Jenis Lensa',
				option: orderKind,
				className: 'col-12 col-md-3'
			},
		},
		field: {
			code: {
				type: 'hidden',
				label: 'Kode',
				'data-readonly-edit': true
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				validate: required,
				'data-readonly-edit': true
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				validate: required,
				'data-readonly-edit': true
			},
			name: {
				type: 'text',
				label: 'Tipe',
				validate: required,
				'data-readonly-edit': true
			},
			kind: {
				type: 'select',
				label: 'Jenis Barang',
				option: orderKind,
				validate: required,
				'data-readonly-edit': true
			},
			sell: {
				type: 'number',
				label: 'Harga',
				min: 0,
				validate: [ required, minValue(0) ],
				hidden: true
			},
		},
		keys: {
			code: 'Kode',
			supplier_name: 'Supplier',
			brand_name: 'Merk',
			name: 'Tipe',
			kind: 'Jenis Lensa',
			sell: {
				title: 'Harga',
				render: renderLocale
			},
		}
	},
	'order/booking': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none',
				validate: store_id === warehouse_id?required:[]
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md-3'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3'
			},
		},
		keys: {
			admin_name: 'Admin',
			invoice: 'Invoice',
			customer: 'Pelanggan',
			supplier_brand: 'Supplier / Merk',
			product: 'Detail Barang',
			detail_string: 'Ukuran Barang',
			request: 'Proses Pemesanan',
			duration: {
				title: 'Durasi Pesan',
				render: (data, type, { request }) => request?data:'-'
			},
		}}
	},
	'order/complete': ({ store_id }) => { return {
		header: {
			store_id: {
				type: store_id === warehouse_id?'select':'hidden',
				label: 'Cabang',
				option: {
					table: 'store'
				},
				className: store_id === warehouse_id?'col-12 col-md-3':'d-none',
				validate: store_id === warehouse_id?required:[]
			},
			supplier_id: {
				type: 'select',
				label: 'Supplier',
				option: {
					table: 'supplier'
				},
				onChange: ['brand_id'],
				className: 'col-12 col-md-3'
			},
			brand_id: {
				type: 'select',
				label: 'Merk',
				option: {
					table: 'brand',
					supplier_id: 0
				},
				className: 'col-12 col-md-3'
			},
		},
		body: {
			id: {
				type: 'hidden',
				label: 'Supplier',
				validate: required
			},
			buy: {
				type: 'number',
				label: 'Harga Beli',
				validate: required
			},
			order: {
				type: 'text',
				label: 'Supplier Invoice',
				validate: required
			},
		},
		keys: {
			admin_name: 'Admin',
			invoice: 'Invoice',
			customer: 'Pelanggan',
			supplier_brand: 'Supplier / Merk',
			product: 'Detail Barang',
			detail_string: 'Ukuran Barang',
			status: 'Status',
			order: 'Barang Datang',
		}}
	},
	'profile/transaction': {
		header: {
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-sm'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-sm'
			},
		}
	},
	'profile/prescription': {
		header: {
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-sm'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-sm'
			},
		}
	},
	'profile/invoice': {
		header: {
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-sm'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-sm'
			},
		}
	},
	'profile/warranty': {
		header: {
			date_from: {
				type: 'date',
				label: 'Tanggal Dari',
				className: 'col-12 col-sm'
			},
			date_to: {
				type: 'date',
				label: 'Tanggal Ke',
				className: 'col-12 col-sm'
			},
		}
	},
}

const where = {
	'product/price': (v, i) => {
		const props = {
			input_id: v => ['input_detail.input_id', v],
			date_from: v => ['DATE(input_detail.created_at)', '>=', v],
			date_to: v => ['DATE(input_detail.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'stock/1': (v, i) => {
		const props = {
			supplier_id: v => ['supplier_id', v],
			brand_id: v => ['brand_id', v],
			code: v => ['code', v],
			name: v => ['name', v],
			stock: v => ['stock', v],
			sell: v => ['sell', v],
			category_id: v => ['category_id', v],
			spherical: v => ['spherical', v],
			cylinder: v => ['cylinder', v],
			addition: v => ['addition', v],
		}
		return props[i](v);
	},
	'stock/:id': (v, i) => {
		const props = {
			store_id: v => ['store_id', v],
			supplier_id: v => ['supplier_id', v],
			brand_id: v => ['brand_id', v],
			code: v => ['code', v],
			name: v => ['name', v],
			stock: v => ['stock', v],
			sell: v => ['sell', v],
			category_id: v => ['category_id', v],
			spherical: v => ['spherical', v],
			cylinder: v => ['cylinder', v],
			addition: v => ['addition', v],
		}
		return props[i](v);
	},
	// 'stock/periode': (v, i) => {
		// const props = {
		// 	store_id: v => ['store_id', v],
		// }
		// return props[i](v);
	// },
	'report/opname': (v, i) => {
		const props = {
			store_id: v => ['opname.store_id', v],
			category_id: v => ['product.category_id', v],
			supplier_id: v => ['product.supplier_id', v],
			brand_id: v => ['product.brand_id', v],
			name: v => ['product.name', 'like', `%${v}%`],
			date_from: v => ['DATE(opname.created_at)', '>=', v],
			date_to: v => ['DATE(opname.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'transfer/list': (v, i) => {
		const props = {
			store_id: v => ['transfer.transfer_to', v],
			category_id: v => ['product.category_id', v],
			date_from: v => ['DATE(transfer.created_at)', '>=', v],
			date_to: v => ['DATE(transfer.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'retur/list': (v, i) => {
		const props = {
			supplier_id: v => ['product.supplier_id', v],
			category_id: v => ['product.category_id', v],
			store_id: v => ['transfer.store_id', v],
			status: v => ['transfer.status', v],
			date_from: v => ['DATE(transfer.created_at)', '>=', v],
			date_to: v => ['DATE(transfer.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'retur/store': (v, i) => {
		const props = {
			store_id: v => ['transfer.store_id', v],
			date_from: v => ['DATE(transfer.created_at)', '>=', v],
			date_to: v => ['DATE(transfer.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'input/list': (v, i) => {
		const props = {
			supplier_id: v => ['product.supplier_id', v],
			category_id: v => ['product.category_id', v],
			date_from: v => ['DATE(input_detail.created_at)', '>=', v],
			date_to: v => ['DATE(input_detail.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'input/history': (v, i) => {
		const props = {
			supplier_id: v => ['input.supplier_id', v],
			category_id: v => ['input.category_id', v],
			admin_id: v => ['input.category_id', v],
			date_from: v => ['DATE(input.created_at)', '>=', v],
			date_to: v => ['DATE(input.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'expense': (v, i) => {
		const props = {
			store_id: v => ['store.id', v],
			date_from: v => ['DATE(expense.created_at)', '>=', v],
			date_to: v => ['DATE(expense.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'customer/payment': (v, i) => {
		const props = {
			id: v => ['transaction.id', v]
		}
		return props[i](v);
	},
	'report/transaction-cancel': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			date_from: v => ['DATE(transaction.modified_at)', '>=', v],
			date_to: v => ['DATE(transaction.modified_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/transaction-change': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			date_from: v => ['DATE(transaction_change.created_at)', '>=', v],
			date_to: v => ['DATE(transaction_change.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'reprint/input': (v, i) => {
		const props = {
			input_id: v => ['input.id', v],
			date_from: v => ['DATE(input.created_at)', '>=', v],
			date_to: v => ['DATE(input.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'reprint/transfer': (v, i) => {
		const props = {
			transfer_id: v => ['transfer.id', v],
			date_from: v => ['DATE(transfer.created_at)', '>=', v],
			date_to: v => ['DATE(transfer.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/unpaid': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/paid': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/transaction': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			payment_id: v => ['transaction_debit.payment_id', v],
			payment_detail_id: v => ['transaction_debit.payment_detail_id', v],
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v],
			time_start: v => ['CAST(transaction.created_at as time)', '>=', v],
			time_end: v => ['CAST(transaction.created_at as time)', '<=', v],
			total_min: v => ['transaction.sum', '>=', v],
			total_max: v => ['transaction.sum', '<=', v],
		}
		return props[i](v);
	},
	'report/product': (v, i) => {
		const props = {
			store_id: v => [{'transaction.store_id': v, 'product_stock.store_id': v}],
			category_id: v => ['product.category_id', v],
			name: v => ['product.name', 'like', `%${v}%`],
			spherical: v => ['product.detail', 'like', `%"spherical":"${v}"%`],
			cylinder: v => ['product.detail', 'like', `%"cylinder":"${v}"%`],
			addition: v => ['product.detail', 'like', `%"addition":"${v}"%`],
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/order': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			supplier_id: v => ['product.supplier_id', v],
			brand_id: v => ['product.brand_id', v],
			kind: v => ['product.kind', v],
			customer_id: v => ['transaction.customer_id', v],
			name: v => ['product.name', 'like', `%${v}%`],
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/transaction-complete': (v, i) => {
		const props = {
			store_id: v => [{'transaction.store_id': v, 'product_stock.store_id': v}],
			admin_id: v => ['transaction.admin_id', v],
			sales_id: v => ['transaction.sales_id', v],
			category_id: v => ['product.category_id', v],
			supplier_id: v => ['product.supplier_id', v],
			brand_id: v => ['product.brand_id', v],
			spherical: v => ['product.detail', 'like', `%"spherical":"${v}"%`],
			cylinder: v => ['product.detail', 'like', `%"cylinder":"${v}"%`],
			addition: v => ['product.detail', 'like', `%"addition":"${v}"%`],
			color: v => ['product.detail', 'like', `%"color":"%${v}%"%`],
			name: v => ['product.name', 'like', `%${v}%`],
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/product-history': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			supplier_id: v => ['product.supplier_id', v],
			brand_id: v => ['product.brand_id', v],
			name: v => ['product.name', 'like', `%${v}%`],
			code: v => ['product.code', 'like', `%${v}%`],
		}
		return props[i](v);
	},
	'report/transaction-history': (v, i) => {
		const props = {
			id: v => ['transaction.id', v],
			customer_id: v => ['transaction.customer_id', v],
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/product-recap': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			category_id: v => ['product.category_id', v],
			supplier_id: v => ['product.supplier_id', v],
			brand_id: v => ['product.brand_id', v],
			name: v => ['product.name', 'like', `%${v}%`],
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/customer-recap': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'report/transaction-recap': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			transaction_date: v => ['DATE(transaction_debit.created_at)', v],
			time_start: v => ['CAST(transaction_debit.created_at as time)', '>=', v],
			time_end: v => ['CAST(transaction_debit.created_at as time)', '<=', v]
		}
		return props[i](v);
	},
	'report/payment-recap': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			transaction_date: v => ['DATE(transaction_debit.created_at)', v],
			time_start: v => ['CAST(transaction_debit.created_at as time)', '>=', v],
			time_end: v => ['CAST(transaction_debit.created_at as time)', '<=', v]
		}
		return props[i](v);
	},
	'order': (v, i) => {
		const props = {
			supplier_id: v => ['product.supplier_id', v],
			brand_id: v => ['product.brand_id', v],
			name: v => ['product.name', 'like', `%${v}%`],
			kind: v => ['product.kind', v],
		}
		return props[i](v);
	},
	'order/booking': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			supplier_id: v => ['product.supplier_id', v],
			brand_id: v => ['product.brand_id', v],
		}
		return props[i](v);
	},
	'order/complete': (v, i) => {
		const props = {
			store_id: v => ['transaction.store_id', v],
			supplier_id: v => ['product.supplier_id', v],
			brand_id: v => ['product.brand_id', v],
		}
		return props[i](v);
	},
	'profile/transaction': (v, i) => {
		const props = {
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'profile/prescription': (v, i) => {
		const props = {
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'profile/invoice': (v, i) => {
		const props = {
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
	'profile/warranty': (v, i) => {
		const props = {
			date_from: v => ['DATE(transaction.created_at)', '>=', v],
			date_to: v => ['DATE(transaction.created_at)', '<=', v]
		}
		return props[i](v);
	},
}

const callback = {
	'transaction/change': {
		'header': (data, that) => {
			const footer = { total: data.total };
			that.setState({ footer });
			that.props.dispatch(change('footerForm', 'total', data.total.toLocaleString('id')));
			that.props.dispatch(change('footerForm', 'sum', data.sum.toLocaleString('id')));
		}
	}
}

const action = {
	'stock/1': {
		edit: {
			url: 'product/:id'
		},
		remove: {
			url: 'product/:id',
			props: {
				store_id: '1'
			},
			pick: []
		},
	},
	'stock/:id': {
		edit: {
			url: 'product/:id'
		},
		remove: {
			url: 'product/:id',
			pick: ['store_id']
		},
	},
	'expense': ({ store_id }) => ({
		add: {
			url: 'expense',
			props: {
				store_id: store_id === warehouse_id?null:store_id
			}
		},
		edit: {
			url: 'expense/:id',
			props: {
				store_id: store_id === warehouse_id?null:store_id
			}
		},
		remove: {
			url: 'delete',
			props: {
				table: 'expense',
				store_id: store_id === warehouse_id?null:store_id
			}
		}
	}),
	'order': {
		add: {
			url: 'order'
		},
		edit: {
			url: 'order/:id'
		},
		remove: {
			url: 'order/:id',
		}
	},
}

const content = {
	'profile/transaction': (data, session, pagination) => {
		const _data = paginate(data, pagination);

		return (
			<Transaction data={_data} session={session} />
		);
	},
	'profile/prescription': (data, session, pagination) => {
		const _data = paginate(data, pagination);

		return (
			<Prescription data={_data} session={session} />
		);
	},
	'profile/invoice': (data, session, pagination) => {
		const _data = paginate(data, pagination);

		return (
			<Invoice data={_data} session={session} />
		);
	},
	'profile/warranty': (data, session, pagination) => {
		const _data = paginate(data, pagination);

		return (
			<Warranty data={_data} session={session} />
		);
	},
}

const filterData = {
	'profile/prescription': (data) => _.map(_.groupBy(data, 'transaction_id')),
	'profile/warranty': (data) => _.filter(data, (v) => _.some(v.detail_simple, ['category_name', 'FRAME']) || _.some(v.detail_simple, ['category_name', 'LENSA'])),
}

export function initialize(slug, session, props) {
	return {
		header: header[slug],
		model: _.cloneDeep(_.isFunction(model[slug])?model[slug](session, props):model[slug]),
		field: _.cloneDeep(_.isFunction(field[slug])?field[slug](session, props):field[slug]),
		where: where[slug],
		callback: _.cloneDeep(_.isFunction(callback[slug])?callback[slug](session, props):callback[slug]),
		action: _.cloneDeep(_.isFunction(action[slug])?action[slug](session, props):action[slug]),
		content: content[slug],
		filter: filterData[slug]
	}
}
