import { combineReducers } from 'redux';
import SessionReducer from './reducer_session';
// import TableReducer from './reducer_table';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
	session: SessionReducer,
	// table: TableReducer,
	form: formReducer
});

export default rootReducer;